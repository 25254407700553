import React, { useEffect } from 'react';
import { Box, Button, Divider, Skeleton, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTypedDispatch, useTypedSelector } from '../../hooks';
import { getProjects, projectsSelector } from '../../store/reducers';
import { AddCircle, Refresh } from '@mui/icons-material';
import { ProjectList } from '../../components/Pages/projects';

const Projects: React.FC = function () {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useTypedDispatch();
  const { isLoading, projects, error } = useTypedSelector(projectsSelector);

  useEffect(() => {
    if (!isLoading) dispatch(getProjects());
  }, []);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    }
  }, [error]);

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '15px',
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: '15px',
        }}
      >
        <Typography variant="h6" align="left">
          Projects
        </Typography>

        <Box>
          <Button
            variant={'outlined'}
            style={{ marginRight: '10px' }}
            color={'primary'}
            onClick={() => dispatch(getProjects())}
          >
            <Refresh />
          </Button>
          <Button variant="outlined" startIcon={<AddCircle />} onClick={() => navigate(`/projects/new`)}>
            Create Project
          </Button>
        </Box>
      </Box>

      <Divider />

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          flexGrow: 1,
          padding: '15px',
        }}
      >
        {isLoading ? (
          <Stack spacing={1}>
            <Skeleton animation={'pulse'} variant={'text'} width={'80vw'} height={'10vh'}>
              <ProjectList projects={[]} />
            </Skeleton>
            <Skeleton animation={'pulse'} variant={'rectangular'} width={'80vw'} height={'65vh'}>
              <ProjectList projects={[]} />
            </Skeleton>
          </Stack>
        ) : (
          <ProjectList projects={projects} />
        )}
      </Box>
    </Box>
  );
};

export default Projects;
