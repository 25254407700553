import React from 'react';
import { Project } from '../../../store/types';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import ProjectItem from './ProjectItem';

interface ProjectListProps {
  projects: Project[];
}

export const ProjectList: React.FC<ProjectListProps> = function ({ projects }) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>Project Name</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {projects?.length ? (
            projects?.map((project) => <ProjectItem key={project.name} project={project} />)
          ) : (
            <TableRow hover>
              <TableCell align={'center'} colSpan={4}>
                <Typography>There are no projects</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
