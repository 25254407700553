import React, { createContext, useContext, useEffect } from 'react';
import axios, { AxiosError, AxiosInstance } from 'axios';
import axiosRetry from 'axios-retry';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import { useAuth } from './AuthContext';
import { JWT_TOKEN_KEY } from '../store/types';

export type ContextValue = AxiosInstance;

type AxiosContextType = {
  authAxios: ContextValue;
  publicAxios: ContextValue;
};

const contextErr = () => {
  throw new Error('You must wrap your component in an AxiosProvider');
};

const contextProxy = () => {
  return new Proxy(axios.create(), {
    apply: () => contextErr(),
    get: () => contextErr(),
  });
};

const AxiosContext = createContext<AxiosContextType>({
  authAxios: contextProxy(),
  publicAxios: contextProxy(),
});

const { Provider } = AxiosContext;

export const publicAxios = axios.create();
export const authAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosRetry(publicAxios, { retries: 5, retryDelay: (retryCount) => retryCount * 1000 });
axiosRetry(authAxios, { retries: 5, retryDelay: (retryCount) => retryCount * 1000 });

export const AxiosProvider: React.FC = function ({ children }) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [jwtToken, setJwtToken] = useLocalStorage<string | null>(JWT_TOKEN_KEY);

  const { logout } = useAuth();

  useEffect(() => {
    if (jwtToken) authAxios.defaults.headers['Authorization'] = jwtToken;
    if (!jwtToken) logout();
  }, []);

  // Handling 500 & auth errors
  const handleErrors = async (error: AxiosError) => {
    const status = error && error.response ? error.response.status : 0;

    if (status == 401 || status == 403) {
      await logout();
    }

    if (status >= 500 && status <= 599) {
      let errMsg = `Server Error: ${error.response?.data?.message || error.message}`;

      if (!error.response || !error?.response?.data) errMsg = 'Error response body has not been provided!';
      if (error.response?.data && !error.response?.data?.message) errMsg = 'Error message has not been provided!';

      enqueueSnackbar(errMsg, { variant: 'error' });
    }

    return Promise.reject(error.response?.data?.message || error.message);
  };

  authAxios.interceptors.response.use((response) => response, handleErrors);
  publicAxios.interceptors.response.use((response) => response, handleErrors);

  return (
    <Provider
      value={{
        authAxios,
        publicAxios,
      }}
    >
      {children}
    </Provider>
  );
};

export function useAxios() {
  const context = useContext(AxiosContext);
  if (context === undefined) {
    throw new Error('The component this hook must be a descendant of the AxiosProvider');
  }
  return context;
}
