import React, { useCallback, useEffect, useRef } from 'react';
import { Button, InputLabel, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { AddCircle } from '@mui/icons-material';

styled('a')((theme) => ({}));

const Input = styled('input')`
  &[type='file'] {
    display: none;
  }
`;
const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  fontWeight: 400,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: `1px solid ${theme.palette!.primary}`,
}));

export const FilesSelectButton: React.FC<{
  disabled: boolean;
  startIcon: React.ReactNode;
  onClick: (e: any) => void;
}> = ({ disabled, startIcon, onClick }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSelect = useCallback(() => {
    if (inputRef && inputRef.current) inputRef.current?.click();
  }, [inputRef]);

  return (
    <>
      <Input ref={inputRef} id="raised-upload-button" type="file" multiple disabled={disabled} onChange={onClick} />
      <StyledInputLabel htmlFor="raised-upload-button">
        <Button
          variant="outlined"
          disabled={disabled}
          startIcon={startIcon}
          onClick={() => handleSelect()}
          sx={{ m: 1, minWidth: 150 }}
        >
          Select File
        </Button>
      </StyledInputLabel>
    </>
  );
};

export const FoldersSelectButton: React.FC<{
  disabled: boolean;
  startIcon: React.ReactNode;
  onClick: (e: any) => void;
}> = ({ disabled, startIcon, onClick }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSelect = useCallback(() => {
    if (inputRef && inputRef.current) inputRef.current?.click();
  }, [inputRef]);

  return (
    <>
      <Input
        ref={inputRef}
        id="raised-upload-button"
        type="file"
        multiple
        // @ts-ignore
        directory=""
        // @ts-ignore
        webkitdirectory=""
        disabled={disabled}
        onChange={onClick}
      />
      <StyledInputLabel htmlFor="raised-upload-button">
        <Button
          variant="outlined"
          disabled={disabled}
          startIcon={startIcon}
          onClick={() => handleSelect()}
          sx={{ m: 1, minWidth: 150 }}
        >
          Select Folder
        </Button>
      </StyledInputLabel>
    </>
  );
};
