import React, { useCallback, useMemo, useState } from 'react';
import {
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  Close,
  Delete,
  Edit,
  FormatAlignCenter,
  FormatAlignLeft,
  FormatBold,
  FormatItalic,
  Link,
  RemoveRedEye,
} from '@mui/icons-material';

import { User } from '../../../store/types';
import { deleteUser, getUsers } from '../../../store/reducers';
import { useTypedDispatch } from '../../../hooks';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { CopyButton } from '../../Common/Buttons/CopyButton';
import styled from 'styled-components';

interface UserItemProps {
  user: User;
}

const StyledExternalLink = styled.a`
  color: #1976d2;
  text-decoration: none;
  position: relative;
  font-weight: 400;
`;

const UserItem: React.FC<UserItemProps> = ({ user }) => {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [deleteButtonEl, setDeleteButtonEl] = useState(null);
  const isOpenDeletePopover = useMemo(() => deleteButtonEl, []);
  const popoverDeleteId = useMemo(() => (isOpenDeletePopover ? 'simple-popover' : undefined), [isOpenDeletePopover]);

  const deleteButtonHandler = useCallback((event) => {
    setDeleteButtonEl(event.currentTarget);
  }, []);
  const deletePopoverHandler = useCallback(() => {
    setDeleteButtonEl(null);
  }, []);

  const deleteHandler = useCallback(() => {
    dispatch(deleteUser(user.email)).then((data) => {
      if (!data.error) {
        enqueueSnackbar('User has been successfully deleted!', {
          variant: 'success',
        });
        dispatch(getUsers());
      }
    });
  }, []);

  return (
    <>
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell component="th" scope="row">
          {user.email}
        </TableCell>
        <TableCell component="th" scope="row">
          {user.firstName} {user.lastName}
        </TableCell>
        <TableCell component="th" scope="row">
          {user.profile}
        </TableCell>
        <TableCell align="right">
          <Box justifyContent={'flex-end'} display={'flex'}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{
                margin: '0 10px',
              }}
              onClick={() =>
                navigate(`/users/update/${user.email}`, {
                  state: { email: user },
                })
              }
            >
              <Edit />
            </Button>

            <>
              <Button
                aria-describedby={popoverDeleteId}
                variant="contained"
                color="error"
                size="small"
                style={{
                  margin: '0 10px',
                }}
                onClick={deleteButtonHandler}
              >
                <Delete />
              </Button>
              <Popover
                id={popoverDeleteId}
                open={!!deleteButtonEl}
                anchorEl={deleteButtonEl}
                onClose={deletePopoverHandler}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                PaperProps={{
                  style: {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    borderRadius: 0,
                  },
                }}
              >
                <Box
                  sx={{
                    backgroundColor: 'background.paper',
                    borderRadius: 1,
                    border: 1,
                    borderColor: 'info.main',
                    padding: '5px',
                  }}
                >
                  <Button onClick={deletePopoverHandler} color="primary" size="small">
                    cancel
                  </Button>
                  <Button onClick={deleteHandler} color="error" size="small">
                    delete
                  </Button>
                </Box>
                <Box
                  sx={{
                    position: 'relative',
                    mb: '15px',
                    '&::before': {
                      backgroundColor: 'white',
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      width: 12,
                      height: 12,
                      bottom: -6,
                      transform: 'rotate(45deg)',
                      left: 'calc(50% - 6px)',
                      borderBottom: 1,
                      borderRight: 1,
                      borderColor: 'info.main',
                    },
                  }}
                />
              </Popover>
            </>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};

export default UserItem;
