import React, { useEffect } from 'react';
import { User } from '../../../store/types';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import UserItem from './UserItem';

interface UserListProps {
  users: User[];
}

export const UserList: React.FC<UserListProps> = function ({ users }) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Profile</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users?.length ? (
            users?.map((user) => <UserItem key={user.email} user={user} />)
          ) : (
            <TableRow hover>
              <TableCell align={'center'} colSpan={4}>
                <Typography>There are no users</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
