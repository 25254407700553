import React, { useCallback, useMemo, useState } from 'react';
import { Box, Button, Popover, TableCell, TableRow, Typography } from '@mui/material';
import { Delete, Edit, RemoveRedEye, Stop, PlayArrow } from '@mui/icons-material';

import { green, red } from '@mui/material/colors';

import { Project } from '../../../store/types';
import { deleteProject, getProjects } from '../../../store/reducers';
import { useTypedDispatch } from '../../../hooks';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

interface ProjectItemProps {
  project: Project;
}

const ProjectItem: React.FC<ProjectItemProps> = ({ project }) => {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  // Delete Behaviour
  const [deleteButtonEl, setDeleteButtonEl] = useState(null);
  const isOpenDeletePopover = useMemo(() => deleteButtonEl, []);
  const popoverDeleteId = useMemo(() => (isOpenDeletePopover ? 'simple-popover' : undefined), [isOpenDeletePopover]);

  const deleteButtonHandler = useCallback((event) => {
    setDeleteButtonEl(event.currentTarget);
  }, []);
  const deletePopoverHandler = useCallback(() => {
    setDeleteButtonEl(null);
  }, []);

  const deleteHandler = useCallback(() => {
    dispatch(deleteProject(project.name)).then((data: { error: any }) => {
      if (!data.error) {
        enqueueSnackbar('Project has been successfully deleted!', {
          variant: 'success',
        });
        dispatch(getProjects());
      }
    });
  }, []);

  return (
    <>
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell component="th" scope="row">
          {project.name}
        </TableCell>
        <TableCell align="right">
          <Box display={'flex'} flexDirection={'row'} justifyContent={'right'}>
            <Button
              variant="contained"
              color="inherit"
              size="small"
              onClick={() =>
                navigate(`/projects/view/${project.name}`, {
                  state: { name: project.name },
                })
              }
            >
              <RemoveRedEye />
            </Button>

            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{
                margin: '0 10px',
              }}
              onClick={() =>
                navigate(`/projects/update/${project.name}`, {
                  state: { name: project.name },
                })
              }
            >
              <Edit />
            </Button>

            <>
              <Button
                aria-describedby={popoverDeleteId}
                variant="contained"
                color="error"
                size="small"
                style={{
                  margin: '0 10px',
                }}
                onClick={deleteButtonHandler}
              >
                <Delete />
              </Button>
              <Popover
                id={popoverDeleteId}
                open={!!deleteButtonEl}
                anchorEl={deleteButtonEl}
                onClose={deletePopoverHandler}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                PaperProps={{
                  style: {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    borderRadius: 0,
                  },
                }}
              >
                <Box
                  sx={{
                    backgroundColor: 'background.paper',
                    borderRadius: 1,
                    border: 1,
                    borderColor: 'info.main',
                    padding: '5px',
                  }}
                >
                  <Button onClick={deletePopoverHandler} color="primary" size="small">
                    cancel
                  </Button>
                  <Button onClick={deleteHandler} color="error" size="small">
                    delete
                  </Button>
                </Box>
                <Box
                  sx={{
                    position: 'relative',
                    mb: '15px',
                    '&::before': {
                      backgroundColor: 'white',
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      width: 12,
                      height: 12,
                      bottom: -6,
                      transform: 'rotate(45deg)',
                      left: 'calc(50% - 6px)',
                      borderBottom: 1,
                      borderRight: 1,
                      borderColor: 'info.main',
                    },
                  }}
                />
              </Popover>
            </>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ProjectItem;
