import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { AsyncThunkStatus, Device } from '../types';
import { authAxios } from '../../context/AxiosProvider';

export const getDevices = createAsyncThunk('GET /api/devices', async function (_, { rejectWithValue }) {
  try {
    const response = await authAxios.get(`/api/devices`);

    return response.data;
  } catch (err) {
    return rejectWithValue((err as Error).message);
  }
});
export const getDevice = createAsyncThunk(
  'GET /api/devices/{name}',
  async function (name: string, { rejectWithValue }) {
    try {
      const response = await authAxios.get(`/api/devices/${name}`);

      return response.data[0];
    } catch (err) {
      return rejectWithValue((err as Error).message);
    }
  },
);
export const getDevicesLabels = createAsyncThunk('GET /api/devices/labels', async function (_, { rejectWithValue }) {
  try {
    const response = await authAxios.get(`/api/devices/labels`);

    return response.data;
  } catch (err) {
    return rejectWithValue((err as Error).message);
  }
});
export const createDevice = createAsyncThunk('POST /api/devices', async function (device: Device, { rejectWithValue }) {
  try {
    const response = await authAxios.post(`/api/devices`, {
      name: device.name,
      description: device.description,
      users: device.users,
      workstations: device.workstations,
      labels: device.labels,
    });

    return response.data;
  } catch (err) {
    return rejectWithValue((err as Error).message);
  }
});
export const updateDevice = createAsyncThunk('PUT /api/devices', async function (device: Device, { rejectWithValue }) {
  try {
    const response = await authAxios.put(`/api/devices`, {
      name: device.name,
      description: device.description,
      labels: device.labels,
      users: device.users,
      workstations: device.workstations,
    });

    return response.data;
  } catch (err) {
    return rejectWithValue((err as Error).message);
  }
});
export const deleteDevice = createAsyncThunk(
  'DELETE /api/devices',
  async function (deviceName: string, { rejectWithValue }) {
    try {
      const response = await authAxios.delete(`/api/devices`, {
        params: {
          name: deviceName,
        },
      });

      return response.data;
    } catch (err) {
      return rejectWithValue((err as Error).message);
    }
  },
);

export interface DeviceState {
  devices: Device[];
  currentDevice: Device | null;
  labels: string[];
  assignedUsers: string[];
  assignedWorkstations: number[];
  assignedLabels: string[];
  status: AsyncThunkStatus;
  isLoading: boolean;
  error: any;
}

const deviceSlice = createSlice({
  name: 'devices',
  initialState: {
    devices: [],
    currentDevice: null,
    labels: [],
    assignedUsers: [],
    assignedWorkstations: [],
    assignedLabels: [],
    status: AsyncThunkStatus.idle,
    isLoading: false,
    error: null,
  },
  reducers: {
    changeStatus(state: DeviceState, action) {
      state.status = action.payload;
    },
    assignUsersToDevice(state: DeviceState, action: PayloadAction<string[] | string>) {
      if (typeof action.payload === 'string') {
        const hasUser = state.assignedUsers?.some((usr) => usr === action.payload);
        if (!hasUser) state.assignedUsers.push(action.payload);
      }
      if (Array.isArray(action.payload)) {
        state.assignedUsers = action.payload;
      }
    },
    unassignUsersToDevice(state: DeviceState, action: PayloadAction<string>) {
      state.assignedUsers = state.assignedUsers.filter((usr) => usr !== action.payload);
    },
    assignLabels(state: DeviceState, action: PayloadAction<string[] | string>) {
      if (typeof action.payload === 'string') {
        const hasLbl = state.assignedLabels?.some((lbl) => lbl === action.payload);
        if (!hasLbl) state.assignedLabels.push(action.payload);
      }
      if (Array.isArray(action.payload)) {
        state.assignedLabels = action.payload;
      }
    },
    unassignLabels(state: DeviceState, action: PayloadAction<string>) {
      state.assignedLabels = state.assignedLabels.filter((lbl) => lbl !== action.payload);
    },
    assignWorkstations(state: DeviceState, action: PayloadAction<number[] | number>) {
      if (typeof action.payload === 'number') {
        const hasWkID = state.assignedWorkstations?.some((wkID) => wkID === action.payload);
        if (!hasWkID) state.assignedWorkstations.push(action.payload);
      }
      if (Array.isArray(action.payload)) {
        state.assignedWorkstations = action.payload;
      }
    },
    unassignWorkstations(state: DeviceState, action: PayloadAction<number>) {
      state.assignedWorkstations = state.assignedWorkstations.filter((wkid) => wkid !== action.payload);
    },
  },
  extraReducers: {
    [getDevices.pending as unknown as string]: (state: DeviceState) => {
      state.isLoading = true;
      state.error = null;
      state.currentDevice = null;
      state.status = AsyncThunkStatus.pending;
      state.assignedLabels = [];
      state.assignedUsers = [];
      state.assignedWorkstations = [];
    },
    [getDevices.fulfilled as unknown as string]: (state: DeviceState, action) => {
      state.isLoading = false;
      state.devices = action.payload;
      state.error = null;
      state.status = AsyncThunkStatus.fulfilled;
    },
    [getDevices.rejected as unknown as string]: (state: DeviceState, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.status = AsyncThunkStatus.rejected;
    },
    [getDevice.pending as unknown as string]: (state: DeviceState) => {
      state.isLoading = true;
      state.error = null;
      state.status = AsyncThunkStatus.pending;
      state.assignedLabels = [];
      state.assignedUsers = [];
      state.assignedWorkstations = [];
    },
    [getDevice.fulfilled as unknown as string]: (state: DeviceState, action: PayloadAction<Device>) => {
      state.isLoading = false;
      state.currentDevice = action.payload;
      state.assignedWorkstations = action.payload.workstations;
      state.assignedLabels = action.payload.labels;
      state.assignedUsers = action.payload.users;
      state.error = null;
      state.status = AsyncThunkStatus.fulfilled;
    },
    [getDevice.rejected as unknown as string]: (state: DeviceState, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.status = AsyncThunkStatus.rejected;
    },
    [getDevicesLabels.pending as unknown as string]: (state: DeviceState) => {
      state.isLoading = true;
      state.error = null;
      state.status = AsyncThunkStatus.pending;
    },
    [getDevicesLabels.fulfilled as unknown as string]: (state: DeviceState, action) => {
      state.isLoading = false;
      state.labels = action.payload;
      state.error = null;
      state.status = AsyncThunkStatus.fulfilled;
    },
    [getDevicesLabels.rejected as unknown as string]: (state: DeviceState, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.status = AsyncThunkStatus.rejected;
    },
    [createDevice.pending as unknown as string]: (state: DeviceState) => {
      state.isLoading = true;
      state.error = null;
      state.status = AsyncThunkStatus.pending;
    },
    [createDevice.fulfilled as unknown as string]: (state: DeviceState) => {
      state.isLoading = false;
      state.error = null;
      state.status = AsyncThunkStatus.fulfilled;
    },
    [createDevice.rejected as unknown as string]: (state: DeviceState, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.status = AsyncThunkStatus.rejected;
    },
    [updateDevice.pending as unknown as string]: (state: DeviceState) => {
      state.isLoading = true;
      state.error = null;
      state.status = AsyncThunkStatus.pending;
    },
    [updateDevice.fulfilled as unknown as string]: (state: DeviceState) => {
      state.isLoading = false;
      state.error = null;
      state.status = AsyncThunkStatus.fulfilled;
    },
    [updateDevice.rejected as unknown as string]: (state: DeviceState, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.status = AsyncThunkStatus.rejected;
    },
  },
});

export const {
  assignUsersToDevice,
  unassignUsersToDevice,
  assignWorkstations,
  unassignWorkstations,
  assignLabels,
  unassignLabels,
} = deviceSlice.actions;

export const devicesSelector = function (state: RootState): DeviceState {
  return state.devices;
};

export default deviceSlice.reducer;
