import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { AsyncThunkStatus, ICreateUser, User } from '../types';
import { authAxios } from '../../context/AxiosProvider';

export const getUsers = createAsyncThunk('GET /api/users', async function (_, { rejectWithValue }) {
  try {
    const response = await authAxios.get(`/api/users`);

    return response.data;
  } catch (err) {
    return rejectWithValue((err as Error).message);
  }
});
export const getUserByEmail = createAsyncThunk(
  'GET /api/users/{email}',
  async function (email: string, { rejectWithValue }) {
    try {
      const response = await authAxios.get(`/api/users/${email}`);

      return response.data;
    } catch (err) {
      return rejectWithValue((err as Error).message);
    }
  },
);
export const createUser = createAsyncThunk('POST /api/users', async function (user: ICreateUser, { rejectWithValue }) {
  try {
    const response = await authAxios.post(`/api/users`, {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      profile: user.profile,
      projects: user.projects,
    });

    return response.data;
  } catch (err) {
    return rejectWithValue((err as Error).message);
  }
});
export const updateUser = createAsyncThunk('PUT /api/users', async function (user: ICreateUser, { rejectWithValue }) {
  try {
    const response = await authAxios.put(`/api/users`, {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      profile: user.profile,
      projects: user.projects,
    });

    return response.data;
  } catch (err) {
    return rejectWithValue((err as Error).message);
  }
});
export const deleteUser = createAsyncThunk('DELETE /api/users', async function (email: string, { rejectWithValue }) {
  try {
    const response = await authAxios.delete(`/api/users`, {
      params: {
        email: email,
      },
    });

    return response.data;
  } catch (err) {
    return rejectWithValue((err as Error).message);
  }
});

export interface UserState {
  users: User[];
  currentUser: User;
  assignedProjects: string[];
  assignedPolicies: string[];
  status: AsyncThunkStatus;
  isLoading: boolean;
  error: any;
}

const usersSlice = createSlice({
  name: 'users',
  initialState: {
    users: [],
    currentUser: {} as User,
    assignedProjects: [],
    assignedPolicies: [],
    status: AsyncThunkStatus.idle,
    isLoading: false,
    error: null,
  },
  reducers: {
    changeStatus(state: UserState, action) {
      state.status = action.payload;
    },
    assignProjects(state: UserState, action: PayloadAction<string[]>) {
      state.assignedProjects = action.payload;
    },
    assignPolicies(state: UserState, action: PayloadAction<string[]>) {
      state.assignedPolicies = action.payload;
    },
  },
  extraReducers: {
    [getUsers.pending as unknown as string]: (state: UserState) => {
      state.isLoading = true;
      state.error = null;
      state.status = AsyncThunkStatus.pending;
      state.assignedProjects = [];
      state.assignedPolicies = [];
      state.currentUser = {} as User;
    },
    [getUsers.fulfilled as unknown as string]: (state: UserState, action) => {
      state.isLoading = false;
      state.users = action.payload;
      state.error = null;
      state.status = AsyncThunkStatus.fulfilled;
    },
    [getUsers.rejected as unknown as string]: (state: UserState, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.status = AsyncThunkStatus.rejected;
    },
    [getUserByEmail.pending as unknown as string]: (state: UserState) => {
      state.isLoading = true;
      state.error = null;
      state.status = AsyncThunkStatus.pending;
    },
    [getUserByEmail.fulfilled as unknown as string]: (state: UserState, action: PayloadAction<User>) => {
      state.isLoading = false;
      state.currentUser = action.payload;
      state.assignedProjects = action.payload?.projects;
      state.error = null;
      state.status = AsyncThunkStatus.fulfilled;
    },
    [getUserByEmail.rejected as unknown as string]: (state: UserState, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.status = AsyncThunkStatus.rejected;
    },
    [createUser.pending as unknown as string]: (state: UserState) => {
      state.isLoading = true;
      state.error = null;
      state.status = AsyncThunkStatus.pending;
      state.assignedProjects = [];
      state.assignedPolicies = [];
    },
    [createUser.fulfilled as unknown as string]: (state: UserState) => {
      state.isLoading = false;
      state.error = null;
      state.status = AsyncThunkStatus.fulfilled;
    },
    [createUser.rejected as unknown as string]: (state: UserState, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.status = AsyncThunkStatus.rejected;
    },
    [updateUser.pending as unknown as string]: (state: UserState) => {
      state.isLoading = true;
      state.error = null;
      state.status = AsyncThunkStatus.pending;
    },
    [updateUser.fulfilled as unknown as string]: (state: UserState) => {
      state.isLoading = false;
      state.error = null;
      state.status = AsyncThunkStatus.fulfilled;
    },
    [updateUser.rejected as unknown as string]: (state: UserState, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.status = AsyncThunkStatus.rejected;
    },
    [deleteUser.pending as unknown as string]: (state: UserState) => {
      state.isLoading = true;
      state.error = null;
      state.status = AsyncThunkStatus.pending;
    },
    [deleteUser.fulfilled as unknown as string]: (state: UserState) => {
      state.isLoading = false;
      state.error = null;
      state.status = AsyncThunkStatus.fulfilled;
    },
    [deleteUser.rejected as unknown as string]: (state: UserState) => {
      state.isLoading = false;
      state.error = null;
      state.status = AsyncThunkStatus.rejected;
    },
  },
});

export const usersSelector = function (state: RootState): UserState {
  return state.users;
};

export const { assignProjects, assignPolicies } = usersSlice.actions;

export default usersSlice.reducer;
