import React from 'react';
import { Session } from '../../../store/types';
import {
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { SessionsListItem } from './SessionsListItem';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

interface ISessionsProps {
  sessions: Session[];
}

export const SessionsList: React.FC<ISessionsProps> = ({ sessions }) => {
  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell width={'30px'} />
            <TableCell colSpan={1}>ID</TableCell>
            <TableCell colSpan={3}>Labels</TableCell>
            <TableCell colSpan={3}>Started</TableCell>
            <TableCell colSpan={4}>Ended</TableCell>
            <TableCell colSpan={1} align={'right'} />
          </TableRow>
        </TableHead>
        <TableBody>
          {sessions?.map((session) => (
            <SessionsListItem key={session.wid} session={session} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
