import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { AsyncThunkStatus, Session, SessionDTO } from '../types';
import { Dayjs } from 'dayjs';
import { authAxios } from '../../context/AxiosProvider';

export const getSessions = createAsyncThunk(
  'GET /api/sessions',
  async function ({ workstationId, fromDate, toDate }: SessionDTO, { rejectWithValue }) {
    try {
      const response = await authAxios.get(`/api/sessions`, {
        params: {
          wid: workstationId,
          fromDate: fromDate && Date.parse(fromDate.toISOString()) / 1000,
          toDate: toDate && Date.parse(toDate.toISOString()) / 1000,
        },
      });

      return response.data;
    } catch (err) {
      return rejectWithValue((err as Error).message);
    }
  },
);
export const getSession = createAsyncThunk(
  'GET /api/sessions/info',
  async function ({ start_time, wid }: { start_time: number; wid: string | number | any }, { rejectWithValue }) {
    try {
      const response = await authAxios.get(`/api/sessions/info`, {
        params: {
          wid: wid,
          start_time: start_time,
        },
      });

      return response.data;
    } catch (err) {
      return rejectWithValue((err as Error).message);
    }
  },
);
export const getSessionsLabels = createAsyncThunk('GET /api/session-labels', async function (_, { rejectWithValue }) {
  try {
    const response = await authAxios.get(`/api/sessions/labels`);

    return response.data;
  } catch (err) {
    return rejectWithValue((err as Error).message);
  }
});
export const getSessionVideoURL = createAsyncThunk(
  'GET /api/sessions/video',
  async function (
    { wid, sessionStart, fileName }: { wid: string | number | any; sessionStart: number; fileName: string },
    { rejectWithValue },
  ) {
    try {
      const response = await authAxios.get(`/api/sessions/video`, {
        params: {
          wid: wid,
          sessionStart: sessionStart / 1000,
          fileName: fileName,
        },
      });

      return response.data;
    } catch (err) {
      return rejectWithValue((err as Error).message);
    }
  },
);
export const getSessionArchiveURL = createAsyncThunk(
  'GET /api/sessions/archive',
  async function (fileName: string, { rejectWithValue }) {
    try {
      const response = await authAxios.get(`/api/sessions/archive`, {
        params: {
          raw_archive: fileName,
        },
      });

      return response.data;
    } catch (err) {
      return rejectWithValue((err as Error).message);
    }
  },
);
export const updateSession = createAsyncThunk(
  'PUT /api/sessions',
  async function ({ workstationId, startTime }: { workstationId: string; startTime: Date }, { rejectWithValue }) {
    try {
      const response = await authAxios.put(`/api/sessions`, {
        params: {
          wid: workstationId,
          start_time: startTime,
        },
      });

      return response.data[0];
    } catch (err) {
      return rejectWithValue((err as Error).message);
    }
  },
);

export type SessionWithArchives = { raw_archives: string[] } & Session;
export interface SessionsState {
  workstationSessions: {
    selectedWid?: string;
    selectedFromDate: Dayjs | null;
    selectedToDate: Dayjs | null;
  };
  sessions: Session[];
  videoUrl: string;
  archiveUrl: string;
  currentSession: SessionWithArchives;
  labels: string[];
  status: AsyncThunkStatus;
  isLoading: boolean;
  error: any;
}

const sessionsSlice = createSlice({
  name: 'sessions',
  initialState: {
    workstationSessions: {
      selectedWid: '',
      selectedFromDate: null,
      selectedToDate: null,
    },
    sessions: [],
    videoUrl: '',
    archiveUrl: '',
    currentSession: {} as SessionWithArchives,
    labels: [],
    status: AsyncThunkStatus.idle,
    isLoading: false,
    error: null,
  },
  reducers: {
    changeStatus(state: SessionsState, action) {
      state.status = action.payload;
    },
    selectWid(state: SessionsState, action: PayloadAction<string>) {
      state.workstationSessions.selectedWid = action.payload;
    },
    selectStartedAt(state: SessionsState, action: PayloadAction<Dayjs | null>) {
      state.workstationSessions.selectedFromDate = action.payload;
    },
    selectFinishedAt(state: SessionsState, action: PayloadAction<Dayjs | null>) {
      state.workstationSessions.selectedToDate = action.payload;
    },
    resetSessionsState(state: SessionsState) {
      state.sessions = [];
      state.workstationSessions = {
        selectedWid: '',
        selectedFromDate: null,
        selectedToDate: null,
      };
    },
  },
  extraReducers: {
    [getSessions.pending as unknown as string]: (state: SessionsState) => {
      state.isLoading = true;
      state.currentSession = {} as SessionWithArchives;
      state.error = null;
      state.status = AsyncThunkStatus.pending;
    },
    [getSessions.fulfilled as unknown as string]: (state: SessionsState, action: PayloadAction<Session[]>) => {
      state.isLoading = false;
      state.sessions = action.payload || [];
      state.error = null;
      state.status = AsyncThunkStatus.fulfilled;
    },
    [getSessions.rejected as unknown as string]: (state: SessionsState, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.sessions = [];
      state.status = AsyncThunkStatus.rejected;
    },
    [getSession.pending as unknown as string]: (state: SessionsState) => {
      state.archiveUrl = '';
      state.videoUrl = '';
      state.isLoading = true;
      state.error = null;
      state.status = AsyncThunkStatus.pending;
    },
    [getSession.fulfilled as unknown as string]: (state: SessionsState, action: PayloadAction<SessionWithArchives>) => {
      state.isLoading = false;
      state.currentSession = action.payload;
      state.error = null;
      state.status = AsyncThunkStatus.fulfilled;
    },
    [getSession.rejected as unknown as string]: (state: SessionsState, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.sessions = [];
      state.status = AsyncThunkStatus.rejected;
    },
    [updateSession.pending as unknown as string]: (state: SessionsState) => {
      state.isLoading = true;
      state.error = null;
      state.status = AsyncThunkStatus.pending;
    },
    [updateSession.fulfilled as unknown as string]: (state: SessionsState, action) => {
      state.isLoading = false;
      state.error = null;
      state.status = AsyncThunkStatus.fulfilled;
    },
    [updateSession.rejected as unknown as string]: (state: SessionsState, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.status = AsyncThunkStatus.rejected;
    },
    [getSessionsLabels.pending as unknown as string]: (state: SessionsState) => {
      state.isLoading = true;
      state.error = null;
      state.status = AsyncThunkStatus.pending;
    },
    [getSessionsLabels.fulfilled as unknown as string]: (state: SessionsState, action: PayloadAction<string[]>) => {
      state.isLoading = false;
      state.labels = action.payload;
      state.error = null;
      state.status = AsyncThunkStatus.fulfilled;
    },
    [getSessionsLabels.rejected as unknown as string]: (state: SessionsState, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.status = AsyncThunkStatus.rejected;
    },
    [getSessionVideoURL.pending as unknown as string]: (state: SessionsState) => {
      state.isLoading = true;
      state.error = null;
      state.status = AsyncThunkStatus.pending;
    },
    [getSessionVideoURL.fulfilled as unknown as string]: (
      state: SessionsState,
      action: PayloadAction<{ name: string; url: string }>,
    ) => {
      state.isLoading = false;
      state.videoUrl = action.payload.url;
      state.error = null;
      state.status = AsyncThunkStatus.fulfilled;
    },
    [getSessionVideoURL.rejected as unknown as string]: (state: SessionsState, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.status = AsyncThunkStatus.rejected;
    },
    [getSessionArchiveURL.pending as unknown as string]: (state: SessionsState) => {
      state.isLoading = true;
      state.error = null;
      state.status = AsyncThunkStatus.pending;
    },
    [getSessionArchiveURL.fulfilled as unknown as string]: (state: SessionsState, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.archiveUrl = action.payload;
      state.error = null;
      state.status = AsyncThunkStatus.fulfilled;
    },
    [getSessionArchiveURL.rejected as unknown as string]: (state: SessionsState, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.status = AsyncThunkStatus.rejected;
    },
  },
});

export const sessionsSelector = function (state: RootState): SessionsState {
  return state.sessions;
};

export const { resetSessionsState, selectWid, selectStartedAt, selectFinishedAt } = sessionsSlice.actions;

export default sessionsSlice.reducer;
