import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { authAxios, publicAxios } from '../../../context/AxiosProvider';
import { AsyncThunkStatus, UploadRepository } from '../../types';
import { RootState } from '../../store';

interface IFileRequestDTO {
  repository: string;
  prefix: string | string[];
}

export const createFolder = createAsyncThunk(
  'POST /api/folder',
  async function ({ prefix, repository }: IFileRequestDTO, { rejectWithValue }) {
    try {
      const response = await authAxios.post(
        `/api/folder`,
        {},
        {
          params: {
            repository: repository,
            prefix: prefix,
          },
        },
      );

      return response.data;
    } catch (err) {
      return rejectWithValue((err as Error).message);
    }
  },
);
export const getFiles = createAsyncThunk(
  'GET /api/files',
  async function ({ repository, prefix }: IFileRequestDTO, { rejectWithValue }) {
    try {
      const response = await authAxios.get(`/api/files`, {
        params: {
          repository: repository,
          prefix: prefix ? prefix : '/',
        },
      });

      return response.data;
    } catch (err) {
      return rejectWithValue((err as Error).message);
    }
  },
);
export const getDownloadFileLink = createAsyncThunk(
  'GET /api/files/{fullName}',
  async function ({ fileName, repository }: { fileName: string; repository: string }, { rejectWithValue }) {
    try {
      const response = await authAxios.get(`/api/files/download`, {
        params: {
          repository: repository,
          prefix: fileName,
        },
      });

      return response.data;
    } catch (err) {
      return rejectWithValue((err as Error).message);
    }
  },
);
export const downloadFile = createAsyncThunk(
  'GET /{url}',
  async function ({ url, fileName }: { url: string; fileName: string }, { rejectWithValue }) {
    try {
      const a = document.createElement('a');
      a.href = url;
      a.setAttribute('download', fileName);
      a.click();
    } catch (err) {
      return rejectWithValue((err as Error).message);
    }
  },
);
export const deleteFile = createAsyncThunk(
  'DELETE /api/files',
  async function ({ prefix, repository }: IFileRequestDTO, { rejectWithValue }) {
    try {
      const response = await authAxios.delete(`/api/files`, {
        params: {
          repository: repository,
        },
        data: {
          keys: prefix,
        },
      });

      return response.data;
    } catch (err) {
      return rejectWithValue((err as Error).message);
    }
  },
);
export const renameFile = createAsyncThunk(
  'PUT /api/files',
  async function ({ prefix, repository, newName }: IFileRequestDTO & { newName: string }, { rejectWithValue }) {
    try {
      const response = await authAxios.put(
        `/api/files`,
        {},
        {
          params: {
            repository: repository,
            prefix: prefix,
            newName: newName,
          },
        },
      );

      return response.data;
    } catch (err) {
      return rejectWithValue((err as Error).message);
    }
  },
);

export interface FilesState {
  repository?: UploadRepository;
  files: FileRequestDTO[];
  folders: string[];
  selectedFiles: FileRequestDTO[];
  selectedFolders: string[];
  currentPath: string;
  downloadLink: string;
  status: AsyncThunkStatus;
  isLoading: boolean;
  isDownloading: boolean;
  error: any;
}

export interface FilesFoldersPair {
  files: FileRequestDTO[];
  folders: string[];
}

export interface FileRequestDTO {
  Key: string;
  LastModified: number;
  Size: number;
}

function isAnFileRequestDTO(obj: any): obj is FileRequestDTO {
  return 'Key' in obj && 'LastModified' in obj && 'Size' in obj;
}

const filesSlice = createSlice({
  name: 'files',
  initialState: {
    repository: undefined,
    files: [],
    selectedFolders: [],
    selectedFiles: [],
    folders: [],
    currentPath: '',
    downloadLink: '',
    status: AsyncThunkStatus.idle,
    isLoading: false,
    isDownloading: false,
    error: null,
  },
  reducers: {
    changeStatus(state: FilesState, action) {
      state.status = action.payload;
    },
    cleanOnMount(state: FilesState) {
      state.files = [];
      state.folders = [];
      state.selectedFolders = [];
      state.selectedFiles = [];
      state.downloadLink = '';
      state.status = AsyncThunkStatus.idle;
      state.isLoading = false;
      state.error = null;
    },
    changeSelectedFolders(state: FilesState, action: PayloadAction<string | string[]>) {
      if (Array.isArray(action.payload)) state.selectedFolders = action.payload;

      if (typeof action.payload === 'string') {
        const hasSelectedFolder = state.selectedFolders?.some((obj) => obj === action.payload);
        if (hasSelectedFolder) {
          state.selectedFolders = state.selectedFolders?.filter((file) => file !== action.payload);
        } else {
          state.selectedFolders.push(action.payload);
        }
      }
    },
    changeSelectedFiles(state: FilesState, action: PayloadAction<FileRequestDTO | FileRequestDTO[]>) {
      if (Array.isArray(action.payload)) state.selectedFiles = action.payload;
      if (isAnFileRequestDTO(action.payload)) {
        const fileRequestDTO: FileRequestDTO = action.payload;
        const hasSelectedFile = state.selectedFiles?.some((obj) => obj?.Key === fileRequestDTO?.Key);
        if (hasSelectedFile) {
          state.selectedFiles = state.selectedFiles?.filter((file) => file?.Key !== fileRequestDTO?.Key);
        } else {
          state.selectedFiles.push(action.payload);
        }
      }
    },
    changeRepository(state: FilesState, action: PayloadAction<UploadRepository | undefined>) {
      state.repository = action.payload;
      state.currentPath = '';
    },
    goToPath(state: FilesState, action: PayloadAction<string>) {
      if (!state.currentPath) state.currentPath = action.payload;
      else state.currentPath = state.currentPath + '/' + action.payload;
    },
    resetPath(state: FilesState, action: PayloadAction<string>) {
      state.currentPath = action.payload;
    },
  },
  extraReducers: {
    [getFiles.pending as unknown as string]: (state: FilesState) => {
      state.selectedFiles = [];
      state.selectedFolders = [];
      state.isLoading = true;
      state.error = null;
      state.status = AsyncThunkStatus.pending;
    },
    [getFiles.fulfilled as unknown as string]: (state: FilesState, action: PayloadAction<FilesFoldersPair>) => {
      const filesHasToBeMapped = action.payload.files?.some((file) => file.Key.includes('/'));
      const foldersHasToBeMapped = action.payload.folders?.some((folder) => folder.includes('/'));
      state.isLoading = false;
      state.files = filesHasToBeMapped
        ? action.payload.files?.map((file) => {
            const splitedPath = file.Key.split('/');
            return {
              ...file,
              Key: splitedPath[splitedPath.length - 1],
            };
          })
        : action.payload?.files;
      state.folders = foldersHasToBeMapped
        ? action.payload.folders?.map((folder) => {
            const splitedPath = folder.split('/');
            return splitedPath[splitedPath.length - 1];
          })
        : action.payload?.folders;
      state.error = null;
      state.status = AsyncThunkStatus.fulfilled;
    },
    [getFiles.rejected as unknown as string]: (state: FilesState, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.status = AsyncThunkStatus.rejected;
    },
    [getDownloadFileLink.pending as unknown as string]: (state: FilesState) => {
      state.isDownloading = true;
      state.error = null;
      state.status = AsyncThunkStatus.pending;
      state.downloadLink = '';
    },
    [getDownloadFileLink.fulfilled as unknown as string]: (state: FilesState, action: PayloadAction<string>) => {
      state.isDownloading = false;
      state.error = null;
      state.downloadLink = action.payload;
      state.status = AsyncThunkStatus.fulfilled;
    },
    [getDownloadFileLink.rejected as unknown as string]: (state: FilesState, action) => {
      state.isDownloading = false;
      state.error = action.payload;
      state.status = AsyncThunkStatus.rejected;
    },
    [deleteFile.pending as unknown as string]: (state: FilesState) => {
      state.isLoading = true;
      state.error = null;
      state.status = AsyncThunkStatus.pending;
    },
    [deleteFile.fulfilled as unknown as string]: (state: FilesState) => {
      state.isLoading = false;
      state.error = null;
      state.status = AsyncThunkStatus.fulfilled;
    },
    [deleteFile.rejected as unknown as string]: (state: FilesState, action) => {
      state.isLoading = false;
      state.error = null;
      state.status = AsyncThunkStatus.rejected;
    },
    [renameFile.pending as unknown as string]: (state: FilesState) => {
      state.isLoading = true;
      state.error = null;
      state.status = AsyncThunkStatus.pending;
    },
    [renameFile.fulfilled as unknown as string]: (state: FilesState) => {
      state.isLoading = false;
      state.error = null;
      state.status = AsyncThunkStatus.fulfilled;
    },
    [renameFile.rejected as unknown as string]: (state: FilesState, action) => {
      state.isLoading = false;
      state.error = null;
      state.status = AsyncThunkStatus.rejected;
    },
    [createFolder.pending as unknown as string]: (state: FilesState) => {
      state.isLoading = true;
      state.error = null;
      state.status = AsyncThunkStatus.pending;
    },
    [createFolder.fulfilled as unknown as string]: (state: FilesState) => {
      state.isLoading = false;
      state.error = null;
      state.status = AsyncThunkStatus.fulfilled;
    },
    [createFolder.rejected as unknown as string]: (state: FilesState, action) => {
      state.isLoading = false;
      state.error = null;
      state.status = AsyncThunkStatus.rejected;
    },
  },
});

export const { cleanOnMount, goToPath, resetPath, changeRepository, changeSelectedFiles, changeSelectedFolders } =
  filesSlice.actions;

export const filesSelector = function (state: RootState): FilesState {
  return state.fileStore.files;
};

export default filesSlice.reducer;
