import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { authAxios, publicAxios } from '../../../context/AxiosProvider';
import { AsyncThunkStatus, UploadRepository } from '../../types';
import { RootState } from '../../store';

export const uploadFile = createAsyncThunk(
  'PUT /api/upload',
  async function ({ url, data }: { url: string; data: any }, { rejectWithValue }) {
    try {
      const putResponse = await publicAxios?.put(url, data, {});

      return putResponse?.headers?.etag || null;
    } catch (err) {
      return rejectWithValue((err as Error).message);
    }
  },
);
export const getUploadId = createAsyncThunk(
  'OPTIONS /api/start-upload',
  async function ({ repository, prefix }: { repository: UploadRepository; prefix: string }, { rejectWithValue }) {
    try {
      const response = await authAxios.post(
        `/api/start-upload`,
        {},
        {
          params: {
            repository: repository,
            prefix: prefix,
          },
        },
      );

      return response.data;
    } catch (err) {
      return rejectWithValue((err as Error).message);
    }
  },
);
export const getUploadSessionId = createAsyncThunk(
  'GET /api/upload',
  async function (
    {
      repository,
      prefix,
      uploadId,
      partNumber,
    }: { repository: string; prefix: string; uploadId: string; partNumber: number },
    { rejectWithValue },
  ) {
    try {
      const response = await authAxios.get(`/api/upload`, {
        params: {
          repository: repository,
          prefix: prefix,
          uploadId: uploadId,
          partNumber: partNumber,
        },
      });

      return response.data;
    } catch (err) {
      return rejectWithValue((err as Error).message);
    }
  },
);
export const endFileUploadSession = createAsyncThunk(
  'PATCH /api/end-upload',
  async function (
    {
      prefix,
      uploadId,
      repository,
      uploadParts,
    }: { prefix: string; uploadId: string; repository: string; uploadParts: { PartNumber: number; ETag: string }[] },
    { rejectWithValue },
  ) {
    try {
      const response = await authAxios.patch(
        '/api/end-upload',
        {
          uploadId: uploadId,
          uploadParts: uploadParts,
        },
        {
          params: {
            repository: repository,
            prefix: prefix,
          },
        },
      );

      return response.data;
    } catch (err) {
      return rejectWithValue((err as Error).message);
    }
  },
);

export type FileDTO = {
  file: File;
  repository: UploadRepository;
};

export interface UploadFileState {
  uploadId: string;
  sessionId: string;
  presignedUrl: string;
  files: FileDTO[];
  status: AsyncThunkStatus;
  isLoading: boolean;
  error: any;
}

const uploadSlice = createSlice({
  name: 'upload',
  initialState: {
    uploadId: '',
    sessionId: '',
    presignedUrl: '',
    files: [],
    status: AsyncThunkStatus.idle,
    isLoading: false,
    error: null,
  },
  reducers: {
    changeStatus(state: UploadFileState, action) {
      state.status = action.payload;
    },
    setSelectedFiles(state: UploadFileState, action: PayloadAction<FileDTO[]>) {
      state.files = action.payload;
    },
  },
  extraReducers: {
    [getUploadId.pending as unknown as string]: (state: UploadFileState) => {
      state.isLoading = true;
      state.error = null;
      state.status = AsyncThunkStatus.pending;
    },
    [getUploadId.fulfilled as unknown as string]: (
      state: UploadFileState,
      action: PayloadAction<{ uploadId: string }>,
    ) => {
      state.uploadId = action.payload.uploadId;
      state.isLoading = false;
      state.error = null;
      state.status = AsyncThunkStatus.fulfilled;
    },
    [getUploadId.rejected as unknown as string]: (state: UploadFileState, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.status = AsyncThunkStatus.rejected;
    },
    [endFileUploadSession.pending as unknown as string]: (state: UploadFileState) => {
      state.isLoading = true;
      state.error = null;
      state.status = AsyncThunkStatus.pending;
    },
    [endFileUploadSession.fulfilled as unknown as string]: (state: UploadFileState) => {
      state.uploadId = '';
      state.presignedUrl = '';
      state.sessionId = '';
      state.isLoading = false;
      state.error = null;
      state.status = AsyncThunkStatus.fulfilled;
    },
    [endFileUploadSession.rejected as unknown as string]: (state: UploadFileState, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.status = AsyncThunkStatus.rejected;
    },
    [uploadFile.pending as unknown as string]: (state: UploadFileState) => {
      state.isLoading = true;
      state.error = null;
      state.status = AsyncThunkStatus.pending;
    },
    [uploadFile.fulfilled as unknown as string]: (state: UploadFileState, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = null;
      state.status = AsyncThunkStatus.fulfilled;
    },
    [uploadFile.rejected as unknown as string]: (state: UploadFileState, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.status = AsyncThunkStatus.rejected;
    },
    [getUploadSessionId.pending as unknown as string]: (state: UploadFileState) => {
      state.isLoading = true;
      state.error = null;
      state.status = AsyncThunkStatus.pending;
    },
    [getUploadSessionId.fulfilled as unknown as string]: (
      state: UploadFileState,
      action: PayloadAction<{ presignedUrl: string }>,
    ) => {
      state.isLoading = false;
      state.error = null;
      state.presignedUrl = action.payload?.presignedUrl;
      state.presignedUrl = state.status = AsyncThunkStatus.fulfilled;
    },
    [getUploadSessionId.rejected as unknown as string]: (state: UploadFileState, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.status = AsyncThunkStatus.rejected;
    },
  },
});

export const uploadSelector = function (state: RootState): UploadFileState {
  return state.fileStore.upload;
};

export const { setSelectedFiles } = uploadSlice.actions;

export default uploadSlice.reducer;
