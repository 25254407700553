import React, { useEffect } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { getWorkstations, workstationsSelector } from '../../store/reducers';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTypedDispatch, useTypedSelector } from '../../hooks';
import { SessionList } from '../../components/Pages/sessions';

const Sessions: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useTypedDispatch();

  const { isLoading: isLoadingWorkstations, error: workstationError } = useTypedSelector(workstationsSelector);

  useEffect(() => {
    if (!isLoadingWorkstations) dispatch(getWorkstations());
  }, []);

  useEffect(() => {
    if (workstationError) {
      enqueueSnackbar(workstationError, { variant: 'error' });
    }
  }, [workstationError]);

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '15px',
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: '15px',
        }}
      >
        <Typography variant="h6" align="left">
          Sessions
        </Typography>
      </Box>

      <Divider />

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          flexGrow: 1,
          padding: '15px',
        }}
      >
        <SessionList />
      </Box>
    </Box>
  );
};

export default Sessions;
