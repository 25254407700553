// User types
import { ProfileMode } from '../components/Base/SideBar';
import { Dayjs } from 'dayjs';

export interface User {
  email: string;
  createdAt: number;
  firstName: string;
  lastName: string;
  projects: string[];
  profile: ProfileMode;
}
export interface ICreateUser {
  email: string;
  firstName: string;
  lastName: string;
  profile: ProfileMode;
  projects?: string[];
}

// Workstation types
export interface Workstation {
  id: number;
  name: string;
  description?: string;
  created_by?: string;
  labels: string[];
}

export const MIN_FROM_DATE = '2020-01-01';

export interface Session {
  wid: number;
  start_time: number;
  end_time: number;
  data: {
    application: string[];
    auth: string[];
    image: string[];
    operation: string[];
    operation_debug: string[];
    raw_image: string[];
    system: string[];
    video: string[];
  };
  description: string;
  tags: { time: number; description: string }[];
  labels: string[];
}
export interface SessionDTO {
  workstationId: string;
  fromDate?: Dayjs | null;
  toDate?: Dayjs | null;
}

// Device types
export interface Device {
  name: string;
  description: string;
  users: string[];
  labels: string[];
  workstations: number[];
}

// Project types

export interface ICreateProject {
  name: string;
  description?: string;
  workstations: (number | string)[];
  users: string[];
}

export interface Project {
  name: string;
  description: string;
  users: string[];
  workstations: [];
}

export interface ProjectWorkstationMap {
  id: number;
  name: string;
}

// Sandbox types
export interface TableWorkflow {
  tableName: string;
  workflowRunning: boolean;
}

export enum UploadRepository {
  ANIMAL_LAB = 'animal_lab',
  DATA_LAKE = 'data_lake',
}

export interface Workflow {
  name: string;
  // TODO: refactor those field to isRunning;
  workflowRunning: boolean;
  tables: TableWorkflow[];
}

// Redux Thunk types
export enum AsyncThunkStatus {
  idle = 'idle',
  pending = 'pending',
  fulfilled = 'fulfilled',
  rejected = 'rejected',
}

// Session Storage types
export const JWT_TOKEN_KEY = 'jwtToken';
export const JWT_UPDATE_AT_KEY = 'jwtUpdatedAt';
export const USER_ACCOUNT_ID_KEY = 'userAccountID';
export const CLIENT_INFO_KEY = 'clientLS';

export const LOGOUT_BROADCAST_EVENT_KEY = 'logout';
