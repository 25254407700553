import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { Box, Breadcrumbs, Divider, Grid, Paper, Typography, CircularProgress, Button } from '@mui/material';
import { NavigateNext, Edit } from '@mui/icons-material';
import styled from 'styled-components';
import { getProject, projectsSelector } from '../../store/reducers';
import { useTypedDispatch, useTypedSelector } from '../../hooks';
import { ProjectUsersList, ProjectWorkstationsList } from '../../components/Pages/projects';

const StyledLink = styled(Link)`
  color: black;
  text-decoration: none;
  position: relative;
  font-weight: 400;

  &:hover {
    border-bottom: 3px solid #c62828;
    color: black;
  }
`;

const ProjectView: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { name: projectName } = useParams();

  const dispatch = useTypedDispatch();

  const { isLoading, currentProject, error } = useTypedSelector(projectsSelector);

  useEffect(() => {
    if (!isLoading) dispatch(getProject(projectName || ''));
  }, []);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    }
  }, [error]);

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '15px',
        overflowX: 'hidden',
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: '15px',
        }}
      >
        <Typography variant="h6" align="left">
          Project Details
        </Typography>
      </Box>

      <Divider />

      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNext fontSize="small" />}
        sx={{
          padding: '15px',
        }}
      >
        <StyledLink to="/projects">Projects</StyledLink>
        <Typography
          color="black"
          style={{
            fontWeight: '700',
          }}
        >
          {projectName}
        </Typography>
      </Breadcrumbs>

      <Paper>
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'flex-start'}
          style={{
            padding: '15px',
            paddingTop: 0,
          }}
        >
          <Grid
            style={{
              margin: '15px',
            }}
            container
            spacing={2}
          >
            <Grid
              item
              width={'99%'}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              paddingRight={'30px'}
            >
              <Typography variant="h6" align="left">
                {projectName}
              </Typography>
              <Button
                startIcon={<Edit />}
                variant={'contained'}
                onClick={() =>
                  navigate(`/projects/update/${projectName}`, {
                    state: { name: projectName },
                  })
                }
              >
                Update project
              </Button>
            </Grid>
          </Grid>
        </Box>

        <Box width={'100%'} padding={'15px'} margin={'0 15px'}>
          <Paper
            elevation={0}
            style={{
              padding: '15px',
              borderLeft: '5px solid #1976d2',
              backgroundColor: '#ecf8ff',
            }}
          >
            <Typography variant="subtitle2" align="left">
              Description: {currentProject?.description}
            </Typography>
          </Paper>
        </Box>

        <Divider />

        <Box width={'100%'} padding={'15px'} margin={'15px'}>
          <Paper
            elevation={0}
            style={{
              padding: '15px',
              borderLeft: '5px solid #1976d2',
              backgroundColor: '#ecf8ff',
            }}
          >
            <Typography variant="subtitle2" align="left">
              Project workstations
            </Typography>
          </Paper>

          {isLoading ? (
            <Box width={'100%'} height={'60px'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <CircularProgress />
            </Box>
          ) : (
            <ProjectWorkstationsList workstations={currentProject.workstations} />
          )}
        </Box>

        <Box width={'100%'} padding={'15px'} margin={'15px'}>
          <Paper
            elevation={0}
            style={{
              padding: '15px',
              borderLeft: '5px solid #1976d2',
              backgroundColor: '#ecf8ff',
            }}
          >
            <Typography variant="subtitle2" align="left">
              Project users
            </Typography>
          </Paper>

          {isLoading ? (
            <Box width={'100%'} height={'60px'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <CircularProgress />
            </Box>
          ) : (
            <ProjectUsersList users={currentProject.users} />
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default ProjectView;
