import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Divider, Drawer, IconButton, Modal, TextField, Typography } from '@mui/material';
import { AddCircle, Upload, Close } from '@mui/icons-material';
import { useTypedDispatch, useTypedSelector } from '../../hooks';
import { useNavigate } from 'react-router-dom';
import { cleanOnMount, createFolder, filesSelector, getFiles } from '../../store/reducers/fileStore';
import { MemoizedFilesTableComponent } from '../../components/Pages/files/filesList/FilesTable';

const Files = () => {
  const navigate = useNavigate();

  const dispatch = useTypedDispatch();

  const { repository, currentPath } = useTypedSelector(filesSelector);

  const [anchor, setAnchor] = useState(false);
  const [folderName, setFolderName] = useState<string>('');

  useEffect(() => {
    dispatch(cleanOnMount());
    if (repository && currentPath) dispatch(getFiles({ repository: repository, prefix: currentPath }));
  }, []);

  const toggleDrawer = useCallback(
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setAnchor(open);
    },
    [],
  );

  const createFolderHandler = useCallback(async () => {
    if (repository) {
      setAnchor(false);
      setFolderName('');
      await dispatch(
        createFolder({
          prefix: currentPath ? currentPath + '/' + folderName : folderName,
          repository: repository,
        }),
      ).then(async () => {
        await dispatch(getFiles({ repository: repository, prefix: currentPath }));
      });
    }
  }, [repository, currentPath, folderName]);

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '15px',
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: '15px',
        }}
      >
        <Typography variant="h6" align="left">
          Files Repository
        </Typography>

        <Box>
          <Button
            variant="outlined"
            startIcon={<AddCircle />}
            disabled={!repository}
            onClick={toggleDrawer(true)}
            style={{ marginRight: '15px' }}
          >
            Create folder
          </Button>
          <Button
            variant="outlined"
            startIcon={<Upload />}
            disabled={!repository}
            onClick={() => navigate(`/files/upload`)}
          >
            Upload Files
          </Button>
        </Box>
      </Box>

      <Divider />

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          flexGrow: 1,
          padding: '15px',
        }}
      >
        <MemoizedFilesTableComponent />
      </Box>

      <>
        <Modal open={anchor} onClose={toggleDrawer(false)}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              minWidth: '400px',
              backgroundColor: 'background.paper',
              borderRadius: 1,
              padding: '15px',
            }}
          >
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} padding={'15px'}>
              <Typography variant={'h6'}>Folder create modal</Typography>
              <IconButton aria-label="delete" size="large" onClick={() => setAnchor(false)}>
                <Close />
              </IconButton>
            </Box>

            <Box padding={'15px'} display={'flex'} justifyContent={'space-between'}>
              <TextField
                id="outlined-basic"
                label="Folder Name *"
                variant="outlined"
                size={'small'}
                value={folderName}
                onChange={(e) => setFolderName(e.currentTarget.value)}
              />

              <Button variant={'outlined'} onClick={createFolderHandler}>
                Create
              </Button>
            </Box>
          </Box>
        </Modal>
      </>
    </Box>
  );
};

export default Files;
