import React, { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Skeleton,
  Toolbar,
  Typography,
} from '@mui/material';
import { blue } from '@mui/material/colors';
import {
  AccountCircle,
  AdminPanelSettings,
  Assessment,
  Devices,
  HelpOutline,
  ListAlt,
  ManageAccounts,
  QueuePlayNext,
  ShowChart,
  CloudUpload,
} from '@mui/icons-material';
import { AuthState, useAuth } from '../../context/AuthContext';
import { useTypedDispatch, useTypedSelector } from '../../hooks';
import { authSelector } from '../../store/reducers/auth';
import { useSnackbar } from 'notistack';
import { setSelectedFiles, uploadSelector } from '../../store/reducers/fileStore';
import { useLocalStorage } from 'react-use';
import { CLIENT_INFO_KEY } from '../../store/types';
import { changeUpoadedStatus, changeUpoadingStatus, utilsSelector } from '../../store/reducers/utils';

export enum ProfileMode {
  ANY = 'Any',
  ADMIN = 'Administrator',
  SERVICE = 'Service',
  CLINICIAN = 'Clinician',
  GUEST = 'Guest',
  ALL = 'All',
}

enum PagesEnum {
  Users = 'users',
  Projects = 'projects',
  Sessions = 'sessions',
  Files = 'Files Repository',
  Workstations = 'workstations',
  Devices = 'devices',
}

const Pages: Record<PagesEnum, ProfileMode[]> = {
  [PagesEnum.Users]: [ProfileMode.GUEST, ProfileMode.ADMIN],
  [PagesEnum.Projects]: [ProfileMode.GUEST, ProfileMode.ADMIN],

  [PagesEnum.Workstations]: [ProfileMode.GUEST, ProfileMode.ADMIN],
  [PagesEnum.Devices]: [ProfileMode.GUEST, ProfileMode.ADMIN],
  [PagesEnum.Files]: [ProfileMode.GUEST, ProfileMode.ADMIN, ProfileMode.SERVICE, ProfileMode.CLINICIAN],
  [PagesEnum.Sessions]: [ProfileMode.GUEST, ProfileMode.ADMIN, ProfileMode.SERVICE, ProfileMode.CLINICIAN],
};

interface SideBarIconProps {
  iconKey: string;
}

export const SIDE_BAR_WIDTH = '15rem';

const SideBarIcon: React.FC<SideBarIconProps> = function ({ iconKey }) {
  if (iconKey === PagesEnum.Users) return <ManageAccounts />;
  if (iconKey === PagesEnum.Projects) return <ListAlt />;
  if (iconKey === PagesEnum.Workstations) return <QueuePlayNext />;
  if (iconKey === PagesEnum.Devices) return <Devices />;
  if (iconKey === PagesEnum.Sessions) return <ShowChart />;
  if (iconKey === PagesEnum.Files) return <CloudUpload />;
  return <HelpOutline />;
};

const mapNavigateLocation = (to: string) => {
  if (to === PagesEnum.Files) return 'files';
  return to;
};

const SideBar: React.FC = function () {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();

  // Auth BL
  const { pathname } = useLocation();

  const { authState } = useAuth();

  const [authStateLS, setAuthStateLS] = useLocalStorage<AuthState>(CLIENT_INFO_KEY);

  // TODO: migrate from UserAccount interface, hide an excess of data from token to separate type
  const { error, isLoading: isAuthorizing, customer } = useTypedSelector(authSelector);

  // Files BL
  const { isUploading, hasUploaded } = useTypedSelector(utilsSelector);

  const [open, setOpen] = React.useState(false);
  const [link, setLink] = React.useState('/');

  const handleClickOpen = (e) => {
    e.preventDefault();

    setOpen(true);
  };

  const handleClose = (e) => {
    e.preventDefault();

    setOpen(false);
  };

  const leaveHandler = async (e) => {
    e.preventDefault();

    setAuthStateLS((prev) => ({ ...prev, isUploading: false } as AuthState));

    dispatch(changeUpoadingStatus(false));
    dispatch(changeUpoadedStatus(true));

    navigate(link, { replace: true });
    dispatch(setSelectedFiles([]));
    handleClose(e);
  };

  useEffect(() => {
    if (error) enqueueSnackbar(JSON.stringify(error), { variant: 'error' });
  }, [error]);

  const onElementClick = useCallback(
    (page: string) => (event) => {
      event.preventDefault();

      setLink(`/${mapNavigateLocation(page)}`);
      if (isUploading || !hasUploaded) {
        handleClickOpen(event);
      } else {
        navigate(`/${mapNavigateLocation(page)}`, { replace: true });
        dispatch(setSelectedFiles([]));
      }
    },
    [isUploading, hasUploaded],
  );

  return (
    <>
      <Drawer
        variant="permanent"
        sx={{
          width: SIDE_BAR_WIDTH,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: SIDE_BAR_WIDTH, boxSizing: 'border-box' },
        }}
      >
        <Toolbar />
        <Paper sx={{ overflow: 'none' }} elevation={0}>
          {isAuthorizing && authState ? (
            <Box padding={'15px'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
              <Skeleton variant="circular" animation="wave" width={32} height={32} sx={{ margin: '10px 0' }} />

              <Box display={'flex'}>
                <Skeleton variant="text" animation="wave" width={70} sx={{ marginRight: '20px' }} />
                <Skeleton variant="text" animation="wave" width={70} />
              </Box>

              <Skeleton variant="text" animation="wave" width={100} />
              <Skeleton variant="text" animation="wave" width={100} style={{ marginTop: '10px' }} />
            </Box>
          ) : (
            <Box padding={'15px'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
              <AccountCircle color={'primary'} fontSize={'large'} sx={{ margin: '10px 0' }} />

              <Typography variant={'subtitle2'} sx={{ textTransform: 'capitalize' }}>
                {authState?.userInfo?.firstName} {authState?.userInfo?.lastName}
              </Typography>
              <Typography variant={'subtitle2'} style={{ fontWeight: '400' }}>
                {authState?.userInfo?.email}
              </Typography>
              <Typography variant={'subtitle2'} marginTop={'15px'} color={blue[700]}>
                {authState?.profile}
              </Typography>
            </Box>
          )}

          <Divider />
          <List>
            {isAuthorizing
              ? null
              : Object.values(PagesEnum)
                  ?.filter((page) => Pages[page]?.some((requiredProfile) => requiredProfile == authState?.profile))
                  ?.map((page) => (
                    <ListItemButton
                      key={page}
                      selected={mapNavigateLocation(page) === pathname}
                      onClick={onElementClick(page)}
                    >
                      <ListItemIcon>
                        <SideBarIcon iconKey={page} />
                      </ListItemIcon>
                      <ListItemText primary={page} sx={{ textTransform: 'capitalize' }} />
                    </ListItemButton>
                  ))}
          </List>
        </Paper>
      </Drawer>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure you want to leave the current page?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You have not uploaded the specified objects.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={leaveHandler} autoFocus>
            Leave
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SideBar;
