import React, { useEffect, useMemo } from 'react';
import { useTypedDispatch, useTypedSelector } from '../../../hooks';
import {
  getSessions,
  selectFinishedAt,
  selectStartedAt,
  selectWid,
  sessionsSelector,
  workstationsSelector,
} from '../../../store/reducers';
import { useSnackbar } from 'notistack';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import {
  Box,
  Chip,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  TextField,
  Button,
  IconButton,
} from '@mui/material';
import { UserList } from '../users';
import { SessionListItem } from './SessionListItem';
import { MIN_FROM_DATE } from '../../../store/types';
import { useNavigate } from 'react-router-dom';

const SessionsListTableToolbar: React.FC = () => {
  const dispatch = useTypedDispatch();

  const { workstationSessions } = useTypedSelector(sessionsSelector);
  const { isLoading: isWorkstationsLoading, workstations } = useTypedSelector(workstationsSelector);

  useEffect(() => {
    if (workstationSessions?.selectedWid) {
      dispatch(
        getSessions({
          workstationId: workstationSessions?.selectedWid,
          fromDate: workstationSessions?.selectedFromDate,
          toDate: workstationSessions?.selectedToDate,
        }),
      );
    }
  }, [workstationSessions]);

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'} width={'100%'}>
        <FormControl sx={{ minWidth: 240, m: 1 }} size="small">
          <InputLabel id="upload-repository-label">Workstation *</InputLabel>
          <Select
            labelId="workstations-type-label"
            id="workstations-type"
            label="Workstation *"
            value={workstationSessions?.selectedWid}
            onChange={(e: any) => {
              dispatch(selectWid(e?.target?.value));
            }}
            renderValue={(selectedId) => {
              if (isWorkstationsLoading) return <CircularProgress size={32} />;
              if (selectedId && typeof selectedId === 'number') {
                return (
                  <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography>{workstations?.find((workstation) => workstation?.id === selectedId)?.name}</Typography>
                    <Chip variant="outlined" color="default" size="small" label={selectedId} />
                  </Box>
                );
              }
              return null;
            }}
          >
            {isWorkstationsLoading ? (
              <CircularProgress size={32} />
            ) : (
              workstations?.map((workstation) => {
                return (
                  <MenuItem
                    value={workstation.id}
                    key={workstation.id}
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Typography>{workstation.name}</Typography>
                    <Chip variant="outlined" color="info" size="small" label={workstation.id} />
                  </MenuItem>
                );
              })
            )}
          </Select>
        </FormControl>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={!workstationSessions?.selectedWid ? 'You must select workstation' : 'Started at'}
            value={workstationSessions.selectedFromDate}
            disabled={!workstationSessions?.selectedWid}
            minDate={new Date(MIN_FROM_DATE)}
            maxDate={new Date()}
            onChange={(selectedFromDate) => {
              if (workstationSessions?.selectedWid && selectedFromDate) {
                dispatch(selectStartedAt(dayjs(selectedFromDate)));
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ m: 1 }}
                size={'small'}
                onKeyDown={(e) => {
                  if (e?.code === 'Backspace') dispatch(selectStartedAt(null));
                }}
              />
            )}
          />
          <DatePicker
            label={!workstationSessions?.selectedWid ? 'You must select workstation' : 'Finished at'}
            value={workstationSessions.selectedToDate}
            disabled={!workstationSessions?.selectedWid}
            minDate={new Date(workstationSessions?.selectedFromDate?.toString() || MIN_FROM_DATE)}
            maxDate={new Date()}
            onChange={(selectedToDate) => {
              if (workstationSessions?.selectedWid && selectedToDate) {
                dispatch(selectFinishedAt(dayjs(selectedToDate)));
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ m: 1 }}
                size={'small'}
                onKeyDown={(e) => {
                  if (e?.code === 'Backspace') dispatch(selectFinishedAt(null));
                }}
              />
            )}
          />
        </LocalizationProvider>
      </Box>
    </Toolbar>
  );
};

export const SessionList: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { isLoading: isLoadingSessions, sessions, error: sessionsError } = useTypedSelector(sessionsSelector);

  useEffect(() => {
    if (sessionsError) {
      enqueueSnackbar(sessionsError, { variant: 'error' });
    }
  }, [sessionsError]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        flexGrow: 1,
        padding: '15px',
      }}
    >
      <Paper sx={{ width: '100%', mb: 2 }}>
        <SessionsListTableToolbar />

        {isLoadingSessions ? (
          <Stack spacing={1} display={'flex'} alignItems={'center'}>
            <Skeleton animation={'pulse'} variant={'text'} width={'90%'} height={'10vh'}>
              <UserList users={[]} />
            </Skeleton>
            <Skeleton animation={'pulse'} variant={'rectangular'} width={'90%'} height={'65vh'}>
              <UserList users={[]} />
            </Skeleton>
          </Stack>
        ) : (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 500 }}>
              <TableHead>
                <TableRow>
                  <TableCell width={'30px'} />
                  <TableCell align={'left'}>Workstation ID</TableCell>
                  <TableCell align={'left'}>Labels</TableCell>
                  <TableCell align={'right'}>Started at</TableCell>
                  <TableCell align={'right'}>Finished at</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sessions?.length ? (
                  sessions?.map((session) => (
                    <SessionListItem key={`${session.wid}/${session.start_time}`} session={session} />
                  ))
                ) : (
                  <TableRow hover>
                    <TableCell align={'center'} colSpan={5}>
                      <Typography>Workstation does not have any sessions</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>
    </Box>
  );
};
