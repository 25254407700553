import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { NavigateNext } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {
  assignProjects,
  createUser,
  getProjects,
  getUsers,
  projectsSelector,
  usersSelector,
} from '../../store/reducers';
import { useSnackbar } from 'notistack';
import { useDebounce } from 'use-debounce';
import { useTypedDispatch, useTypedSelector } from '../../hooks';
import { ProfileMode } from '../../components/Base/SideBar';

const StyledLink = styled(Link)`
  color: black;
  text-decoration: none;
  position: relative;
  font-weight: 400;

  &:hover {
    border-bottom: 3px solid #c62828;
    color: black;
  }
`;

const CreateUser: React.FC = function () {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const dispatch = useTypedDispatch();

  const { isLoading: isLoadingProjects, projects, error: projectsError } = useTypedSelector(projectsSelector);
  const { error: userCreateError, assignedProjects, assignedPolicies } = useTypedSelector(usersSelector);

  const [profile, setProfile] = useState<ProfileMode>(ProfileMode.GUEST);

  const [userEmail, setUserEmail] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');

  const [debouncedEmail] = useDebounce<string>(userEmail, 500);
  const [debouncedFirst] = useDebounce<string>(firstName, 500);
  const [debouncedLast] = useDebounce<string>(lastName, 500);

  useEffect(() => {
    if (!isLoadingProjects) dispatch(getProjects());
  }, []);

  useEffect(() => {
    if (userCreateError) {
      enqueueSnackbar(userCreateError, { variant: 'error' });
    }
    if (projectsError) {
      enqueueSnackbar(projectsError, { variant: 'error' });
    }
  }, [projectsError]);

  const isValidEmail = useMemo(
    () => debouncedEmail.length >= 0 || debouncedEmail.match(/\w+@\w+\.\w+/),
    [debouncedEmail],
  );

  const emailInputHandler = useCallback(
    (e) => {
      setUserEmail(e.target.value);
    },
    [setUserEmail],
  );
  const firstNameInputHandler = useCallback(
    (e) => {
      setFirstName(e.target.value);
    },
    [setFirstName],
  );
  const lastNameInputHandler = useCallback(
    (e) => {
      setLastName(e.target.value);
    },
    [setLastName],
  );
  const profileDropdownHandler = useCallback(
    (e: any) => {
      setProfile(e.target.value);
    },
    [setProfile],
  );

  const projectsAutocompleteOptions = useMemo(() => projects?.map((project) => project?.name), [projects]);

  const userSubmitHandler = useCallback(
    (e) => {
      e.preventDefault();

      dispatch(
        createUser({
          email: debouncedEmail,
          firstName: debouncedFirst,
          lastName: debouncedLast,
          profile: profile,
          projects: isLoadingProjects ? undefined : assignedProjects,
        }),
      ).then((data: { error: any }) => {
        if (!data.error) {
          enqueueSnackbar('User has been successfully created!', {
            variant: 'success',
          });
          dispatch(getUsers());
        }
      });

      navigate('/users');
    },
    [debouncedEmail, debouncedFirst, debouncedLast, profile, assignedProjects, assignedPolicies],
  );

  const isDisabledCreateButton = useMemo(
    () => (!isValidEmail || !debouncedFirst || !debouncedLast ? !assignedPolicies.length : false),
    [isValidEmail, debouncedFirst, debouncedLast, assignedPolicies.length],
  );

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '15px',
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: '15px',
        }}
      >
        <Typography variant="h6" align="left">
          Enroll new user
        </Typography>
      </Box>

      <Divider />

      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNext fontSize="small" />}
        sx={{
          padding: '15px',
        }}
      >
        <StyledLink to="/users">Users</StyledLink>
        <Typography
          color="black"
          style={{
            fontWeight: '700',
          }}
        >
          Create user
        </Typography>
      </Breadcrumbs>

      <Paper>
        <Box
          style={{
            padding: '15px',
          }}
        >
          <form onSubmit={userSubmitHandler}>
            <FormControl error={!isValidEmail} required variant="outlined" style={{ width: '30%' }}>
              <TextField
                onChange={emailInputHandler}
                error={!isValidEmail}
                id="user-email"
                label={'User email *'}
                aria-describedby="user-email"
              />
            </FormControl>

            <Box style={{ width: '100%', margin: '15px 0', display: 'flex' }}>
              <TextField
                onChange={firstNameInputHandler}
                id="first-name"
                label={'First name *'}
                aria-describedby="first-name"
                style={{ marginRight: '15px', width: '25%' }}
              />
              <TextField
                onChange={lastNameInputHandler}
                id="last-name"
                label={'Last name *'}
                aria-describedby="last-name"
                style={{ marginRight: '15px', width: '25%' }}
              />
              <FormControl style={{ width: '25%' }}>
                <InputLabel id="profile-label-id">Profile *</InputLabel>
                <Select
                  labelId={'profile-label-id'}
                  id="profile"
                  value={profile}
                  label="Profile *"
                  aria-describedby="profile"
                  onChange={profileDropdownHandler}
                >
                  <MenuItem value={ProfileMode.ADMIN}>{ProfileMode.ADMIN}</MenuItem>
                  <MenuItem value={ProfileMode.SERVICE}>{ProfileMode.SERVICE}</MenuItem>
                  <MenuItem value={ProfileMode.CLINICIAN}>{ProfileMode.CLINICIAN}</MenuItem>
                  <MenuItem value={ProfileMode.GUEST}>{ProfileMode.GUEST}</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box style={{ padding: '15px' }}>
              <Typography
                color="black"
                variant="subtitle1"
                style={{
                  margin: '20px 0',
                }}
              >
                Assign User to Projects
              </Typography>
              <Typography
                color="black"
                variant="subtitle1"
                style={{
                  margin: '20px 0',
                  fontStyle: 'italic',
                }}
              >
                You are able to assign the user into single or several projects
              </Typography>

              {isLoadingProjects ? (
                <Box padding={'15px 0px'} display={'flex'} alignItems={'center'}>
                  <CircularProgress style={{ marginRight: '15px' }} />
                  <Typography
                    color="black"
                    variant="subtitle1"
                    style={{
                      fontStyle: 'italic',
                    }}
                  >
                    Existing projects and their roles are loading...
                  </Typography>
                </Box>
              ) : (
                <Autocomplete
                  multiple
                  id="projects-multiple"
                  options={projectsAutocompleteOptions}
                  getOptionLabel={(option) => option}
                  filterSelectedOptions
                  value={assignedProjects}
                  onChange={(event, newValue) => {
                    dispatch(assignProjects(newValue));
                  }}
                  limitTags={5}
                  renderInput={(params) =>
                    isLoadingProjects ? (
                      <Box padding={'15px 0px'} display={'flex'} alignItems={'center'}>
                        <CircularProgress style={{ marginRight: '15px' }} />
                        <Typography
                          color="black"
                          variant="subtitle1"
                          style={{
                            fontStyle: 'italic',
                          }}
                        >
                          Existing projects are loading...
                        </Typography>
                      </Box>
                    ) : (
                      <TextField {...params} label="Assigned projects" placeholder="Project" />
                    )
                  }
                />
              )}
            </Box>

            <Divider />

            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'right',
                padding: '15px',
              }}
            >
              <Button variant="outlined" onClick={() => navigate('/users')}>
                cancel
              </Button>
              <Button
                type="submit"
                disabled={isDisabledCreateButton}
                variant="contained"
                style={{ marginLeft: '10px' }}
              >
                create
              </Button>
            </Box>
          </form>
        </Box>
      </Paper>
    </Box>
  );
};

export default CreateUser;
