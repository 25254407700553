import React from 'react';
import { Workstation } from '../../../store/types';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import WorkstationsListItem from './WorkstationsListItem';

interface WorkstationListProps {
  workstations: Workstation[];
}

export const WorkstationList: React.FC<WorkstationListProps> = function ({ workstations }) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Labels</TableCell>
            <TableCell align="right" />
          </TableRow>
        </TableHead>
        <TableBody>
          {workstations?.length ? (
            workstations?.map((workstation) => <WorkstationsListItem key={workstation.id} workstation={workstation} />)
          ) : (
            <TableRow hover>
              <TableCell align={'center'} colSpan={4}>
                <Typography>There are no workstations</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
