import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { AsyncThunkStatus, ICreateProject, Project, ProjectWorkstationMap } from '../types';
import { authAxios } from '../../context/AxiosProvider';

export const getProjects = createAsyncThunk('GET /api/projects', async function (_, { rejectWithValue }) {
  try {
    const response = await authAxios.get(`/api/projects`);

    return response.data;
  } catch (err) {
    return rejectWithValue((err as Error).message);
  }
});
export const getProject = createAsyncThunk(
  'GET /api/project',
  async function (projectName: string, { rejectWithValue }) {
    try {
      const response = await authAxios.get(`/api/projects`, {
        params: {
          name: projectName,
        },
      });

      return response.data;
    } catch (err) {
      return rejectWithValue((err as Error).message);
    }
  },
);
export const createProject = createAsyncThunk(
  'POST /api/projects',
  async function (project: ICreateProject, { rejectWithValue }) {
    try {
      const response = await authAxios.post(`/api/projects`, project);

      return response.data;
    } catch (err) {
      return rejectWithValue((err as Error).message);
    }
  },
);
export const updateProject = createAsyncThunk(
  'PUT /api/projects',
  async function (project: ICreateProject, { rejectWithValue }) {
    try {
      const response = await authAxios.put(`/api/projects`, project);

      return response.data;
    } catch (err) {
      return rejectWithValue((err as Error).message);
    }
  },
);
export const deleteProject = createAsyncThunk(
  'DELETE /api/projects',
  async function (name: string, { rejectWithValue }) {
    try {
      const response = await authAxios.delete(`/api/projects`, {
        params: {
          name: name,
        },
      });

      return response.data;
    } catch (err) {
      return rejectWithValue((err as Error).message);
    }
  },
);

export interface ProjectState {
  projects: Project[];
  currentProject: Project;
  assignedUsers: string[];
  assignedWorkstations: ProjectWorkstationMap[];
  status: AsyncThunkStatus;
  isLoading: boolean;
  error: any;
}

const projectsSlice = createSlice({
  name: 'projects',
  initialState: {
    projects: [],
    assignedUsers: [],
    assignedWorkstations: [],
    currentProject: {} as Project,
    status: AsyncThunkStatus.idle,
    isLoading: false,
    error: null,
  },
  reducers: {
    changeStatus(state: ProjectState, action: PayloadAction<AsyncThunkStatus>) {
      state.status = action.payload;
    },
    addProject() {},
    assignWorkstationToProject(state: ProjectState, action: PayloadAction<ProjectWorkstationMap[]>) {
      state.assignedWorkstations = action.payload;
    },
    assignUserToProject(state: ProjectState, action: PayloadAction<string[]>) {
      state.assignedUsers = action.payload;
    },
  },
  extraReducers: {
    [getProjects.pending as unknown as string]: (state: ProjectState) => {
      state.isLoading = true;
      state.error = null;
      state.status = AsyncThunkStatus.pending;
      state.assignedWorkstations = [];
      state.assignedUsers = [];
      state.currentProject = {} as Project;
    },
    [getProjects.fulfilled as unknown as string]: (state: ProjectState, action: PayloadAction<Project[]>) => {
      state.isLoading = false;
      state.projects = action.payload;
      state.error = null;
      state.status = AsyncThunkStatus.fulfilled;
    },
    [getProjects.rejected as unknown as string]: (state: ProjectState, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.status = AsyncThunkStatus.rejected;
    },
    [getProject.pending as unknown as string]: (state: ProjectState) => {
      state.isLoading = true;
      state.error = null;
      state.status = AsyncThunkStatus.pending;
    },
    [getProject.fulfilled as unknown as string]: (state: ProjectState, action: PayloadAction<Project>) => {
      state.isLoading = false;
      state.currentProject = action.payload;
      state.error = null;
      state.status = AsyncThunkStatus.fulfilled;
      state.assignedUsers = action.payload.users;
      state.assignedWorkstations = action.payload.workstations;
    },
    [getProject.rejected as unknown as string]: (state: ProjectState, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.status = AsyncThunkStatus.rejected;
    },
    [createProject.pending as unknown as string]: (state: ProjectState) => {
      state.isLoading = true;
      state.error = null;
      state.status = AsyncThunkStatus.pending;
    },
    [createProject.fulfilled as unknown as string]: (state: ProjectState) => {
      state.isLoading = false;
      state.error = null;
      state.status = AsyncThunkStatus.fulfilled;
    },
    [createProject.rejected as unknown as string]: (state: ProjectState, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.status = AsyncThunkStatus.rejected;
      state.assignedWorkstations = [];
      state.assignedUsers = [];
    },
    [updateProject.pending as unknown as string]: (state: ProjectState) => {
      state.isLoading = true;
      state.error = null;
      state.status = AsyncThunkStatus.pending;
    },
    [updateProject.fulfilled as unknown as string]: (state: ProjectState) => {
      state.isLoading = false;
      state.error = null;
      state.status = AsyncThunkStatus.fulfilled;
    },
    [updateProject.rejected as unknown as string]: (state: ProjectState, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.status = AsyncThunkStatus.rejected;
    },
    [deleteProject.pending as unknown as string]: (state: ProjectState) => {
      state.isLoading = true;
      state.error = null;
      state.status = AsyncThunkStatus.pending;
    },
    [deleteProject.fulfilled as unknown as string]: (state: ProjectState) => {
      state.isLoading = false;
      state.error = null;
      state.status = AsyncThunkStatus.fulfilled;
    },
    [deleteProject.rejected as unknown as string]: (state: ProjectState, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.status = AsyncThunkStatus.rejected;
    },
  },
});

export const { assignWorkstationToProject, assignUserToProject } = projectsSlice.actions;

export const projectsSelector = function (state: RootState): ProjectState {
  return state.projects;
};

export default projectsSlice.reducer;
