import React from 'react';
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface ProjectUsersListProps {
  users: string[];
}

export const ProjectUsersList: React.FC<ProjectUsersListProps> = function ({ users }) {
  const navigate = useNavigate();
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell align={'right'}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users?.map((userEmail) => (
            <TableRow key={userEmail}>
              <TableCell>{userEmail}</TableCell>
              <TableCell align={'right'}>
                <Button variant={'outlined'} onClick={() => navigate(`/users/update/${userEmail}`)}>
                  Review & Update
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
