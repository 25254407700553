import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { NavigateNext, Person } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router';
import {
  assignProjects,
  getProjects,
  getUserByEmail,
  getUsers,
  projectsSelector,
  updateUser,
  usersSelector,
} from '../../store/reducers';
import { useTypedDispatch, useTypedSelector } from '../../hooks';
import { ProfileMode } from '../../components/Base/SideBar';
import { useDebounce } from 'use-debounce/esm';

const StyledLink = styled(Link)`
  color: black;
  text-decoration: none;
  position: relative;
  font-weight: 400;

  &:hover {
    border-bottom: 3px solid #c62828;
    color: black;
  }
`;

const UpdateUser: React.FC = function () {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { email: userEmail } = useParams();

  const dispatch = useTypedDispatch();
  const { isLoading: isLoadingProjects, projects, error: errorProject } = useTypedSelector(projectsSelector);
  const {
    isLoading: isUserSelecting,
    error: userUpdateError,
    currentUser,
    assignedProjects,
  } = useTypedSelector(usersSelector);

  const [profile, setProfile] = useState<ProfileMode>(ProfileMode.GUEST);

  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');

  const [debouncedFirst] = useDebounce<string>(firstName, 500);
  const [debouncedLast] = useDebounce<string>(lastName, 500);

  useEffect(() => {
    setProfile(currentUser?.profile);
    setFirstName(currentUser?.firstName ? currentUser?.firstName : '');
    setLastName(currentUser?.lastName ? currentUser?.lastName : '');
  }, [currentUser]);

  useEffect(() => {
    if (!isLoadingProjects) dispatch(getProjects());
    if (!isUserSelecting) dispatch(getUserByEmail(userEmail || ''));
  }, []);

  useEffect(() => {
    if (userUpdateError) {
      enqueueSnackbar(userUpdateError, { variant: 'error' });
    }
    if (errorProject) {
      enqueueSnackbar(errorProject, { variant: 'error' });
    }
  }, [userUpdateError, errorProject]);

  const firstNameInputHandler = useCallback(
    (e) => {
      setFirstName(e.target.value);
    },
    [setFirstName],
  );
  const lastNameInputHandler = useCallback(
    (e) => {
      setLastName(e.target.value);
    },
    [setLastName],
  );
  const profileDropdownHandler = useCallback(
    (e: any) => {
      setProfile(e.target.value);
    },
    [setProfile],
  );

  const projectsAutocompleteOptions = useMemo(() => projects?.map((project) => project?.name), [projects]);

  const userSubmitHandler = useCallback(
    (e) => {
      e.preventDefault();

      dispatch(
        updateUser({
          email: userEmail || '',
          firstName: debouncedFirst,
          lastName: debouncedLast,
          profile: profile,
          projects: isLoadingProjects ? undefined : assignedProjects,
        }),
      ).then((data: any) => {
        if (!data.error) {
          enqueueSnackbar('User has been successfully updated!', {
            variant: 'success',
          });
          dispatch(getUsers());
        }
      });

      navigate('/users');
    },
    [userEmail, debouncedFirst, debouncedLast, profile, assignedProjects],
  );

  const isDisabledUpdateButton = useMemo(() => !debouncedFirst || !debouncedLast, [debouncedLast, debouncedFirst]);

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '15px',
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: '15px',
        }}
      >
        <Typography variant="h6" align="left">
          Update user
        </Typography>
      </Box>

      <Divider />

      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNext fontSize="small" />}
        sx={{
          padding: '15px',
        }}
      >
        <StyledLink to="/users">Users</StyledLink>
        <Typography
          color="black"
          style={{
            fontWeight: '700',
          }}
        >
          Update user
        </Typography>
      </Breadcrumbs>

      <Paper>
        <Box
          style={{
            padding: '15px',
          }}
        >
          <form onSubmit={userSubmitHandler}>
            <Box>
              <Box display={'flex'} alignItems={'center'} style={{ width: '30%' }}>
                <Person />
                <Typography
                  color="black"
                  variant="subtitle1"
                  style={{
                    margin: '20px 10px',
                  }}
                >
                  {userEmail}
                </Typography>
              </Box>

              <Divider />

              <Box display={'flex'} style={{ width: '100%', margin: '15px 0' }}>
                <TextField
                  value={debouncedFirst}
                  onChange={firstNameInputHandler}
                  id="first-name"
                  label={'First name *'}
                  aria-describedby="first-name"
                  style={{ marginRight: '15px', width: '30%' }}
                />
                <TextField
                  value={debouncedLast}
                  onChange={lastNameInputHandler}
                  id="last-name"
                  label={'Last name *'}
                  aria-describedby="last-name"
                  style={{ marginRight: '15px', width: '30%' }}
                />
                <FormControl style={{ width: '30%' }}>
                  <InputLabel id="profile-label-id">Profile *</InputLabel>
                  <Select
                    labelId={'profile-label-id'}
                    id="profile"
                    value={profile}
                    label="Profile *"
                    aria-describedby="profile"
                    onChange={profileDropdownHandler}
                  >
                    <MenuItem value={ProfileMode.ADMIN}>{ProfileMode.ADMIN}</MenuItem>
                    <MenuItem value={ProfileMode.SERVICE}>{ProfileMode.SERVICE}</MenuItem>
                    <MenuItem value={ProfileMode.CLINICIAN}>{ProfileMode.CLINICIAN}</MenuItem>
                    <MenuItem value={ProfileMode.GUEST}>{ProfileMode.GUEST}</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>

            <Box style={{ padding: '15px' }}>
              <Typography
                color="black"
                variant="subtitle1"
                style={{
                  margin: '20px 0',
                }}
              >
                Assign User to Projects
              </Typography>
              <Typography
                color="black"
                variant="subtitle1"
                style={{
                  margin: '20px 0',
                  fontStyle: 'italic',
                }}
              >
                You are able to assign the user into single or several projects
              </Typography>

              {isLoadingProjects ? (
                <Box padding={'15px 0px'} display={'flex'} alignItems={'center'}>
                  <CircularProgress style={{ marginRight: '15px' }} />
                  <Typography
                    color="black"
                    variant="subtitle1"
                    style={{
                      fontStyle: 'italic',
                    }}
                  >
                    Existing projects and their roles are loading...
                  </Typography>
                </Box>
              ) : (
                <Autocomplete
                  multiple
                  id="projects-multiple"
                  options={projectsAutocompleteOptions}
                  getOptionLabel={(option) => option}
                  filterSelectedOptions
                  value={assignedProjects}
                  onChange={(event, newValue) => {
                    dispatch(assignProjects(newValue));
                  }}
                  limitTags={5}
                  renderInput={(params) =>
                    isLoadingProjects ? (
                      <Box padding={'15px 0px'} display={'flex'} alignItems={'center'}>
                        <CircularProgress style={{ marginRight: '15px' }} />
                        <Typography
                          color="black"
                          variant="subtitle1"
                          style={{
                            fontStyle: 'italic',
                          }}
                        >
                          Existing projects are loading...
                        </Typography>
                      </Box>
                    ) : (
                      <TextField {...params} label="Assigned projects" placeholder="Project" />
                    )
                  }
                />
              )}
            </Box>

            <Divider />

            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'right',
                padding: '15px',
              }}
            >
              <Button variant="outlined" onClick={() => navigate('/users')}>
                cancel
              </Button>
              <Button
                type="submit"
                disabled={isDisabledUpdateButton}
                variant="contained"
                style={{ marginLeft: '10px' }}
              >
                update
              </Button>
            </Box>
          </form>
        </Box>
      </Paper>
    </Box>
  );
};

export default UpdateUser;
