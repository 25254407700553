import React, { useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { ReportGmailerrorred } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useQuery } from '../../hooks/useQuery';
import { useAuth } from '../../context/AuthContext';

const AuthFailedPage: React.FC = function () {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const authContext = useAuth();

  const query = useQuery();

  useEffect(() => {
    if (query.has('error')) enqueueSnackbar(`Auth Failed: ${query.get('error')}`, { variant: 'error' });
  }, [query]);

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      height={`90%`}
      padding={'15px'}
      textAlign={'center'}
    >
      <ReportGmailerrorred sx={{ width: '3rem', height: '3rem', marginBottom: '1rem' }} color={'error'} />
      <Typography variant={'subtitle1'} fontSize={20}>
        {query.get('error')}
      </Typography>
      <Button
        variant={'contained'}
        style={{
          padding: '5px 30px',
          margin: '15px',
        }}
        onClick={() => navigate('/')}
      >
        Try Again
      </Button>
    </Box>
  );
};

export default AuthFailedPage;
