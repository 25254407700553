import React, { useCallback, useMemo, useState } from 'react';
import {
  Badge,
  Box,
  Button,
  Chip,
  IconButton,
  Modal,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Delete, Edit, RemoveRedEye } from '@mui/icons-material';

import { Device } from '../../../store/types';
import { deleteDevice, getDevices } from '../../../store/reducers';
import { useTypedDispatch } from '../../../hooks';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

interface DeviceListItemProps {
  device: Device;
}

const DeviceListItem: React.FC<DeviceListItemProps> = ({ device }) => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const [deleteButtonEl, setDeleteButtonEl] = useState(null);
  const isOpenDeletePopover = useMemo(() => deleteButtonEl, []);
  const popoverDeleteId = useMemo(() => (isOpenDeletePopover ? 'simple-popover' : undefined), [isOpenDeletePopover]);

  const deleteButtonHandler = useCallback((event) => {
    setDeleteButtonEl(event.currentTarget);
  }, []);
  const deletePopoverHandler = useCallback(() => {
    setDeleteButtonEl(null);
  }, []);

  const deleteHandler = useCallback(() => {
    dispatch(deleteDevice(device.name)).then((data: { error: any }) => {
      if (!data.error) {
        enqueueSnackbar('Device has been successfully deleted!', {
          variant: 'success',
        });
        dispatch(getDevices());
      }
    });
  }, []);

  return (
    <>
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell component="th" scope="row">
          {device.name}
        </TableCell>
        <TableCell component="th" scope="row">
          {device?.labels?.map((label, index) => (
            <Chip
              key={label}
              label={label}
              color="primary"
              variant="outlined"
              style={{ marginRight: index !== device?.labels?.length - 1 ? '15px' : 0 }}
            />
          ))}
        </TableCell>
        <TableCell align="right">
          <Button
            variant="contained"
            color="inherit"
            size="small"
            startIcon={<RemoveRedEye />}
            onClick={() =>
              navigate(`/devices/view/${device.name}`, {
                state: { name: device },
              })
            }
          >
            Review
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{
              margin: '0 10px',
            }}
            onClick={() =>
              navigate(`/devices/update/${device.name}`, {
                state: { name: device },
              })
            }
          >
            <Edit />
          </Button>

          <>
            <Button
              aria-describedby={popoverDeleteId}
              variant="contained"
              color="error"
              size="small"
              style={{
                margin: '0 10px',
              }}
              onClick={deleteButtonHandler}
            >
              <Delete />
            </Button>
            <Popover
              id={popoverDeleteId}
              open={!!deleteButtonEl}
              anchorEl={deleteButtonEl}
              onClose={deletePopoverHandler}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              PaperProps={{
                style: {
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                  borderRadius: 0,
                },
              }}
            >
              <Box
                sx={{
                  backgroundColor: 'background.paper',
                  borderRadius: 1,
                  border: 1,
                  borderColor: 'info.main',
                  padding: '5px',
                }}
              >
                <Button onClick={deletePopoverHandler} color="primary" size="small">
                  cancel
                </Button>
                <Button onClick={deleteHandler} color="error" size="small">
                  delete
                </Button>
              </Box>
              <Box
                sx={{
                  position: 'relative',
                  mb: '15px',
                  '&::before': {
                    backgroundColor: 'white',
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    width: 12,
                    height: 12,
                    bottom: -6,
                    transform: 'rotate(45deg)',
                    left: 'calc(50% - 6px)',
                    borderBottom: 1,
                    borderRight: 1,
                    borderColor: 'info.main',
                  },
                }}
              />
            </Popover>
          </>
        </TableCell>
      </TableRow>
    </>
  );
};

export default DeviceListItem;
