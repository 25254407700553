import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useLocalStorage } from 'react-use';
import { useTypedDispatch, useTypedSelector } from '../hooks';
import { authorizeCustomer, authSelector, CustomerDTO, updateToken } from '../store/reducers/auth';
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { authAxios } from './AxiosProvider';
import { CLIENT_INFO_KEY, JWT_TOKEN_KEY, JWT_UPDATE_AT_KEY } from '../store/types';
import { useQuery } from '../hooks/useQuery';
import { PayloadAction } from '@reduxjs/toolkit';
import { ProfileMode } from '../components/Base/SideBar';

const AuthContext = createContext<AuthContextType>({
  authState: {
    userInfo: {} as CustomerDTO,
    isAuthenticated: false,
    isUploading: false,
    profile: ProfileMode.GUEST,
  },
  logout: async () => {},
  login: async () => {},
});

const { Provider } = AuthContext;

// TODO: migrate from UserAccount interface, hide an excess of data from token to separate type
export interface UserAccount {
  iat: number;
  exp: number;
  jti: string;
  iss: string;
  username: string;
  client_id: string;
  auth_time: number;
}

export type AuthState = {
  userInfo: CustomerDTO | null;
  isAuthenticated: boolean;
  isUploading: boolean;
  profile: ProfileMode;
};

type AuthContextType = {
  authState: AuthState;
  logout: (arg0?: boolean) => Promise<void>;
  login: () => Promise<void>;
};

export const AuthProvider: React.FC = function ({ children }) {
  // Common Ctx
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();

  // Auth Context
  const query = useQuery();
  const { error, isLoading } = useTypedSelector(authSelector);

  const [jwtToken, setJwtToken] = useLocalStorage<string | null>(JWT_TOKEN_KEY);
  const [jwtUpdateAt, setJwtUpdateAt] = useLocalStorage<number | null>(JWT_UPDATE_AT_KEY);
  const [authStateLS, setAuthStateLS] = useLocalStorage<AuthState>(CLIENT_INFO_KEY);

  useEffect(() => {
    if (error) enqueueSnackbar(JSON.stringify(error), { variant: 'error' });
  }, [error]);

  const errorState = useCallback(
    function errorState() {
      localStorage.removeItem(JWT_TOKEN_KEY);
      localStorage.removeItem(JWT_UPDATE_AT_KEY);

      localStorage.removeItem(CLIENT_INFO_KEY);

      delete authAxios.defaults.headers['Authorization'];
    },
    [authAxios?.defaults.headers['Authorization']],
  );

  async function useToken(token) {
    const parsedToken: UserAccount = jwtDecode(token);

    await setJwtToken(token);
    await setJwtUpdateAt(parsedToken.iat || null);

    if (!authAxios.defaults.headers['Authorization'] && query.has('token')) {
      authAxios.defaults.headers['Authorization'] = token;
      const action: PayloadAction<CustomerDTO> = await dispatch(authorizeCustomer());

      if (action?.payload?.profile) {
        const authState = localStorage?.getItem(CLIENT_INFO_KEY);
        const actualUploadingStatus = authState && !(JSON?.parse(authState) as AuthState)?.isUploading;

        if (!authState) {
          setAuthStateLS({
            isAuthenticated: true,
            isUploading: !!actualUploadingStatus,
            userInfo: action?.payload,
            profile: action.payload?.profile,
          } as AuthState);
        }
      }
    }
  }

  async function useEntryToken(entryToken: string) {
    if (!isLoading) {
      const action: PayloadAction<{ token: string }> = await dispatch(updateToken(entryToken));
      const token = action.payload?.token;

      if (token) {
        delete authAxios.defaults.headers['Authorization'];

        await useToken(token);

        const willExpireOn = (token: string) => {
          const parsedToken: UserAccount = jwtDecode(token);
          const tokenExpiration = parsedToken.exp;
          const prepTime = 2 * 60_000;
          // timeoutDelay = expDate - now - shift time window
          return Number(tokenExpiration * 1000 - Date.now() - prepTime);
        };

        const intervalTimeout: number = willExpireOn(token);
        setTimeout(async () => {
          await useEntryToken(token);
        }, intervalTimeout);
      }
    }
  }

  async function logout() {
    errorState();

    return navigate('/auth');
  }

  async function login() {
    if (query.has('error')) {
      enqueueSnackbar(`Auth Failed: ${query.get('error')}`, { variant: 'error' });

      return errorState();
    }

    if (query.has('token')) {
      const postAuthTokenURL = query.get('token') || '';

      await useEntryToken(postAuthTokenURL);

      return navigate('/');
    }

    if (!query.has('error') && !query.has('token')) {
      return window.location.replace(
        `${process.env.REACT_APP_API_REDIRECT_URL}/auth/login?returnTo=${window.location.origin}/auth`,
      );
    }
  }

  const value = useMemo(
    () => ({
      authState: authStateLS as AuthState,
      logout: logout,
      login: login,
    }),
    [authStateLS, logout, login],
  );

  return <Provider value={value}>{children}</Provider>;
};

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('The component this hook must be a descendant of the AuthProvider');
  }
  return context;
}
