import React from 'react';
import { Button, Chip, TableCell, TableRow } from '@mui/material';

import { Workstation } from '../../../store/types';
import { useNavigate } from 'react-router-dom';
import { RemoveRedEye } from '@mui/icons-material';

interface WorkstationListItemProps {
  workstation: Workstation;
}

const WorkstationListItem: React.FC<WorkstationListItemProps> = ({ workstation }) => {
  const navigate = useNavigate();

  return (
    <>
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell component="th" scope="row">
          {workstation.id}
        </TableCell>
        <TableCell component="th" scope="row">
          {workstation.name}
        </TableCell>
        <TableCell component="th" scope="row">
          {workstation?.labels?.map((label, index) => (
            <Chip
              key={label}
              label={label}
              color="primary"
              variant="outlined"
              style={{ marginRight: index !== workstation?.labels?.length - 1 ? '15px' : 0 }}
            />
          ))}
        </TableCell>
        <TableCell align="right">
          <Button
            variant="contained"
            color="inherit"
            size="small"
            startIcon={<RemoveRedEye />}
            onClick={() =>
              navigate(`/workstations/view/${workstation.id}`, {
                state: { id: workstation },
              })
            }
          >
            Review
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
};

export default WorkstationListItem;
