import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { Link, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Box,
  Breadcrumbs,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Popover,
  Skeleton,
  Typography,
} from '@mui/material';
import styled from 'styled-components';
import { useTypedDispatch, useTypedSelector } from '../../hooks';
import {
  deleteWorkstation,
  getSessions,
  getWorkstation,
  getWorkstations,
  workstationsSelector,
  sessionsSelector,
} from '../../store/reducers';
import { NavigateNext } from '@mui/icons-material';
import { SessionsList } from '../../components/Pages/workstations/SessionsList';

const StyledLink = styled(Link)`
  color: black;
  text-decoration: none;
  position: relative;
  font-weight: 400;

  &:hover {
    border-bottom: 3px solid #c62828;
    color: black;
  }
`;

const WorkstationView: React.FC = function () {
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const dispatch = useTypedDispatch();

  const { id: workstationId } = useParams();

  const SessionsReference = useRef<HTMLElement>(null);

  const {
    currentWorkstation,
    isLoading: isLoadingWorkstation,
    error: errorWorkstation,
  } = useTypedSelector(workstationsSelector);
  const { sessions, isLoading: isLoadingSession, error: errorSession } = useTypedSelector(sessionsSelector);

  useEffect(() => {
    if (errorWorkstation) {
      enqueueSnackbar(errorWorkstation, { variant: 'error' });
    }
    if (errorSession) {
      enqueueSnackbar(errorSession, { variant: 'error' });
    }
  }, [errorWorkstation, errorSession]);

  useEffect(() => {
    if (!isLoadingWorkstation) {
      dispatch(getWorkstation(workstationId || ''));
    }
    if (!isLoadingSession) {
      dispatch(getSessions({ workstationId: workstationId || '' }));
    }
  }, []);

  const [deleteButtonEl, setDeleteButtonEl] = useState(null);
  const isOpenDeletePopover = useMemo(() => deleteButtonEl, []);
  const popoverDeleteId = useMemo(() => (isOpenDeletePopover ? 'simple-popover' : undefined), [isOpenDeletePopover]);

  const deleteButtonHandler = useCallback((event) => {
    setDeleteButtonEl(event.currentTarget);
  }, []);
  const deletePopoverHandler = useCallback(() => {
    setDeleteButtonEl(null);
  }, []);

  const deleteHandler = useCallback(() => {
    dispatch(deleteWorkstation(workstationId || '')).then((data: { error: any }) => {
      if (!data.error) {
        enqueueSnackbar('Workstation has been successfully deleted!', {
          variant: 'success',
        });
        dispatch(getWorkstations());

        navigate('/workstations');
      }
    });
  }, []);

  const deleteButtonValidation = useMemo(
    () => isLoadingSession || !!sessions?.length || !currentWorkstation,
    [isLoadingSession, sessions?.length, currentWorkstation],
  );

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '15px',
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: '15px',
        }}
      >
        {isLoadingWorkstation ? (
          <CircularProgress size={32} />
        ) : currentWorkstation?.name ? (
          <Typography variant="h6" align="left">
            {currentWorkstation?.name}
          </Typography>
        ) : (
          <Typography>None</Typography>
        )}
      </Box>

      <Divider />

      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNext fontSize="small" />}
        sx={{
          padding: '15px',
        }}
      >
        <StyledLink to="/workstations">Workstations</StyledLink>
        {isLoadingWorkstation ? (
          <CircularProgress size={28} />
        ) : currentWorkstation?.name ? (
          <Typography
            color="black"
            style={{
              fontWeight: '700',
            }}
          >
            {currentWorkstation?.name}
          </Typography>
        ) : (
          <Typography variant={'subtitle1'}>None</Typography>
        )}
      </Breadcrumbs>

      <Paper>
        <Box
          style={{
            padding: '15px',
          }}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              padding: '0 15px',
              margin: '15px 0',
              width: '100%',
              borderLeft: '5px solid #1976d2',
              backgroundColor: '#ecf8ff',
              borderRadius: '5px',
            }}
          >
            <Grid container>
              <Grid item>
                <Grid item style={{ margin: '15px 50px 15px 0' }}>
                  <Typography style={{ fontWeight: '700' }}>ID: </Typography>
                </Grid>
                <Grid item style={{ margin: '15px 50px 15px 0' }}>
                  <Typography style={{ fontWeight: '700' }}>Name: </Typography>
                </Grid>
                <Grid item style={{ margin: '15px 50px 15px 0' }}>
                  <Typography style={{ fontWeight: '700' }}>Description: </Typography>
                </Grid>
                <Grid item style={{ margin: '15px 50px 15px 0' }}>
                  <Typography style={{ fontWeight: '700' }}>Labels: </Typography>
                </Grid>
              </Grid>
              <Grid item style={{ height: '100%' }}>
                <Grid item style={{ margin: '15px 50px 15px 0' }}>
                  <Typography>{workstationId}</Typography>
                </Grid>
                <Grid item style={{ margin: '15px 50px 15px 0' }}>
                  {isLoadingWorkstation ? (
                    <CircularProgress size={28} />
                  ) : currentWorkstation?.name ? (
                    <Typography>{currentWorkstation?.name}</Typography>
                  ) : (
                    <Typography variant={'subtitle1'}>None</Typography>
                  )}
                </Grid>
                <Grid item style={{ margin: '15px 50px 15px 0' }}>
                  {isLoadingWorkstation ? (
                    <CircularProgress size={28} />
                  ) : currentWorkstation?.description ? (
                    <Typography>{currentWorkstation?.description}</Typography>
                  ) : (
                    <Typography variant={'subtitle1'}>None</Typography>
                  )}
                </Grid>
                <Grid item style={{ margin: '15px 50px 15px 0' }}>
                  {isLoadingWorkstation ? (
                    <CircularProgress size={28} />
                  ) : currentWorkstation?.labels?.length ? (
                    currentWorkstation?.labels?.map((label) => (
                      <>
                        <Chip
                          key={label}
                          label={label}
                          color={'primary'}
                          variant={'outlined'}
                          style={{ marginRight: '15px' }}
                        />
                      </>
                    ))
                  ) : (
                    <Typography>Does not have any labels</Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>

        {isLoadingSession ? (
          <Skeleton
            width={SessionsReference?.current?.offsetWidth || '90%'}
            height={SessionsReference?.current?.offsetHeight || '90%'}
          />
        ) : (
          <Box
            ref={SessionsReference}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'start',
              width: '100%',
              flexGrow: 1,
              padding: '15px',
            }}
          >
            <Typography variant={'h6'}>Sessions</Typography>
            <SessionsList sessions={sessions} />
          </Box>
        )}

        <Divider />

        <Box display={'flex'} justifyContent={'space-between'} padding={'15px'}>
          <>
            <Button
              aria-describedby={popoverDeleteId}
              variant="outlined"
              color="error"
              disabled={deleteButtonValidation}
              onClick={deleteButtonHandler}
            >
              Delete
            </Button>
            <Popover
              id={popoverDeleteId}
              open={!!deleteButtonEl}
              anchorEl={deleteButtonEl}
              onClose={deletePopoverHandler}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              PaperProps={{
                style: {
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                  borderRadius: 0,
                },
              }}
            >
              <Box
                sx={{
                  backgroundColor: 'background.paper',
                  borderRadius: 1,
                  border: 1,
                  borderColor: 'info.main',
                  padding: '5px',
                }}
              >
                <Button onClick={deletePopoverHandler} color="primary" size="small">
                  cancel
                </Button>
                <Button onClick={deleteHandler} color="error" size="small">
                  delete
                </Button>
              </Box>
              <Box
                sx={{
                  position: 'relative',
                  mb: '15px',
                  '&::before': {
                    backgroundColor: 'white',
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    width: 12,
                    height: 12,
                    bottom: -6,
                    transform: 'rotate(45deg)',
                    left: 'calc(50% - 6px)',
                    borderBottom: 1,
                    borderRight: 1,
                    borderColor: 'info.main',
                  },
                }}
              />
            </Popover>
          </>
          <Box display={'flex'} justifyContent={'end'}>
            <Button variant={'outlined'} style={{ marginRight: '15px' }} onClick={() => navigate('/workstations')}>
              Go back
            </Button>
            <Button variant={'contained'} onClick={() => navigate(`/workstations/update/${workstationId}`)}>
              Update Workstation
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default WorkstationView;
