import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Collapse,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { Done, KeyboardArrowDown, KeyboardArrowUp, NavigateNext, AddCircleOutlined } from '@mui/icons-material';
import {
  assignLabels,
  assignUsersToDevice,
  assignWorkstations,
  createDevice,
  devicesSelector,
  getDevices,
  getDevicesLabels,
  getUsers,
  getWorkstations,
  unassignLabels,
  unassignUsersToDevice,
  unassignWorkstations,
  usersSelector,
  workstationsSelector,
} from '../../store/reducers';
import { useSnackbar } from 'notistack';
import { Link, useNavigate } from 'react-router-dom';
import { useTypedDispatch, useTypedSelector } from '../../hooks';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  color: black;
  text-decoration: none;
  position: relative;
  font-weight: 400;

  &:hover {
    border-bottom: 3px solid #c62828;
    color: black;
  }
`;

const DeviceCreate: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const dispatch = useTypedDispatch();

  const {
    assignedUsers,
    assignedLabels,
    assignedWorkstations,
    labels,
    isLoading: isLoadingLabels,
    error: errorLabels,
  } = useTypedSelector(devicesSelector);

  const { isLoading: isLoadingUsers, users, error: usersError } = useTypedSelector(usersSelector);
  const {
    isLoading: isLoadingWorkstations,
    workstations,
    error: workstationsError,
  } = useTypedSelector(workstationsSelector);

  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  const [newLabel, setNewLabel] = useState<string>('');

  const [isUsersListOpen, setUsersListOpen] = React.useState(false);
  const [isWorkstationsListOpen, setWorkstationsListOpen] = React.useState(false);

  const [isLabelsInputOpen, setLabelsInputOpen] = React.useState(false);

  useEffect(() => {
    if (!isLoadingUsers) dispatch(getUsers());
    if (!isLoadingLabels) dispatch(getDevicesLabels());
    if (!isLoadingWorkstations) dispatch(getWorkstations());
  }, []);

  useEffect(() => {
    if (usersError) {
      enqueueSnackbar(usersError, { variant: 'error' });
    }
    if (errorLabels) {
      enqueueSnackbar(errorLabels, { variant: 'error' });
    }
    if (workstationsError) {
      enqueueSnackbar(workstationsError, { variant: 'error' });
    }
  }, [usersError, errorLabels, workstationsError]);

  const newLabelInputHandler = useCallback(
    (e) => {
      setNewLabel(e.target.value);
    },
    [setNewLabel],
  );

  const nameInputHandler = useCallback(
    (e) => {
      setName(e.target.value);
    },
    [setName],
  );

  const deviceSubmitHandler = useCallback(
    (e) => {
      e.preventDefault();

      dispatch(
        createDevice({
          name: name,
          description: description,
          users: assignedUsers,
          labels: assignedLabels,
          workstations: assignedWorkstations,
        }),
      ).then((data: { error: any }) => {
        if (!data.error) {
          enqueueSnackbar('Device has been successfully created!', {
            variant: 'success',
          });
          dispatch(getDevices());
        }
      });

      navigate('/devices');
    },
    [name, description, assignedUsers, assignedWorkstations, assignedLabels],
  );

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '15px',
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: '15px',
        }}
      >
        <Typography variant="h6" align="left">
          Add new device
        </Typography>
      </Box>

      <Divider />

      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNext fontSize="small" />}
        sx={{
          padding: '15px',
        }}
      >
        <StyledLink to="/devices">Devices</StyledLink>
        <Typography
          color="black"
          style={{
            fontWeight: '700',
          }}
        >
          Create device
        </Typography>
      </Breadcrumbs>

      <Paper>
        <Box
          style={{
            padding: '15px',
          }}
        >
          <form onSubmit={deviceSubmitHandler}>
            <FormControl required variant="outlined" fullWidth>
              <TextField
                onChange={nameInputHandler}
                id="device-name"
                label={'Device name *'}
                aria-describedby="device-name"
              />
            </FormControl>

            <Paper sx={{ width: '100%', marginTop: '15px', padding: '10px' }} elevation={1}>
              <Box display={'flex'} alignItems={'center'}>
                <IconButton aria-label="expand row" size="small" onClick={() => setUsersListOpen((prev) => !prev)}>
                  {isUsersListOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </IconButton>
                <Typography variant={'subtitle1'}>Users</Typography>
              </Box>

              {isUsersListOpen ? <Divider /> : null}

              <Collapse in={isUsersListOpen} timeout="auto" unmountOnExit>
                <TableContainer>
                  <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'}>
                    <TableHead>
                      <TableRow>
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            indeterminate={assignedUsers.length > 0 && assignedUsers.length < users.length}
                            checked={assignedUsers.length > 0 && assignedUsers.length === users.length}
                            onClick={() => {
                              if (assignedUsers.length < users.length) {
                                for (const user of users) {
                                  dispatch(assignUsersToDevice(user.email));
                                }
                              } else {
                                for (const user of users) {
                                  dispatch(unassignUsersToDevice(user.email));
                                }
                              }
                              console.log('users: ', assignedUsers, users);
                            }}
                          />
                        </TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Profile</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {users?.map((user, index) => {
                        const isItemSelected = assignedUsers?.some((_user) => _user === user.email);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            onClick={(event) => {
                              if (!isItemSelected) dispatch(assignUsersToDevice(user.email));
                              else dispatch(unassignUsersToDevice(user.email));
                            }}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={user.email}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                  'aria-labelledby': labelId,
                                }}
                              />
                            </TableCell>
                            <TableCell component="th" id={labelId} scope="row" padding="none">
                              {user.email}
                            </TableCell>
                            <TableCell component="th" id={labelId} scope="row" padding="none">
                              {user.firstName} {user.lastName}
                            </TableCell>
                            <TableCell component="th" id={labelId} scope="row" padding="none">
                              {user.profile}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Collapse>
            </Paper>

            <Paper sx={{ width: '100%', marginTop: '15px', padding: '10px' }} elevation={1}>
              <Box display={'flex'} alignItems={'center'}>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setWorkstationsListOpen((prev) => !prev)}
                >
                  {isWorkstationsListOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </IconButton>
                <Typography variant={'subtitle1'}>Workstations</Typography>
              </Box>

              {isWorkstationsListOpen ? <Divider /> : null}

              <Collapse in={isWorkstationsListOpen} timeout="auto" unmountOnExit>
                <TableContainer>
                  <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'}>
                    <TableHead>
                      <TableRow>
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            indeterminate={
                              assignedWorkstations.length > 0 && assignedWorkstations.length < workstations.length
                            }
                            checked={
                              assignedWorkstations.length > 0 && assignedWorkstations.length === workstations.length
                            }
                            onClick={() => {
                              if (assignedWorkstations.length < workstations.length) {
                                for (const workstation of workstations) {
                                  dispatch(assignWorkstations(workstation.id));
                                }
                              } else {
                                for (const workstation of workstations) {
                                  dispatch(unassignWorkstations(workstation.id));
                                }
                              }
                            }}
                          />
                        </TableCell>
                        <TableCell align={'left'}>ID</TableCell>
                        <TableCell>Name</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {workstations?.map((workstation, index) => {
                        const isItemSelected = assignedWorkstations?.some((_wkid) => _wkid === workstation.id);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            onClick={(event) => {
                              if (!isItemSelected) dispatch(assignWorkstations(workstation.id));
                              else dispatch(unassignWorkstations(workstation.id));
                            }}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={workstation.id}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                  'aria-labelledby': labelId,
                                }}
                              />
                            </TableCell>
                            <TableCell component="th" id={labelId} scope="row" padding="none">
                              {workstation.id}
                            </TableCell>
                            <TableCell component="th" id={labelId} scope="row" padding="none">
                              {workstation.name}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Collapse>
            </Paper>

            <FormControl
              variant="outlined"
              fullWidth
              style={{ marginTop: '15px', marginRight: '15px', display: 'flex', flexDirection: 'row', width: '100%' }}
            >
              <InputLabel id="labels-label">Labels</InputLabel>
              <Select
                fullWidth
                multiple
                labelId="labels-label"
                label={'Labels'}
                id="labels"
                startAdornment={
                  isLabelsInputOpen ? (
                    <TextField
                      autoFocus
                      onChange={newLabelInputHandler}
                      variant="standard"
                      style={{ margin: '5px 15px 5px 0' }}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          if (!assignedLabels?.some((label) => label === newLabel))
                            dispatch(assignLabels([...assignedLabels, newLabel]));
                          setLabelsInputOpen(false);
                        }
                      }}
                    />
                  ) : (
                    <IconButton onClick={() => setLabelsInputOpen(true)}>
                      <AddCircleOutlined />
                    </IconButton>
                  )
                }
                value={assignedLabels}
                onChange={(e: SelectChangeEvent<string[]>) => dispatch(assignLabels(e.target.value))}
                renderValue={(selectedList) =>
                  isLoadingLabels ? (
                    <CircularProgress size={32} style={{ marginLeft: '15px' }} />
                  ) : (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selectedList?.map((selected) => (
                        <>
                          <Chip
                            key={selected}
                            label={selected}
                            variant={'outlined'}
                            onMouseDown={(event) => {
                              event.stopPropagation();
                            }}
                            onDelete={() => dispatch(unassignLabels(selected))}
                          />
                        </>
                      ))}
                    </Box>
                  )
                }
              >
                {isLoadingLabels ? (
                  <CircularProgress style={{ marginLeft: '15px' }} />
                ) : (
                  labels.length &&
                  labels?.map((lbl) => (
                    <MenuItem key={lbl} value={lbl}>
                      {assignedLabels?.some((label) => label === lbl) ? <Done /> : <Box width={'24px'} />}
                      {lbl}
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>

            <FormControl variant="outlined" fullWidth>
              <TextField
                variant="outlined"
                multiline
                fullWidth
                maxRows={3}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                id="device-description"
                label={'Description'}
                aria-describedby="device-description-label"
                style={{ marginTop: '15px' }}
              />
            </FormControl>

            <Divider />

            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'right',
                padding: '15px',
              }}
            >
              <Button variant="outlined" onClick={() => navigate('/devices')}>
                cancel
              </Button>
              <Button type="submit" disabled={!name} variant="contained" style={{ marginLeft: '10px' }}>
                create
              </Button>
            </Box>
          </form>
        </Box>
      </Paper>
    </Box>
  );
};

export default DeviceCreate;
