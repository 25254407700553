import React, { useMemo } from 'react';
import AuthBaseLayout from '../../components/Base/AuthBaseLayout';
import { RouteProps } from 'react-router';
import { useAuth } from '../../context/AuthContext';
import { Outlet, Navigate, useNavigate } from 'react-router-dom';
import { JWT_TOKEN_KEY } from '../../store/types';
import { useLocalStorage } from 'react-use';
import { useQuery } from '../../hooks/useQuery';
import { Box, Button, Typography } from '@mui/material';
import { Home } from '@mui/icons-material';

export enum ProfileMode {
  ANY = 'Any',
  ADMIN = 'Administrator',
  SERVICE = 'Service',
  CLINICIAN = 'Clinician',
  GUEST = 'Guest',
  ALL = 'All',
}

type AuthenticatedRouteProps = {
  requiredProfiles?: string[];
  unauthenticated?: boolean;
} & RouteProps;

export const AuthenticatedRoute: React.FC<AuthenticatedRouteProps> = function ({
  unauthenticated,
  requiredProfiles = [ProfileMode.ALL],
}) {
  const navigate = useNavigate();

  const { authState } = useAuth();

  const query = useQuery();

  const [jwtToken, setJwtToken] = useLocalStorage<string | null>(JWT_TOKEN_KEY);

  const hasRequiredProfiles = useMemo(() => {
    // If any requiredRoles specified for route it should be visible
    if (!requiredProfiles || requiredProfiles.length === 0) return false;
    // Customer should have at least 1 predefined profiles(portal roles)
    if (requiredProfiles.some((item) => item === ProfileMode.ANY)) return true;

    // TODO: add validation for ProfileMode.ALL

    // If customer has all required roles
    return requiredProfiles?.some((requiredProfile) => requiredProfile === authState?.profile);
  }, [authState?.profile, requiredProfiles]);

  const isAuthenticating = query.get('error') || query.get('token');

  if (jwtToken && unauthenticated) {
  }

  if (!jwtToken && !unauthenticated && !isAuthenticating) {
    // Go to /auth to get token (kick user from route)
    return <Navigate to="/auth" />;
  }

  return (
    <AuthBaseLayout>
      {/*// TODO: add Roles verification*/}
      {(jwtToken && hasRequiredProfiles) || unauthenticated ? (
        <Outlet />
      ) : (
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={'center'}
          height={`90%`}
          padding={'15px'}
          textAlign={'center'}
        >
          <Typography variant={'subtitle2'} fontSize={18}>
            You are not authorized to view this source
          </Typography>
          <Button
            startIcon={<Home />}
            variant={'outlined'}
            style={{ padding: '5px 30px', margin: '15px' }}
            onClick={() => navigate('/')}
          >
            Home
          </Button>
        </Box>
      )}
    </AuthBaseLayout>
  );
};
