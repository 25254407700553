import { Box } from '@mui/material';
import React, { useMemo } from 'react';
import { useLocalStorage } from 'react-use';
import Header, { HEADER_HEIGHT } from './Header';
import SideBar, { SIDE_BAR_WIDTH } from './SideBar';
import { CLIENT_INFO_KEY, JWT_TOKEN_KEY } from '../../store/types';
import { useQuery } from '../../hooks/useQuery';
import { useMatch } from 'react-router-dom';
import { AuthState } from '../../context/AuthContext';

const AuthBaseLayout: React.FC = function ({ children }) {
  const query = useQuery();

  const [jwtToken, setJwtToken] = useLocalStorage<string | null>(JWT_TOKEN_KEY);
  const [clientLS, setClientLS] = useLocalStorage<AuthState>(CLIENT_INFO_KEY);

  const patchMatch = useMatch('/auth');

  const isAuthenticating = useMemo(() => patchMatch && patchMatch.pattern.end, [patchMatch]);

  const isAuthenticated = useMemo(
    () => jwtToken && !query.get('error') && !isAuthenticating && localStorage.getItem(JWT_TOKEN_KEY),
    [jwtToken, isAuthenticating, query.get('error'), localStorage.getItem(JWT_TOKEN_KEY)],
  );

  return (
    <>
      <header>
        <Header />
      </header>
      <main>
        <nav>{!isAuthenticated ? null : <SideBar />}</nav>
        <Box
          style={{
            marginLeft: isAuthenticated ? SIDE_BAR_WIDTH : 0,
            marginTop: HEADER_HEIGHT,
            width: `calc(100vw-${isAuthenticated ? SIDE_BAR_WIDTH : 0})`,
            maxHeight: `calc(100vh-${HEADER_HEIGHT})`,
            scrollbarWidth: 'none',
            overflow: '-moz-hidden-unscrollable',
          }}
        >
          {children}
        </Box>
      </main>
    </>
  );
};

export default AuthBaseLayout;
