import React, { Suspense, useMemo } from 'react';
import { CircularProgress, CssBaseline } from '@mui/material';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useBeforeunload } from 'react-beforeunload';

import { AuthenticatedRoute } from '../../utils/auth/AuthenticatedRoute';

import UsersPage from '../user/users';
import CreateUserPage from '../user/createUser';
import UpdateUserPage from '../user/updateUser';

import ProjectPage from '../projects/projects';
import ProjectViewPage from '../projects/projectView';
import ProjectCreatePage from '../projects/projectCreate';
import ProjectUpdatePage from '../projects/projectUpdate';

import WorkstationPage from '../workstation/workstations';
import WorkstationCreatePage from '../workstation/workstationCreate';
import WorkstationUpdatePage from '../workstation/workstationUpdate';
import WorkstationViewPage from '../workstation/workstationView';

import DevicePage from '../device/devices';
import DeviceCreatePage from '../device/deviceCreate';
import DeviceUpdatePage from '../device/deviceUpdate';
import DeviceViewPage from '../device/deviceView';

import FilesPage from '../files/files';
import UploadFilesPage from '../files/uploadFiles';

import SessionsPage from '../session/sessions';
import SessionDetailedPage from '../session/sessionView';

import AuthPage from '../auth/sso';

import NotFoundPage from '../notFound';

import { CLIENT_INFO_KEY, JWT_TOKEN_KEY } from '../../store/types';
import { useLocalStorage } from 'react-use';
import { AuthState } from '../../context/AuthContext';
import { ProfileMode } from '../../components/Base/SideBar';
import { useTypedSelector } from '../../hooks';
import { authSelector } from '../../store/reducers/auth';
import { uploadSelector } from '../../store/reducers/fileStore';

export const AppRoutes: React.FC = function () {
  const [jwtToken, setJwtToken] = useLocalStorage<string | null>(JWT_TOKEN_KEY);
  const [authStateLS, setAuthStateLS] = useLocalStorage<AuthState>(CLIENT_INFO_KEY);

  const { error, isLoading, customer } = useTypedSelector(authSelector);
  const { files } = useTypedSelector(uploadSelector);

  useBeforeunload(
    (event) => {
      if (files.length && localStorage?.getItem(CLIENT_INFO_KEY)) {
        event.preventDefault();
      }
    },
    [files.length],
  );

  const initialRoute: string = useMemo(() => {
    if (authStateLS?.profile === ProfileMode.ADMIN || customer?.profile === ProfileMode.ADMIN) return '/users';
    if (authStateLS?.profile === ProfileMode.GUEST || customer?.profile === ProfileMode.GUEST) return '/users';
    if (authStateLS?.profile === ProfileMode.SERVICE || customer?.profile === ProfileMode.SERVICE) return '/sessions';
    if (authStateLS?.profile === ProfileMode.CLINICIAN || customer?.profile === ProfileMode.CLINICIAN)
      return '/sessions';
    if (!jwtToken) return '/auth';
    return '';
  }, [authStateLS?.profile, customer?.profile, jwtToken]);

  return (
    <Suspense fallback={() => <CircularProgress color="success" />}>
      <CssBaseline />
      <Routes>
        <Route
          // @ts-ignore
          index
          path="/"
          element={<Navigate to={initialRoute} />}
        />

        <Route element={<AuthenticatedRoute requiredProfiles={[ProfileMode.ANY]} unauthenticated />}>
          <Route path="/auth" element={<AuthPage />} />

          <Route path="/not-found" element={<NotFoundPage />} />
          <Route path="/*" element={<NotFoundPage />} />
        </Route>

        <Route element={<AuthenticatedRoute requiredProfiles={[ProfileMode.GUEST, ProfileMode.ADMIN]} />}>
          <Route path="/users" element={<UsersPage />} />
          <Route path="/users/new" element={<CreateUserPage />} />
          <Route path="/users/update/:email" element={<UpdateUserPage />} />

          <Route path="/projects" element={<ProjectPage />} />
          <Route path="/projects/new" element={<ProjectCreatePage />} />
          <Route path="/projects/view/:name" element={<ProjectViewPage />} />
          <Route path="/projects/update/:name" element={<ProjectUpdatePage />} />

          <Route path="/workstations" element={<WorkstationPage />} />
          <Route path="/workstations/new" element={<WorkstationCreatePage />} />
          <Route path="/workstations/update/:id" element={<WorkstationUpdatePage />} />
          <Route path="/workstations/view/:id" element={<WorkstationViewPage />} />

          <Route path="/devices" element={<DevicePage />} />
          <Route path="/devices/new" element={<DeviceCreatePage />} />
          <Route path="/devices/update/:name" element={<DeviceUpdatePage />} />
          <Route path="/devices/view/:name" element={<DeviceViewPage />} />
        </Route>

        <Route
          element={
            <AuthenticatedRoute
              requiredProfiles={[ProfileMode.GUEST, ProfileMode.ADMIN, ProfileMode.SERVICE, ProfileMode.CLINICIAN]}
            />
          }
        >
          <Route path="/files" element={<FilesPage />} />
          <Route path="/files/upload" element={<UploadFilesPage />} />
          <Route path="/sessions" element={<SessionsPage />} />
          <Route path="/sessions/view" element={<SessionDetailedPage />} />
        </Route>
      </Routes>
    </Suspense>
  );
};
