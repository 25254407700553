import React from 'react';
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ProjectWorkstationMap } from '../../../../store/types';

interface ProjectWorkstationsListProps {
  workstations: ProjectWorkstationMap[];
}

export const ProjectWorkstationsList: React.FC<ProjectWorkstationsListProps> = function ({ workstations }) {
  const navigate = useNavigate();
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align={'right'}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {workstations?.map((workstation) => (
            <TableRow key={workstation?.id}>
              <TableCell>{workstation?.name}</TableCell>
              <TableCell align={'right'}>
                <Button variant={'outlined'} onClick={() => navigate(`/workstations/view/${workstation?.id}`)}>
                  Review
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
