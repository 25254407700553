import React, { useCallback, useEffect, useMemo, useState, memo } from 'react';
import {
  Box,
  Breadcrumbs,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Typography,
  Link,
} from '@mui/material';
import { NavigateNext } from '@mui/icons-material';
import { Link as NavLink, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTypedDispatch, useTypedSelector } from '../../hooks';
import styled from 'styled-components';
import { EnhancedTable, UploadedFileDTO } from '../../components/Pages/files/upload/SelectedFilesTable';
import { useLocalStorage } from 'react-use';
import { CLIENT_INFO_KEY, UploadRepository } from '../../store/types';
import { AuthState } from '../../context/AuthContext';
import {
  endFileUploadSession,
  FileDTO,
  filesSelector,
  getUploadId,
  getUploadSessionId,
  resetPath,
  setSelectedFiles,
  uploadFile,
  uploadSelector,
} from '../../store/reducers/fileStore';
import { changeUpoadedStatus, changeUpoadingStatus } from '../../store/reducers/utils';

const StyledLink = styled(NavLink)`
  color: black;
  text-decoration: none;
  position: relative;
  font-weight: 400;

  &:hover {
    border-bottom: 3px solid #c62828;
    color: black;
  }
`;

const UploadFilesPage = () => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const [authStateLS, setAuthStateLS] = useLocalStorage<AuthState>(CLIENT_INFO_KEY);

  const [open, setOpen] = useState(false);

  const handleClose = (e) => {
    e.preventDefault();
    setOpen(false);
  };

  const { isLoading: isUploading, files, error } = useTypedSelector(uploadSelector);
  const { currentPath, repository } = useTypedSelector(filesSelector);

  const pathLayers = useMemo<string[]>(() => currentPath.split('/')?.filter((layer) => !!layer), [currentPath]);

  useEffect(() => {
    if (files?.length)
      setAuthStateLS((prev) => ({ ...prev, isUploading: true, hasFinishedUploading: false } as AuthState));
  }, [files?.length]);

  useEffect(() => {
    setAuthStateLS((prev) => ({ ...prev, isUploading: !!files?.length, hasFinishedUploading: true } as AuthState));
  }, []);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    }
  }, [error]);

  const [uploadedFiles, setUploadedFiles] = useState<UploadedFileDTO[]>([]);
  const uploadedSize = useMemo<number>(
    () =>
      uploadedFiles?.length
        ? uploadedFiles?.map((uploadedFileDTO) => uploadedFileDTO?.uploadedSize)?.reduce((acc, curr, inx) => acc + curr)
        : 0,
    [uploadedFiles],
  );
  const totalSize = useMemo<number>(
    () => (files.length ? files?.map((file) => file?.file.size)?.reduce((acc, curr, inx) => acc + curr) : Infinity),
    [files],
  );

  useEffect(() => {
    if (files.length) {
      if (uploadedSize === totalSize && totalSize > 0) {
        setAuthStateLS((prev) => ({ ...prev, isUploading: false } as AuthState));

        dispatch(changeUpoadingStatus(false));
        dispatch(changeUpoadedStatus(true));
      }

      if (uploadedSize < totalSize) {
        setAuthStateLS((prev) => ({ ...prev, isUploading: true } as AuthState));

        dispatch(changeUpoadingStatus(true));
        dispatch(changeUpoadedStatus(false));
      }
    }
  }, [files.length, uploadedSize, totalSize, isUploading]);

  const cancelUploadingHandler = useCallback(
    (path: string) => (e) => {
      e.preventDefault();

      if (!files.length || uploadedSize === totalSize) {
        navigate('/files');
        dispatch(setSelectedFiles([]));
        dispatch(resetPath(path));
      } else {
        setOpen(true);
      }
    },
    [files.length, uploadedSize, totalSize],
  );

  const leaveUploadingHandler = (e) => {
    e.preventDefault();

    setAuthStateLS((prev) => ({ ...prev, isUploading: false } as AuthState));

    dispatch(changeUpoadingStatus(false));
    dispatch(changeUpoadedStatus(true));

    navigate('/files');
    dispatch(setSelectedFiles([]));
  };

  const uploadingProgress = useMemo(
    () => Math.round((Number.parseInt(String(uploadedSize)) / Number.parseInt(String(totalSize))) * 100),
    [totalSize, uploadedSize],
  );

  const uploadHandler = useCallback(async () => {
    setAuthStateLS((prev) => ({ ...prev, isUploading: true } as AuthState));
    dispatch(changeUpoadingStatus(true));
    // Chunk Size for uploading set as 20 Mb
    const chunkSize = 20 * 1024 * 1024;

    for (const [index, fileDTO] of files.entries()) {
      // Abort uploading, when we canceled it
      const authState = localStorage?.getItem(CLIENT_INFO_KEY);
      const actualUploadingStatus = authState && !(JSON?.parse(authState) as AuthState)?.isUploading;
      if (actualUploadingStatus) return;

      // File description
      const file: File = fileDTO.file;
      // Get file session upload id
      const prefix = file?.webkitRelativePath ? file.webkitRelativePath : file?.name;
      const actionGetUploadId = await dispatch(
        getUploadId({
          repository: fileDTO?.repository,
          prefix: currentPath ? `${currentPath}/${prefix}` : prefix,
        }),
      );

      // Collect ETags for correctly end-up upload session
      const ETags: any[] = [];
      const chunksAmount = Math.ceil(file?.size / chunkSize);

      for (let chunkNumber = 0; chunkNumber < chunksAmount; chunkNumber++) {
        // Abort uploading, when we canceled it
        const authState = localStorage?.getItem(CLIENT_INFO_KEY);
        if (
          !authState ||
          typeof (JSON?.parse(authState) as AuthState)?.isUploading !== 'boolean' ||
          !(JSON?.parse(authState) as AuthState)?.isUploading
        )
          return;

        // Splitting file by chunks by 20 mb
        const leftChunkByte = chunkNumber === 0 ? 0 : chunkNumber * chunkSize + 1;
        const rightChunkByte = chunkNumber === chunksAmount - 1 ? file?.size : (chunkNumber + 1) * chunkSize;
        const chunk = file.slice(leftChunkByte, rightChunkByte + 1);

        // Calculate how much data has been already uploaded
        setUploadedFiles((prev) => {
          const current = prev?.find((uploadedFile) => uploadedFile?.file?.name === file?.name);
          const updateUploadingDTO: UploadedFileDTO = current
            ? { ...current, uploadedSize: current?.uploadedSize + chunk?.size }
            : { file: file, repository: fileDTO?.repository, uploadedSize: chunk?.size, hasFinished: false };
          return [...prev?.filter((fileDto) => fileDto?.file?.name !== current?.file?.name), updateUploadingDTO];
        });

        // GET presigned URL and upload chunk to it
        const { payload } = await new Promise(function (res, rej) {
          try {
            setTimeout(
              () =>
                res(
                  dispatch(
                    getUploadSessionId({
                      repository: fileDTO?.repository,
                      prefix: currentPath ? `${currentPath}/${prefix}` : prefix,
                      uploadId: actionGetUploadId?.payload?.uploadId,
                      partNumber: chunkNumber + 1,
                    }),
                  ),
                ),
              400,
            );
          } catch (err) {
            rej(err);
          }
        });
        // Upload chunk to AWS S3 presigned chunk bucket
        const resultUploadingAction = await dispatch(
          uploadFile({
            url: payload?.presignedUrl,
            data: chunk,
          }),
        );

        ETags.push({
          PartNumber: chunkNumber + 1,
          ETag: resultUploadingAction?.payload,
        });
      }

      // End session with all chunks ETags
      await dispatch(
        endFileUploadSession({
          repository: fileDTO?.repository,
          prefix: currentPath ? `${currentPath}/${prefix}` : prefix,
          uploadId: actionGetUploadId?.payload?.uploadId,
          uploadParts: ETags,
        }),
      );
      // Mark file as already uploaded
      setUploadedFiles((prev) => {
        return prev?.map((fileDto) => {
          if (fileDto?.file?.name === file?.name)
            return {
              ...fileDto,
              hasFinished: true,
            };

          return fileDto;
        });
      });
    }
  }, [files, currentPath, uploadedFiles]);

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '15px',
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: '15px',
        }}
      >
        <Typography variant="h6" align="left">
          Upload Files
        </Typography>
      </Box>

      <Divider />

      <Breadcrumbs
        maxItems={3}
        aria-label="breadcrumb"
        separator={<NavigateNext fontSize="small" />}
        sx={{
          padding: '15px',
        }}
      >
        <Link underline="hover" color="inherit" onClick={cancelUploadingHandler('')}>
          {repository && repository === UploadRepository.ANIMAL_LAB ? 'Animal Lab' : null}
          {repository && repository === UploadRepository.DATA_LAKE ? 'Data Lake' : null}
        </Link>

        {pathLayers &&
          pathLayers?.map((layer, idx) => (
            <Link
              underline="hover"
              color="inherit"
              key={layer}
              onClick={cancelUploadingHandler(
                pathLayers?.slice(0, idx + 1)?.reduce((acc, curr, index) => acc + '/' + curr),
              )}
            >
              {layer}
            </Link>
          ))}

        <Typography
          color="black"
          style={{
            fontWeight: '700',
          }}
        >
          Upload files
        </Typography>
      </Breadcrumbs>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          flexGrow: 1,
          padding: '15px',
        }}
      >
        <EnhancedTable
          files={files}
          uploadedFiles={uploadedFiles}
          uploadedSize={uploadedSize}
          onDelete={(newFiles: FileDTO[]) => {
            dispatch(setSelectedFiles([...newFiles]));
          }}
        />
      </Box>

      <Divider />

      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'right',
          padding: '15px',
        }}
      >
        <Button variant="outlined" onClick={cancelUploadingHandler('')}>
          {uploadingProgress === 100 && !isUploading ? 'close' : null}
          {uploadingProgress >= 0 && uploadingProgress <= 100 && uploadedSize > 0 ? 'cancel' : null}
          {!uploadingProgress && isUploading && !uploadedSize ? 'cancel' : null}
          {!uploadingProgress && !isUploading && !uploadedSize ? 'go back' : null}
        </Button>
        {!uploadingProgress && !uploadedSize ? (
          <Button
            variant="contained"
            style={{ marginLeft: '10px' }}
            disabled={!files.length || isUploading || (uploadedSize > 0 && uploadedSize < totalSize)}
            onClick={uploadHandler}
          >
            upload
          </Button>
        ) : null}
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure you want to leave the current page?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You have not uploaded the specified objects.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={leaveUploadingHandler} autoFocus>
            Leave
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default memo(UploadFilesPage);
