import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { AsyncThunkStatus, Workstation } from '../types';
import { authAxios } from '../../context/AxiosProvider';

export const getWorkstations = createAsyncThunk('GET /api/workstations', async function (_, { rejectWithValue }) {
  try {
    const response = await authAxios.get(`/api/workstations`);

    return response.data;
  } catch (err) {
    return rejectWithValue((err as Error).message);
  }
});
export const getWorkstationLabels = createAsyncThunk(
  'GET /api/workstations/labels',
  async function (_, { rejectWithValue }) {
    try {
      const response = await authAxios.get(`/api/workstations/labels`);

      return response.data;
    } catch (err) {
      return rejectWithValue((err as Error).message);
    }
  },
);
export const getWorkstation = createAsyncThunk(
  'GET /api/workstation/{id}',
  async function (id: string, { rejectWithValue }) {
    try {
      const response = await authAxios.get(`/api/workstations/${id}`);

      return response.data[0];
    } catch (err) {
      return rejectWithValue((err as Error).message);
    }
  },
);
export const createWorkstation = createAsyncThunk(
  'POST /api/workstations',
  async function (workstation: Workstation, { rejectWithValue }) {
    try {
      const response = await authAxios.post(`/api/workstations`, {
        id: workstation.id,
        name: workstation.name,
        description: workstation.description || '',
        labels: workstation.labels,
      });

      return response.data;
    } catch (err) {
      return rejectWithValue((err as Error).message);
    }
  },
);
export const updateWorkstation = createAsyncThunk(
  'PUT /api/workstations',
  async function (workstation: Workstation, { rejectWithValue }) {
    try {
      const response = await authAxios.put(`/api/workstations`, {
        id: workstation.id,
        name: workstation.name,
        description: workstation.description || '',
        labels: workstation.labels,
      });

      return response.data;
    } catch (err) {
      return rejectWithValue((err as Error).message);
    }
  },
);
export const deleteWorkstation = createAsyncThunk(
  'DELETE /api/workstations',
  async function (workstationId: string, { rejectWithValue }) {
    try {
      const response = await authAxios.delete(`/api/workstations`, {
        params: {
          wid: workstationId,
        },
      });

      return response.data;
    } catch (err) {
      return rejectWithValue((err as Error).message);
    }
  },
);

export interface WorkstationState {
  workstations: Workstation[];
  labels: string[];
  assignedLabels: string[];
  currentWorkstation: Workstation;
  status: AsyncThunkStatus;
  isLoading: boolean;
  error: any;
}

const workstationsSlice = createSlice({
  name: 'workstations',
  initialState: {
    workstations: [],
    labels: [],
    assignedLabels: [],
    currentWorkstation: {} as Workstation,
    status: AsyncThunkStatus.idle,
    isLoading: false,
    error: null,
  },
  reducers: {
    changeStatus(state: WorkstationState, action) {
      state.status = action.payload;
    },
    assignWorkstationLabels(state: WorkstationState, action: PayloadAction<string[] | string>) {
      if (typeof action.payload === 'string') state.assignedLabels.push(action.payload);
      if (Array.isArray(action.payload)) {
        state.assignedLabels = action.payload;
      }
    },
    unassignWorkstationLabels(state: WorkstationState, action: PayloadAction<string>) {
      state.assignedLabels = state.assignedLabels.filter((lbl) => lbl !== action.payload);
    },
  },
  extraReducers: {
    [getWorkstations.pending as unknown as string]: (state: WorkstationState) => {
      state.isLoading = true;
      state.error = null;
      state.status = AsyncThunkStatus.pending;
      state.currentWorkstation = {} as Workstation;
      state.labels = [];
      state.assignedLabels = [];
    },
    [getWorkstations.fulfilled as unknown as string]: (state: WorkstationState, action) => {
      state.isLoading = false;
      state.workstations = action.payload;
      state.error = null;
      state.status = AsyncThunkStatus.fulfilled;
    },
    [getWorkstations.rejected as unknown as string]: (state: WorkstationState, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.status = AsyncThunkStatus.rejected;
    },
    [getWorkstationLabels.pending as unknown as string]: (state: WorkstationState) => {
      state.isLoading = true;
      state.error = null;
      state.status = AsyncThunkStatus.pending;
    },
    [getWorkstationLabels.fulfilled as unknown as string]: (
      state: WorkstationState,
      action: PayloadAction<string[]>,
    ) => {
      state.isLoading = false;
      state.labels = action.payload;
      state.error = null;
      state.status = AsyncThunkStatus.fulfilled;
    },
    [getWorkstationLabels.rejected as unknown as string]: (state: WorkstationState, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.status = AsyncThunkStatus.rejected;
    },
    [getWorkstation.pending as unknown as string]: (state: WorkstationState) => {
      state.isLoading = true;
      state.error = null;
      state.status = AsyncThunkStatus.pending;
    },
    [getWorkstation.fulfilled as unknown as string]: (state: WorkstationState, action: PayloadAction<Workstation>) => {
      state.isLoading = false;
      state.currentWorkstation = action.payload;
      state.error = null;
      state.status = AsyncThunkStatus.fulfilled;
      state.assignedLabels = action.payload.labels;
    },
    [getWorkstation.rejected as unknown as string]: (state: WorkstationState, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.status = AsyncThunkStatus.rejected;
    },
    [createWorkstation.pending as unknown as string]: (state: WorkstationState) => {
      state.isLoading = true;
      state.error = null;
      state.status = AsyncThunkStatus.pending;
    },
    [createWorkstation.fulfilled as unknown as string]: (state: WorkstationState) => {
      state.isLoading = false;
      state.error = null;
      state.status = AsyncThunkStatus.fulfilled;
    },
    [createWorkstation.rejected as unknown as string]: (state: WorkstationState, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.status = AsyncThunkStatus.rejected;
    },
    [updateWorkstation.pending as unknown as string]: (state: WorkstationState) => {
      state.isLoading = true;
      state.error = null;
      state.status = AsyncThunkStatus.pending;
    },
    [updateWorkstation.fulfilled as unknown as string]: (state: WorkstationState) => {
      state.isLoading = false;
      state.error = null;
      state.status = AsyncThunkStatus.fulfilled;
    },
    [updateWorkstation.rejected as unknown as string]: (state: WorkstationState, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.status = AsyncThunkStatus.rejected;
    },
  },
});

export const workstationsSelector = function (state: RootState): WorkstationState {
  return state.workstations;
};

export const { assignWorkstationLabels, unassignWorkstationLabels } = workstationsSlice.actions;

export default workstationsSlice.reducer;
