import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Chip,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Session } from '../../../store/types';
import { useTypedDispatch } from '../../../hooks';

interface SessionListItemProps {
  session: Session;
}

export const SessionListItem: React.FC<SessionListItemProps> = ({ session }) => {
  const dispatch = useTypedDispatch();

  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow hover onClick={() => navigate(`/sessions/view?wid=${session?.wid}&startedAt=${session?.start_time}`)}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={(e) => {
              e?.preventDefault();
              e?.stopPropagation();
              setOpen((prev) => !prev);
            }}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>{session.wid}</TableCell>
        <TableCell>
          {session?.labels?.length
            ? session?.labels?.map((label, index) => (
                <Chip
                  key={label}
                  label={label}
                  color="primary"
                  variant="outlined"
                  style={{ marginRight: index !== session?.labels?.length - 1 ? '15px' : 0 }}
                />
              ))
            : 'Does not have any'}
        </TableCell>
        <TableCell align={'right'}>{new Date(session.start_time * 1000).toUTCString()}</TableCell>
        <TableCell align={'right'}>{new Date(session.end_time * 1000).toUTCString()}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={open}>
            <TableContainer
              component={Paper}
              style={{
                padding: '15px',
                margin: '15px',
                borderLeft: '5px solid #1976d2',
                backgroundColor: '#ecf8ff',
                borderRadius: 5,
              }}
            >
              <Table>
                <TableHead>
                  <TableCell>Property name</TableCell>
                  <TableCell>Number of files</TableCell>
                </TableHead>
                <TableBody>
                  {Object.keys(session?.data)?.map((key) => (
                    <TableRow key={key}>
                      <TableCell>{key}</TableCell>
                      <TableCell>{session?.data[key]?.length}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
