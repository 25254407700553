import React from 'react';
import { Session } from '../../../store/types';
import { Button, Chip, IconButton, TableCell, TableRow, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { RemoveRedEye } from '@mui/icons-material';

interface ISessionsListItemProps {
  session: Session;
}
export const SessionsListItem: React.FC<ISessionsListItemProps> = ({ session }) => {
  const navigate = useNavigate();

  return (
    <TableRow hover>
      <TableCell width={'30px'} />
      <TableCell colSpan={1}>
        <Typography>{session.wid}</Typography>
      </TableCell>
      <TableCell colSpan={3}>
        {session?.labels?.length ? (
          session?.labels?.map((label) => (
            <>
              <Chip key={label} label={label} color={'primary'} variant={'outlined'} style={{ marginRight: '15px' }} />
            </>
          ))
        ) : (
          <Typography>Does not have any labels</Typography>
        )}
      </TableCell>
      <TableCell colSpan={3}>
        <Typography>{new Date(Number(session.start_time * 1000)).toUTCString()}</Typography>
      </TableCell>
      <TableCell colSpan={4}>
        <Typography>{new Date(Number(session.end_time * 1000)).toUTCString()}</Typography>
      </TableCell>
      <TableCell colSpan={1} align={'right'}>
        <Button
          variant="contained"
          color="inherit"
          size="small"
          startIcon={<RemoveRedEye />}
          onClick={() =>
            navigate(`/sessions/view?wid=${session?.wid}&startedAt=${session?.start_time}`, {
              state: {
                id: session?.wid,
              },
            })
          }
        >
          Review
        </Button>
      </TableCell>
    </TableRow>
  );
};
