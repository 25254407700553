import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import { visuallyHidden } from '@mui/utils';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, FormControl, InputLabel, LinearProgress, LinearProgressProps, MenuItem, Select } from '@mui/material';
import { UploadRepository } from '../../../../store/types';
import {
  AddCircle,
  PendingOutlined,
  AccessTimeOutlined,
  CheckCircleOutlined,
  DoDisturbOnOutlined,
  Folder,
} from '@mui/icons-material';
import {
  changeRepository,
  FileDTO,
  filesSelector,
  setSelectedFiles,
  uploadSelector,
} from '../../../../store/reducers/fileStore';
import { useTypedDispatch, useTypedSelector } from '../../../../hooks';
import { blue } from '@mui/material/colors';
import { bytesToSize } from '../../../../utils/byteMapper';
import { FilesSelectButton, FoldersSelectButton } from '../../../Common/Buttons';

interface Data {
  name: string;
  webkitRelativePath: string;
  type: string;
  size: number;
  lastModified: number;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'webkitRelativePath',
    numeric: false,
    disablePadding: true,
    label: 'Folder',
  },
  {
    id: 'type',
    numeric: true,
    disablePadding: false,
    label: 'Type',
  },
  {
    id: 'size',
    numeric: true,
    disablePadding: false,
    label: 'Size',
  },
];

interface EnhancedTableProps {
  numSelected: number;
  uploadedSize: number;
  totalSize: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

function EnhancedTableHead({
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  uploadedSize,
  totalSize,
}: EnhancedTableProps) {
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  const { isLoading: isUploading } = useTypedSelector(uploadSelector);

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {(uploadedSize > 0 && uploadedSize <= totalSize) || isUploading ? null : (
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          )}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {isUploading || (uploadedSize > 0 && uploadedSize <= totalSize) ? (
          <TableCell align={'left'}>Status</TableCell>
        ) : null}
        <TableCell align={'right'}>Last Modified</TableCell>
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
  totalFiles: number;
  uploadedFiles: UploadedFileDTO[];
  summarySize: number;
  uploadedSize: number;
  onDelete: (e: any) => void;
}

export const HeadLabelComponentMemoized = memo(function HeadLabelComponent({
  numSelected,
  totalFiles,
  summarySize,
}: {
  numSelected: number;
  totalFiles: number;
  summarySize: number;
}) {
  return (
    <>
      {numSelected > 0 ? (
        <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
          Files ({totalFiles} Total, {bytesToSize(summarySize)})
        </Typography>
      )}
    </>
  );
});

const HeadButtonComponentMemoized = memo(function HeadButtonComponent({
  numSelected,
  filesLength,
  repository,
  setRepository,
  selectFilesHandler,
  onDelete,
}: {
  numSelected: number;
  filesLength: number;
  repository: UploadRepository | undefined;
  setRepository: any;
  selectFilesHandler: any;
  onDelete: any;
}) {
  return (
    <>
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton
            onClick={(e) => {
              onDelete(e);
              if (!filesLength) {
                setRepository(undefined);
              }
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Box display={'flex'} justifyContent={'flex-start'}>
          <FormControl sx={{ minWidth: 240, m: 1 }} style={{ marginRight: '15px' }} size="small">
            <InputLabel id="upload-repository-label">Repository *</InputLabel>
            <Select
              labelId="upload-data-type-label"
              id="upload-data-type"
              value={repository}
              label="Repository *"
              disabled={!!filesLength}
              onChange={(e: any) => {
                setRepository(e?.target?.value);
              }}
            >
              <MenuItem value={UploadRepository.ANIMAL_LAB}>Animal Lab</MenuItem>
              <MenuItem value={UploadRepository.DATA_LAKE}>Data Lake</MenuItem>
            </Select>
          </FormControl>

          <FoldersSelectButton
            disabled={!repository}
            startIcon={<Folder color={!repository ? 'disabled' : 'primary'} sx={{ mr: 1 }} />}
            onClick={selectFilesHandler}
          />

          <FilesSelectButton
            disabled={!repository}
            startIcon={<AddCircle color={!repository ? 'disabled' : 'primary'} sx={{ mr: 1 }} />}
            onClick={selectFilesHandler}
          />
        </Box>
      )}
    </>
  );
});

function EnhancedTableToolbar({
  numSelected,
  totalFiles,
  uploadedFiles,
  summarySize,
  uploadedSize,
  onDelete,
}: EnhancedTableToolbarProps) {
  const dispatch = useTypedDispatch();

  const { repository: initialRepositoryValue } = useTypedSelector(filesSelector);

  const [repository, setRepository] = useState<UploadRepository | undefined>(initialRepositoryValue);

  const { isLoading: isUploading, files } = useTypedSelector(uploadSelector);

  const selectFilesHandler = async (event: any) => {
    const mappedFiles: FileDTO[] = [];
    for await (const file of event.target.files) {
      const hasFile = files?.some(
        (selectedFile) =>
          selectedFile?.file?.name === file.name && selectedFile?.file?.webkitRelativePath && file.webkitRelativePath,
      );

      if (hasFile) continue;

      mappedFiles.push({
        file: file,
        repository: repository,
      } as FileDTO);
    }

    if (mappedFiles) dispatch(setSelectedFiles([...files, ...mappedFiles]));
  };

  const uploadingProgress = useMemo(
    () => Math.round((Number.parseInt(String(uploadedSize)) / Number.parseInt(String(summarySize))) * 100),
    [summarySize, uploadedSize],
  );
  const remainingFiles = useMemo(
    () => totalFiles - uploadedFiles?.filter((uploadedFile) => uploadedFile?.hasFinished)?.length,
    [totalFiles, uploadedFiles],
  );
  const remainingSize = useMemo(() => summarySize - uploadedSize, [summarySize, uploadedSize]);

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {uploadingProgress >= 0 && uploadingProgress < 100 && uploadedSize > 0 ? (
        <Box sx={{ width: '100%' }}>
          <LinearProgressWithLabel
            value={uploadingProgress}
            style={{
              height: '7px',
              borderRadius: '5px',
            }}
          />

          <Typography sx={{ flex: '1 1 100%' }} id="tableTitle" component="div">
            Total remaining: {remainingFiles} {remainingFiles > 1 ? 'Files' : 'File'}:{' '}
            {remainingSize / 1024 < 1 ? 0 : bytesToSize(remainingSize)}
          </Typography>
        </Box>
      ) : null}
      {!uploadingProgress && !isUploading && !uploadedSize ? (
        <HeadLabelComponentMemoized totalFiles={totalFiles} numSelected={numSelected} summarySize={summarySize} />
      ) : null}
      {!uploadingProgress && !isUploading && !uploadedSize ? (
        <HeadButtonComponentMemoized
          numSelected={numSelected}
          repository={repository}
          filesLength={files?.length}
          onDelete={onDelete}
          setRepository={(val) => {
            dispatch(changeRepository(val));
            setRepository(val);
          }}
          selectFilesHandler={selectFilesHandler}
        />
      ) : null}
    </Toolbar>
  );
}

export type UploadedFileDTO = FileDTO & {
  uploadedSize: number;
  hasFinished: boolean;
};
export enum UploadingStatusFileEnum {
  Pending = 'Pending',
  InProgress = 'In Progress',
  Completed = 'Completed',
  Canceled = 'Canceled',
}

interface EnhancedTableComponentProps {
  files: FileDTO[];
  uploadedSize: number;
  uploadedFiles: UploadedFileDTO[];
  onDelete: (newFiles: FileDTO[]) => void;
}

export const EnhancedTable: React.FC<EnhancedTableComponentProps> = ({
  files,
  uploadedSize,
  uploadedFiles,
  onDelete,
}) => {
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('name');
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const { isLoading: isUploading } = useTypedSelector(uploadSelector);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = files?.map((n) => n.file.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - files.length) : 0;

  const totalSize = useMemo(
    () => (files.length ? files?.map((file) => file?.file.size)?.reduce((acc, curr, inx) => acc + curr) : 0),
    [files],
  );

  const onDeleteFiles = useCallback(() => {
    const filteredFiles = files?.filter(
      (fileDTO) => !selected.some((selectedFile) => selectedFile === fileDTO?.file.name),
    );
    onDelete(filteredFiles);
    setSelected([]);
    setPage(0);
  }, [files, selected]);

  useEffect(() => {
    if (uploadedSize > 0) setSelected([]);
  }, [uploadedSize]);

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          totalFiles={files.length}
          uploadedFiles={uploadedFiles}
          uploadedSize={uploadedSize}
          summarySize={totalSize}
          onDelete={onDeleteFiles}
        />
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'}>
            <EnhancedTableHead
              numSelected={selected.length}
              uploadedSize={uploadedSize}
              totalSize={totalSize}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={files.length}
            />
            <TableBody>
              {stableSort(
                files?.map<File>((fileDTO) => fileDTO?.file),
                getComparator(order, orderBy),
              )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((file, index) => {
                  const uploadingFileMetrics = uploadedFiles?.find(
                    (uploadingFile) => uploadingFile?.file?.name === file?.name,
                  );
                  const isItemSelected = isSelected(String(file.name));
                  const labelId = `enhanced-table-checkbox-${index}`;

                  const StatusComponent = () => {
                    if (typeof uploadingFileMetrics?.hasFinished === 'undefined') {
                      return (
                        <Box display={'flex'} alignItems={'center'}>
                          <AccessTimeOutlined style={{ marginRight: '5px' }} />
                          <Typography>{UploadingStatusFileEnum.Pending}</Typography>
                        </Box>
                      );
                    }
                    if (uploadingFileMetrics?.hasFinished) {
                      return (
                        <Box display={'flex'} alignItems={'center'}>
                          <CheckCircleOutlined color={'primary'} style={{ marginRight: '5px' }} />
                          <Typography
                            style={{
                              color: blue[700],
                            }}
                          >
                            {UploadingStatusFileEnum.Completed}
                          </Typography>
                        </Box>
                      );
                    }
                    if (!uploadingFileMetrics?.hasFinished) {
                      return (
                        <Box display={'flex'} alignItems={'center'}>
                          <PendingOutlined style={{ marginRight: '5px' }} />
                          <Typography>
                            {UploadingStatusFileEnum.InProgress} (
                            {Math.round(
                              (Number.parseInt(String(uploadingFileMetrics?.uploadedSize)) /
                                Number.parseInt(String(file?.size))) *
                                100,
                            )}
                            %)
                          </Typography>
                        </Box>
                      );
                    }

                    return (
                      <Box display={'flex'} alignItems={'center'}>
                        <DoDisturbOnOutlined style={{ marginRight: '5px' }} />
                        <Typography>{UploadingStatusFileEnum.Canceled}</Typography>
                      </Box>
                    );
                  };

                  return (
                    <TableRow
                      hover
                      onClick={(event) => {
                        if (uploadedSize > 0 && uploadedSize <= totalSize) return;
                        return handleClick(event, file.name);
                      }}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={file?.webkitRelativePath + file.name}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        {(uploadedSize > 0 && uploadedSize <= totalSize) || isUploading ? null : (
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                        )}
                      </TableCell>
                      <TableCell width={'fit-content'} component="th" id={labelId} scope="row" padding="none">
                        {file.name}
                      </TableCell>
                      <TableCell width={'fit-content'} component="th" id={labelId} scope="row" padding="none">
                        {file.webkitRelativePath
                          ? file.webkitRelativePath.split('/').slice(0, -1).join('/') + '/'
                          : '-'}
                      </TableCell>
                      <TableCell align="right" width={'300px'}>
                        {file.type}
                      </TableCell>
                      <TableCell width={'fit-content'} align="right">
                        {bytesToSize(file.size)}
                      </TableCell>
                      {isUploading || (uploadedSize > 0 && uploadedSize <= totalSize) ? (
                        <TableCell align="left" width={'300px'}>
                          {<StatusComponent />}
                        </TableCell>
                      ) : null}
                      <TableCell width={'fit-content'} align="right">
                        {new Date(file?.lastModified).toUTCString()}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={files.length}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[-1]}
          page={page}
          onPageChange={handleChangePage}
        />
      </Paper>
    </Box>
  );
};
