import { configureStore, combineReducers } from '@reduxjs/toolkit';

import thunk from 'redux-thunk';

import auth from './reducers/auth';

import users from './reducers/users';
import projects from './reducers/projects';

import upload from './reducers/fileStore/upload';
import files from './reducers/fileStore/files';

import sessions from './reducers/sessions';
import workstations from './reducers/workstations';
import devices from './reducers/devices';

import utils from './reducers/utils';

const store = configureStore({
  reducer: {
    auth: auth,
    users: users,
    projects: projects,
    fileStore: combineReducers({
      upload: upload,
      files: files,
    }),
    sessions: sessions,
    workstations: workstations,
    devices: devices,
    // utils
    utils: utils,
  },
  middleware: [thunk],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
