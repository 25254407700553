import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { AsyncThunkStatus } from '../types';

export interface UtilsState {
  status: AsyncThunkStatus;
  isUploading: boolean;
  hasUploaded: boolean;
  error: any;
}

const utilsSlice = createSlice({
  name: 'utils',
  initialState: {
    status: AsyncThunkStatus.idle,
    isUploading: false,
    hasUploaded: true,
    error: null,
  },
  reducers: {
    changeStatus(state: UtilsState, action) {
      state.status = action.payload;
    },
    changeUpoadingStatus(state: UtilsState, action: PayloadAction<boolean>) {
      state.isUploading = action.payload;
    },
    changeUpoadedStatus(state: UtilsState, action: PayloadAction<boolean>) {
      state.hasUploaded = action.payload;
    },
  },
});

export const utilsSelector = function (state: RootState): UtilsState {
  return state.utils;
};

export const { changeUpoadingStatus, changeUpoadedStatus } = utilsSlice.actions;

export default utilsSlice.reducer;
