import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Box,
  Breadcrumbs,
  Button,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { AddCircleOutlined, Done, NavigateNext } from '@mui/icons-material';
import styled from 'styled-components';
import { useTypedDispatch, useTypedSelector } from '../../hooks';
import {
  assignWorkstationLabels,
  unassignWorkstationLabels,
  createWorkstation,
  getWorkstationLabels,
  workstationsSelector,
  getWorkstations,
} from '../../store/reducers';
import { useDebounce } from 'use-debounce';

const StyledLink = styled(Link)`
  color: black;
  text-decoration: none;
  position: relative;
  font-weight: 400;

  &:hover {
    border-bottom: 3px solid #c62828;
    color: black;
  }
`;

const WorkstationCreate: React.FC = function () {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const dispatch = useTypedDispatch();

  const { isLoading, currentWorkstation, error, labels, assignedLabels } = useTypedSelector(workstationsSelector);

  const [workstationId, setWorkstationId] = useState<string>('');
  const [debouncedWorkstationId] = useDebounce(workstationId, 1000);
  const errorWorkstationId = Number.isNaN(Number(debouncedWorkstationId));

  const [description, setDescription] = useState<string>('');
  const [name, setName] = useState<string>('');

  const [newLabel, setNewLabel] = useState<string>('');
  const [isLabelsInputOpen, setLabelsInputOpen] = React.useState(false);

  const newLabelInputHandler = useCallback(
    (e) => {
      setNewLabel(e.target.value);
    },
    [setNewLabel],
  );

  useEffect(() => {
    if (!isLoading) dispatch(getWorkstationLabels());
  }, []);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    }
  }, [error]);

  const workstationSubmitHandler = useCallback(
    (e) => {
      e.preventDefault();

      dispatch(
        createWorkstation({
          id: Number(workstationId),
          name: name,
          description: description,
          labels: assignedLabels,
        }),
      ).then((data: { error: any }) => {
        if (!data.error) {
          enqueueSnackbar('Workstation has been successfully created!', {
            variant: 'success',
          });
          dispatch(getWorkstations());
        }
      });

      navigate('/workstations');
    },
    [name, description, assignedLabels, workstationId],
  );

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '15px',
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: '15px',
        }}
      >
        <Typography variant="h6" align="left">
          Register new Workstation
        </Typography>
      </Box>

      <Divider />

      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNext fontSize="small" />}
        sx={{
          padding: '15px',
        }}
      >
        <StyledLink to="/workstations">Workstations</StyledLink>
        <Typography
          color="black"
          style={{
            fontWeight: '700',
          }}
        >
          New Workstation
        </Typography>
      </Breadcrumbs>

      <Paper>
        <Box
          style={{
            padding: '15px',
          }}
        >
          <FormControl required variant="outlined" fullWidth style={{ marginTop: '15px' }}>
            <TextField
              value={workstationId}
              error={errorWorkstationId && !!debouncedWorkstationId.length}
              onChange={(e) => setWorkstationId(e.target.value)}
              id="workstation-id"
              label={'Workstation ID *'}
              aria-describedby="workstation-id"
            />
          </FormControl>

          <FormControl required variant="outlined" fullWidth style={{ marginTop: '15px' }}>
            <TextField
              value={name}
              onChange={(e) => setName(e.target.value)}
              id="workstation-name"
              label={'Workstation Name'}
              aria-describedby="workstation-name"
            />
          </FormControl>

          <TextField
            variant="outlined"
            multiline
            fullWidth
            maxRows={3}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            id="workstation-description"
            label={'Description'}
            aria-describedby="role-description-label"
            style={{ marginTop: '15px' }}
          />

          <FormControl
            variant="outlined"
            fullWidth
            style={{ marginTop: '15px', marginRight: '15px', display: 'flex', flexDirection: 'row', width: '100%' }}
          >
            <InputLabel id="labels-label">Labels</InputLabel>
            <Select
              fullWidth
              multiple
              labelId="labels-label"
              label={'Labels'}
              id="labels"
              startAdornment={
                isLabelsInputOpen ? (
                  <TextField
                    autoFocus
                    onChange={newLabelInputHandler}
                    variant="standard"
                    style={{ margin: '5px 15px 5px 0' }}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        if (!assignedLabels?.some((label) => label === newLabel))
                          dispatch(assignWorkstationLabels([...assignedLabels, newLabel]));
                        setLabelsInputOpen(false);
                      }
                    }}
                  />
                ) : (
                  <IconButton onClick={() => setLabelsInputOpen(true)}>
                    <AddCircleOutlined />
                  </IconButton>
                )
              }
              value={assignedLabels}
              onChange={(e: SelectChangeEvent<string[]>) => dispatch(assignWorkstationLabels(e.target.value))}
              renderValue={(selectedList) =>
                isLoading ? (
                  <CircularProgress size={32} style={{ marginLeft: '15px' }} />
                ) : (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selectedList?.map((selected) => (
                      <>
                        <Chip
                          key={selected}
                          label={selected}
                          variant={'outlined'}
                          onMouseDown={(event) => {
                            event.stopPropagation();
                          }}
                          onDelete={() => dispatch(unassignWorkstationLabels(selected))}
                        />
                      </>
                    ))}
                  </Box>
                )
              }
            >
              {isLoading ? (
                <CircularProgress style={{ marginLeft: '15px' }} />
              ) : (
                labels.length &&
                labels?.map((lbl) => (
                  <MenuItem key={lbl} value={lbl}>
                    {assignedLabels?.some((label) => label === lbl) ? <Done /> : <Box width={'24px'} />}
                    {lbl}
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
        </Box>

        <Divider />

        <Box display={'flex'} justifyContent={'end'} padding={'15px'}>
          <Button variant={'outlined'} style={{ marginRight: '15px' }} onClick={() => navigate('/workstations')}>
            Cancel
          </Button>
          <Button
            disabled={!debouncedWorkstationId || errorWorkstationId}
            variant={'contained'}
            onClick={workstationSubmitHandler}
          >
            Create
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default WorkstationCreate;
