import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import store from './store/store';
import { AxiosProvider } from './context/AxiosProvider';
import { AuthProvider } from './context/AuthContext';
import { AppRoutes } from './flows/routes';

const App: React.FC = function () {
  const theme = createTheme({
    palette: {
      secondary: {
        main: '#e4e4e4',
        light: '#fff',
        dark: '#000',
      },
    },
  });

  return (
    <BrowserRouter>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            maxSnack={3}
            preventDuplicate
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            autoHideDuration={5000}
          >
            <AuthProvider>
              <AxiosProvider>
                <AppRoutes />
              </AxiosProvider>
            </AuthProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  );
};

export default App;
