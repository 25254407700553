import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, CircularProgress, Typography } from '@mui/material';
import { Science } from '@mui/icons-material';
import { useAuth } from '../../context/AuthContext';
import { useQuery } from '../../hooks/useQuery';
import AuthFailedPage from './authFailed';
import { JWT_TOKEN_KEY } from '../../store/types';

const SSOPage: React.FC = function () {
  const authContext = useAuth();
  const query = useQuery();

  const [jwtToken, setJwtToken] = useState<string | null>(localStorage.getItem(JWT_TOKEN_KEY));

  useEffect(() => {
    const updateJwtTokenState = () => {
      const jwtTokenReplica = localStorage.getItem(JWT_TOKEN_KEY);

      if (jwtTokenReplica) setJwtToken(jwtTokenReplica);
    };

    window.addEventListener('storage', updateJwtTokenState);
    return () => window.removeEventListener('storage', updateJwtTokenState);
  }, []);

  const isNotAuthenticated = useMemo(
    () => !jwtToken || !query.get('error') || !query.get('token'),
    [jwtToken, query.get('error'), query.get('token')],
  );

  useEffect(() => {
    if (isNotAuthenticated) authContext.login();
  }, []);

  return (
    <>
      {!query.get('error') ? (
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={'center'}
          height={`90%`}
          padding={'15px'}
          textAlign={'center'}
        >
          <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'end'} mb={4}>
            <Typography variant={'subtitle2'} fontSize={18}>
              Wait a bit! We are trying to sign in your session...
            </Typography>
            <Science fontSize={'large'} color={'primary'} />
          </Box>
          <CircularProgress sx={{ width: '4rem', height: '4rem' }} color={'primary'} />
        </Box>
      ) : (
        <AuthFailedPage />
      )}
    </>
  );
};

export default SSOPage;
