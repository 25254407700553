import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '../../hooks/useQuery';
import {
  Box,
  Breadcrumbs,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { Download, NavigateNext } from '@mui/icons-material';
import VideoPlayer from '../../components/Video/VideoPlayer';
import styled from 'styled-components';
import TableauComponent from '../../components/Tableau/TableauComponent';
import { useTypedDispatch, useTypedSelector } from '../../hooks';
import {
  getSession,
  getSessionArchiveURL,
  getSessionVideoURL,
  sessionsSelector,
  workstationsSelector,
} from '../../store/reducers';
import { downloadFile, getDownloadFileLink } from '../../store/reducers/fileStore';
import { publicAxios } from '../../context/AxiosProvider';

export const VIDEO_HEIGHT = '360px';
export const VIDEO_WIDTH = '640px';

interface VideoSeriesProps {
  title?: string;
  onClick: () => void;
  isActive?: boolean;
}

const StyledPaperSeries = styled(Paper)(({ theme }) => ({
  '&:hover': {
    filter: 'brightness(95%)',
  },
}));

const VideoSeries: React.FC<VideoSeriesProps> = ({ title, isActive, onClick }) => {
  // We are placing all time 2 series at a row
  const seriesWidth = useMemo(() => `${Number.parseInt(VIDEO_WIDTH.slice(0, -2)) / 2}px`, []);

  return (
    <Box display={'inline-block'} width={seriesWidth} padding={'5px'}>
      <StyledPaperSeries
        elevation={3}
        variant={'outlined'}
        style={{
          padding: '5px',
          display: 'flex',
          filter: isActive ? 'brightness(85%)' : '',
        }}
        onClick={onClick}
      >
        <Typography>{title}</Typography>
      </StyledPaperSeries>
    </Box>
  );
};

const SessionView: React.FC = () => {
  const query = useQuery();
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();

  const { currentSession, videoUrl } = useTypedSelector(sessionsSelector);

  const [title, setTitle] = useState('');
  const [active, setActive] = useState(`0-${currentSession?.data?.video[0]}`);

  const wid = useMemo(() => query.get('wid'), [query.get('wid')]);
  const startedAt = useMemo<number>(
    () => Number.parseInt(query.get('startedAt') || '0') * 1000,
    [query.get('startedAt')],
  );

  useEffect(() => {
    if (currentSession?.data?.video[0]) {
      dispatch(
        getSessionVideoURL({
          wid: wid,
          sessionStart: startedAt,
          fileName: currentSession?.data?.video[0],
        }),
      );
      setTitle(currentSession?.data?.video[0]);
      setActive(`0-${currentSession?.data?.video[0]}`);
    }
  }, [wid, startedAt, currentSession?.data?.video[0]]);

  useEffect(() => {
    if (!Object.keys(currentSession).length) dispatch(getSession({ wid: wid, start_time: startedAt / 1000 }));
  }, []);

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '15px',
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: '15px',
        }}
      >
        <Typography variant="h6" align="left" fontWeight={'normal'}>
          <b>Workstation ID:</b> {wid}
        </Typography>
        <Typography variant="h6" align="left" fontWeight={'normal'}>
          <b>Session started at:</b> {new Date(startedAt || 0).toUTCString()}
        </Typography>
      </Box>

      <Divider />

      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNext fontSize="small" />}
        sx={{
          padding: '15px',
        }}
      >
        <Link underline="hover" color="inherit" onClick={() => navigate(`/sessions`)}>
          Sessions
        </Link>
        <Typography
          color="black"
          style={{
            fontWeight: '700',
          }}
        >
          Review session
        </Typography>
      </Breadcrumbs>

      <Paper
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-around',
          alignItems: 'flex-start',
          padding: '15px',
        }}
      >
        <Box>
          {/*Video Player*/}
          <Box
            display={'flex'}
            justifyContent={'start'}
            flexDirection={'column'}
            style={{
              padding: '10px',
            }}
          >
            <VideoPlayer title={title} url={videoUrl} />

            {/*Carousel*/}
            <Box
              display={'flex'}
              flexWrap={'wrap'}
              alignItems={'center'}
              justifyContent={'flex-start'}
              maxWidth={VIDEO_WIDTH}
            >
              {currentSession?.data?.video?.map((videoFileName, index) => (
                <VideoSeries
                  key={`${index}-${videoFileName}`}
                  title={videoFileName}
                  isActive={`${index}-${videoFileName}` === active}
                  onClick={() => {
                    dispatch(
                      getSessionVideoURL({
                        wid: wid,
                        sessionStart: startedAt,
                        fileName: videoFileName,
                      }),
                    );
                    setActive(`${index}-${videoFileName}`);
                    setTitle(videoFileName);
                  }}
                />
              ))}
            </Box>
          </Box>

          <Box width={'100%'}>
            <TableContainer component={Paper}>
              <Table aria-label="caption table">
                <caption>A list of raw archives available</caption>
                <TableHead>
                  <TableRow>
                    <TableCell>Archive Name</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentSession?.raw_archives?.map((archive) => (
                    <TableRow key={archive} hover>
                      <TableCell component="th" scope="row">
                        {archive}
                      </TableCell>
                      <TableCell>
                        <IconButton>
                          <Download
                            onClick={async () => {
                              const { payload } = await dispatch(getSessionArchiveURL(archive));

                              await dispatch(downloadFile({ url: payload, fileName: archive }));
                            }}
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>

        <Box>
          <TableauComponent
            url={
              'https://tableau.bsci.com/t/OperationalReporting/views/operation_debug_extract_v4/Sheet1?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link'
            }
            date={startedAt}
            wid={wid}
          />
        </Box>
      </Paper>
    </Box>
  );
};

export default SessionView;
