import React, { MouseEventHandler, useCallback } from 'react';
import clsx from 'clsx';
import { Button, Container, Grid, IconButton, Slider, Theme, Tooltip, Typography } from '@mui/material';
import { Fullscreen, FastForward, FastRewind, Pause, PlayArrow, VolumeUp, VolumeOff } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme?: Theme) => ({
  controlsWrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    zIndex: 1,
  },

  controlIcon: {
    fontSize: 50,
    '&:hover': {
      color: 'white',
    },
  },

  bottomProgressBar: {
    color: 'gray',
  },

  volumeSlider: {
    width: 100,
  },
}));

function ValueLabelComponent(props: any) {
  const { children, value } = props;

  return (
    <Tooltip enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

interface IVideoPlayerControlsProps {
  volume: number;
  played: number;
  duration: number | null;
  isPlaying: boolean;
  isMuted: boolean;
  onPause: () => void;
  onMute: () => void;
  onRewind: () => void;
  onFastForward: () => void;
  onSeek: (event: Event, value: number | number[], activeThumb: number) => void;
  onSeekMouseDown: MouseEventHandler;
  onSeekMouseUp: any;
  onVolumeChange: (prevVolume: any, currentVolume: any) => void;
  onVolumeSeekUp: (prevVolume: any, currentVolume: any) => void;
  onFullscreen: () => void;
  title: string;
}

export const VideoPlayerControls: React.FC<IVideoPlayerControlsProps> = function ({
  played,
  duration,
  volume,
  isPlaying,
  isMuted,
  onMute,
  onPause,
  onRewind,
  onFastForward,
  onVolumeChange,
  onVolumeSeekUp,
  onSeek,
  onSeekMouseDown,
  onSeekMouseUp,
  onFullscreen,
  title,
}) {
  const classes = useStyles(),
    formatTime = useCallback((time: any) => {
      if (Number.isNaN(time)) return '00:00';

      const date = new Date(time * 1000),
        hh = date.getUTCHours(),
        mm = date.getUTCMinutes().toString().padStart(2, '0'),
        ss = date.getUTCSeconds().toString().padStart(2, '0');

      if (hh) return `${hh}:${mm}:${ss}`;

      return `${mm}:${ss}`;
    }, []);

  return (
    <>
      {/* Video Control Flow */}
      <Container className={classes.controlsWrapper}>
        {/* Custom Player Controls */}
        {/* Top Bar */}
        <Grid container direction="row" alignItems="center" justifyContent="space-between" style={{ padding: 16 }}>
          <Grid item>
            <Typography variant="h5" color={'secondary.light'}>
              {title}
            </Typography>
          </Grid>
        </Grid>

        {/* Video Player Controls */}
        <Grid container direction="row" alignItems="center" justifyContent="space-between">
          <IconButton className={classes.controlIcon} arial-label="remind" color={'secondary'} onClick={onRewind}>
            <FastRewind fontSize="inherit" />
          </IconButton>

          <IconButton className={classes.controlIcon} arial-label="remind" color={'secondary'} onClick={onPause}>
            {!isPlaying ? <PlayArrow fontSize="inherit" /> : <Pause fontSize="inherit" />}
          </IconButton>

          <IconButton className={classes.controlIcon} arial-label="remind" color={'secondary'} onClick={onFastForward}>
            <FastForward fontSize="inherit" />
          </IconButton>
        </Grid>

        {/* Video Progress Bar Controls */}
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          style={{
            padding: 16,
          }}
        >
          {/* Progress Bar */}
          <Grid item xs={12}>
            <Slider
              min={0}
              max={duration ? duration : undefined}
              value={played}
              onChange={onSeek}
              onMouseDown={onSeekMouseDown}
              onChangeCommitted={onSeekMouseUp}
              valueLabelDisplay={'auto'}
              valueLabelFormat={formatTime(played)}
              aria-label="Default"
            />
          </Grid>

          {/* Bottom Controls */}
          <Grid item>
            <Grid container alignItems="center" direction="row">
              {/* Pause Button */}
              <Grid item>
                <IconButton className={classes.bottomProgressBar} onClick={onPause}>
                  {!isPlaying ? (
                    <PlayArrow fontSize="large" color={'secondary'} />
                  ) : (
                    <Pause fontSize="large" color={'secondary'} />
                  )}
                </IconButton>
              </Grid>

              {/* Volume Button */}
              <Grid item>
                <IconButton className={classes.bottomProgressBar} color={'secondary'} onClick={onMute}>
                  {isMuted ? (
                    <VolumeOff fontSize="large" color={'secondary'} />
                  ) : (
                    <VolumeUp fontSize="large" color={'secondary'} />
                  )}
                </IconButton>
              </Grid>

              <Grid
                item
                minWidth="70px"
                style={{
                  margin: '0 16px',
                }}
              >
                <Slider
                  min={0}
                  max={100}
                  value={isMuted ? 0 : volume * 100}
                  className={clsx(classes.volumeSlider)}
                  onChange={onVolumeChange}
                  onChangeCommitted={onVolumeSeekUp}
                />
              </Grid>

              <Grid item>
                <Button
                  variant="text"
                  color="inherit"
                  style={{
                    color: 'white',
                  }}
                  disabled
                >
                  <Typography>
                    {formatTime(played)} / {formatTime(duration)}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
