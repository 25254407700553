import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Link, useNavigate } from 'react-router-dom';
import { useTypedDispatch, useTypedSelector } from '../../hooks';
import {
  assignUserToProject,
  assignWorkstationToProject,
  createProject,
  getProjects,
  getUsers,
  getWorkstations,
  projectsSelector,
  usersSelector,
  workstationsSelector,
} from '../../store/reducers';
import { useDebounce } from 'use-debounce';
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { Group, NavigateNext, QueuePlayNext } from '@mui/icons-material';
import styled from 'styled-components';
import { ICreateProject } from '../../store/types';

const StyledLink = styled(Link)`
  color: black;
  text-decoration: none;
  position: relative;
  font-weight: 400;

  &:hover {
    border-bottom: 3px solid #c62828;
    color: black;
  }
`;
const ProjectCreate: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const dispatch = useTypedDispatch();

  const {
    isLoading: isLoadingWorkstations,
    error: workstationsError,
    workstations,
  } = useTypedSelector(workstationsSelector);
  const { isLoading: isLoadingUsers, users, error: userError } = useTypedSelector(usersSelector);

  const {
    isLoading: isLoadingProjects,
    assignedUsers,
    assignedWorkstations,
    error: projectError,
  } = useTypedSelector(projectsSelector);

  const usersAutocompleteOptions = useMemo(() => users?.map((user) => user.email), [users]);
  const workstationsAutocompleteOptions = useMemo(
    () => workstations?.map((workstation) => ({ id: workstation?.id, name: workstation?.name })),
    [workstations],
  );

  const [name, setName] = useState<string>('');
  const [debouncedName] = useDebounce<string>(name, 500);

  const [description, setDescription] = useState<string>('');

  useEffect(() => {
    if (!workstationsError) dispatch(getWorkstations());
    if (!isLoadingUsers) dispatch(getUsers());
  }, []);

  useEffect(() => {
    if (workstationsError) {
      enqueueSnackbar(workstationsError, { variant: 'error' });
    }
    if (userError) {
      enqueueSnackbar(userError, { variant: 'error' });
    }
    if (projectError) {
      enqueueSnackbar(projectError, { variant: 'error' });
    }
  }, [workstationsError, userError, projectError]);

  const nameInputHandler = useCallback(
    (e) => {
      setName(e.target.value);
    },
    [setName],
  );

  const isValidName = useMemo(() => debouncedName.length >= 0 || debouncedName.match(/\w+/), [debouncedName]);

  const isFormValid = useMemo(() => debouncedName && isValidName, [debouncedName, isValidName]);

  const projectSubmitHandler = useCallback(
    (e) => {
      e.preventDefault();

      dispatch(
        createProject({
          name: debouncedName,
          description: description,
          users: assignedUsers,
          workstations: assignedWorkstations?.map((assignedWorkstation) => assignedWorkstation.id),
        } as ICreateProject),
      )
        .then((data: { error: any }) => {
          if (!data.error) {
            enqueueSnackbar('Project has been successfully created!', {
              variant: 'success',
            });
          }
        })
        .finally(() => {
          dispatch(getProjects());

          navigate('/projects');
        });
    },
    [debouncedName, assignedUsers, assignedWorkstations, description],
  );

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '15px',
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: '15px',
        }}
      >
        <Typography variant="h6" align="left">
          Create new project
        </Typography>
      </Box>

      <Divider />

      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNext fontSize="small" />}
        sx={{
          padding: '15px',
        }}
      >
        <StyledLink to="/projects">Projects</StyledLink>
        <Typography
          color="black"
          style={{
            fontWeight: '700',
          }}
        >
          Create project
        </Typography>
      </Breadcrumbs>

      <Paper>
        <Box
          style={{
            padding: '15px',
          }}
        >
          <form onSubmit={projectSubmitHandler}>
            <FormControl error={!isValidName} required variant="outlined" fullWidth>
              <TextField
                onChange={nameInputHandler}
                error={!isValidName}
                id="project-name"
                label={'Project Name *'}
                aria-describedby="project-name"
              />
            </FormControl>

            <Box padding={'15px'}>
              <Box display={'flex'} flexDirection={'row'} marginBottom={'15px'}>
                <QueuePlayNext fontSize={'medium'} style={{ marginRight: '15px' }} />
                <Typography color="black" fontWeight={'bold'} variant={'subtitle1'}>
                  Assign workstations to project
                </Typography>
              </Box>

              <Autocomplete
                multiple
                id="workstation-multiple"
                options={workstationsAutocompleteOptions}
                getOptionLabel={(option) => option?.name}
                filterSelectedOptions
                value={assignedWorkstations}
                onChange={(event, newValue) => {
                  dispatch(assignWorkstationToProject(newValue));
                }}
                limitTags={5}
                renderInput={(params) =>
                  isLoadingWorkstations ? (
                    <Box padding={'15px 0px'} display={'flex'} alignItems={'center'}>
                      <CircularProgress style={{ marginRight: '15px' }} />
                      <Typography
                        color="black"
                        variant="subtitle1"
                        style={{
                          fontStyle: 'italic',
                        }}
                      >
                        Existing workstations are loading...
                      </Typography>
                    </Box>
                  ) : (
                    <TextField {...params} label="Assigned workstations" placeholder="Workstation" />
                  )
                }
              />
            </Box>

            <Box padding={'15px'}>
              <Box display={'flex'} flexDirection={'row'} marginBottom={'15px'}>
                <Group fontSize={'medium'} style={{ marginRight: '15px' }} />
                <Typography color="black" fontWeight={'bold'} variant={'subtitle1'}>
                  Assign users to project
                </Typography>
              </Box>

              <Autocomplete
                multiple
                id="users-multiple"
                options={usersAutocompleteOptions}
                getOptionLabel={(option) => option}
                filterSelectedOptions
                limitTags={5}
                value={assignedUsers}
                onChange={(event, newValue) => {
                  dispatch(assignUserToProject(newValue));
                }}
                renderInput={(params) =>
                  isLoadingUsers ? (
                    <Box padding={'15px 0px'} display={'flex'} alignItems={'center'}>
                      <CircularProgress style={{ marginRight: '15px' }} />
                      <Typography
                        color="black"
                        variant="subtitle1"
                        style={{
                          fontStyle: 'italic',
                        }}
                      >
                        Existing users are loading...
                      </Typography>
                    </Box>
                  ) : (
                    <TextField {...params} label="Assigned users" placeholder="Users" />
                  )
                }
              />
            </Box>

            <TextField
              variant="outlined"
              multiline
              fullWidth
              maxRows={3}
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              id="role-description"
              label={'Description'}
              aria-describedby="role-description-label"
              style={{ marginTop: '15px' }}
            />

            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'right',
                padding: '15px',
              }}
            >
              <Button variant="outlined" onClick={() => navigate('/projects')}>
                cancel
              </Button>
              <Button
                type="submit"
                disabled={!isFormValid}
                onClick={projectSubmitHandler}
                variant="contained"
                style={{ marginLeft: '10px' }}
              >
                create
              </Button>
            </Box>
          </form>
        </Box>
      </Paper>
    </Box>
  );
};

export default ProjectCreate;
