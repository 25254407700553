import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { AsyncThunkStatus } from '../types';
import { authAxios } from '../../context/AxiosProvider';

export const updateToken = createAsyncThunk('PUT /api/token', async function (oldToken: string, { rejectWithValue }) {
  try {
    const response = await authAxios.put(
      `/api/token`,
      {},
      {
        headers: {
          Authorization: oldToken,
        },
      },
    );

    return response.data;
  } catch (err) {
    return rejectWithValue((err as Error).message);
  }
});
export const authorizeCustomer = createAsyncThunk('GET /api/whoami', async function (_, { rejectWithValue }) {
  try {
    const response = await authAxios.get(`/api/whoami`);

    return response.data;
  } catch (err) {
    return rejectWithValue((err as Error).message);
  }
});

export interface CustomerDTO {
  firstName: string;
  lastName: string;
  email: string;
  profile: string;
}

export interface AuthState {
  customer: CustomerDTO | null;
  status: AsyncThunkStatus;
  isLoading: boolean;
  error: any;
}

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    customer: null,
    status: AsyncThunkStatus.idle,
    isLoading: false,
    error: null,
  },
  reducers: {
    changeStatus(state: AuthState, action) {
      state.status = action.payload;
    },
    addProject() {},
  },
  extraReducers: {
    [updateToken.pending as unknown as string]: (state: AuthState) => {
      state.isLoading = true;
      state.error = null;
      state.status = AsyncThunkStatus.pending;
    },
    [updateToken.fulfilled as unknown as string]: (state: AuthState) => {
      state.isLoading = false;
      state.error = null;
      state.status = AsyncThunkStatus.fulfilled;
    },
    [updateToken.rejected as unknown as string]: (state: AuthState, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.status = AsyncThunkStatus.rejected;
    },
    [authorizeCustomer.pending as unknown as string]: (state: AuthState) => {
      state.isLoading = true;
      state.error = null;
      state.customer = null;
      state.status = AsyncThunkStatus.pending;
    },
    [authorizeCustomer.fulfilled as unknown as string]: (state: AuthState, action: PayloadAction<CustomerDTO>) => {
      state.isLoading = false;
      state.error = null;
      state.customer = action.payload;
      state.status = AsyncThunkStatus.fulfilled;
    },
    [authorizeCustomer.rejected as unknown as string]: (state: AuthState, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.customer = null;
      state.status = AsyncThunkStatus.rejected;
    },
  },
});

export const authSelector = function (state: RootState): AuthState {
  return state.auth;
};

export default authSlice.reducer;
