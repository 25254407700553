import _ from 'underscore';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  Link,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Modal,
  Paper,
  Popover,
  Select,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import {
  Close,
  Delete,
  Download,
  DriveFileRenameOutline,
  Folder,
  InsertDriveFile,
  MoreVert,
  NavigateNext,
} from '@mui/icons-material';
import { UploadRepository } from '../../../../store/types';
import { useTypedDispatch, useTypedSelector } from '../../../../hooks';
import {
  changeRepository,
  changeSelectedFiles,
  changeSelectedFolders,
  deleteFile,
  downloadFile,
  FileRequestDTO,
  filesSelector,
  getDownloadFileLink,
  getFiles,
  goToPath,
  renameFile,
  resetPath,
} from '../../../../store/reducers/fileStore';
import { bytesToSize } from '../../../../utils/byteMapper';
import { alpha } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';

interface FilesTableTooblarProps {
  repository?: UploadRepository;
  setRepository: any;
}

const FilesTableTooblar: React.FC<FilesTableTooblarProps> = ({ repository, setRepository }) => {
  const dispatch = useTypedDispatch();

  const { currentPath, selectedFiles, selectedFolders, isLoading } = useTypedSelector(filesSelector);

  const pathLayers = useMemo<string[]>(() => currentPath.split('/')?.filter((layer) => !!layer), [currentPath]);

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(selectedFiles?.length + selectedFolders?.length > 0 &&
          !isLoading && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          }),
      }}
    >
      <Box display={'flex'} flexDirection={'column'} width={'100%'}>
        {selectedFiles?.length + selectedFolders?.length > 0 && !isLoading ? (
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
              {selectedFiles?.length + selectedFolders?.length} selected
            </Typography>

            <Tooltip title="Delete">
              <IconButton
                onClick={async (e) => {
                  if (repository) {
                    await dispatch(
                      deleteFile({
                        prefix: [
                          ...selectedFiles?.map((file) => (currentPath ? `${currentPath}/${file?.Key}` : file?.Key)),
                          ...selectedFolders?.map((folder) =>
                            currentPath ? `${currentPath}/${folder}/` : `${folder}/`,
                          ),
                        ],
                        repository: repository,
                      }),
                    );
                    await dispatch(getFiles({ prefix: currentPath, repository: repository }));
                  }
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
        ) : (
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <Breadcrumbs maxItems={3} aria-label="breadcrumb" separator={<NavigateNext fontSize="small" />}>
              {currentPath ? (
                <Link
                  underline="hover"
                  color="inherit"
                  onClick={() => {
                    if (pathLayers.length) dispatch(resetPath(''));
                  }}
                >
                  {repository && repository === UploadRepository.ANIMAL_LAB ? 'Animal Lab' : null}
                  {repository && repository === UploadRepository.DATA_LAKE ? 'Data Lake' : null}
                </Link>
              ) : (
                <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" color="text.primary" component="div">
                  {repository && repository === UploadRepository.ANIMAL_LAB ? 'Animal Lab' : null}
                  {repository && repository === UploadRepository.DATA_LAKE ? 'Data Lake' : null}
                </Typography>
              )}

              {pathLayers &&
                pathLayers?.map((layer, idx) => {
                  return idx === pathLayers.length - 1 ? (
                    <Typography color="text.primary">{layer}</Typography>
                  ) : (
                    <>
                      <Link
                        underline="hover"
                        color="inherit"
                        onClick={() => {
                          const path = pathLayers?.slice(0, idx + 1)?.reduce((acc, curr, index) => acc + '/' + curr);
                          dispatch(resetPath(path));
                        }}
                      >
                        {layer}
                      </Link>
                    </>
                  );
                })}
            </Breadcrumbs>

            <Box display={'flex'} justifyContent={'flex-start'}>
              <FormControl sx={{ minWidth: 240, m: 1 }} style={{ marginRight: '15px' }} size="small">
                <InputLabel id="upload-repository-label">Repository *</InputLabel>
                <Select
                  labelId="upload-data-type-label"
                  id="upload-data-type"
                  value={repository}
                  label="Repository *"
                  onChange={(e: any) => {
                    setRepository(e?.target?.value);
                  }}
                >
                  <MenuItem value={UploadRepository.ANIMAL_LAB}>Animal Lab</MenuItem>
                  <MenuItem value={UploadRepository.DATA_LAKE}>Data Lake</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
        )}
      </Box>
    </Toolbar>
  );
};

const MemoizedFilesTableTooblar = React.memo(FilesTableTooblar);

interface FilesTableProps {
  files: FileRequestDTO[];
  folders: string[];
  currentPath: string;
}

const FilesTable: React.FC<FilesTableProps> = ({ files, folders, currentPath }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useTypedDispatch();

  const { isLoading, selectedFiles, selectedFolders, repository } = useTypedSelector(filesSelector);

  const [processingObjectName, setProcessingObjectName] = useState<string>('');

  const [openMoveInto, setOpenMoveInto] = useState(false);

  const handleClickOpenMoveInto = useCallback(() => {
    setOpenMoveInto(true);
  }, []);

  const handleCloseMoveInto = useCallback(() => {
    setOpenMoveInto(false);
  }, []);

  const [anchor, setAnchor] = useState(false);
  const [newFileName, setNewFileName] = useState<string>(processingObjectName);

  const [prefix, setPrefix] = useState('');

  // File delete Popover
  const [deleteButtonEl, setDeleteButtonEl] = useState(null);
  const isOpenPopover = useMemo(() => deleteButtonEl, [deleteButtonEl]);
  const popoverDeleteId = useMemo(() => (isOpenPopover ? 'simple-popover' : undefined), [isOpenPopover]);

  const moreButtonHandler = useCallback(
    (objectName) => (event) => {
      event.stopPropagation();
      event.preventDefault();
      setDeleteButtonEl(event.currentTarget);
      setPrefix(currentPath ? `${currentPath}/${objectName}` : objectName);
      setProcessingObjectName(objectName);
    },
    [currentPath],
  );
  const deletePopoverHandler = useCallback(() => {
    setDeleteButtonEl(null);
  }, []);

  const deleteHandler = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();

      repository &&
        dispatch(deleteFile({ prefix: prefix, repository: repository })).then((data) => {
          if (!data.error) {
            enqueueSnackbar('Object has been successfully deleted!', {
              variant: 'success',
            });
            repository &&
              dispatch(
                getFiles({
                  repository: repository,
                  prefix: prefix
                    .split('/')
                    .filter((path) => !!path)
                    .slice(0, -1)
                    .join('/'),
                }),
              );
          }
        });
    },
    [prefix, repository],
  );
  const downloadHandler = useCallback(
    async (event) => {
      event.stopPropagation();
      event.preventDefault();

      if (repository) {
        const { payload } = await dispatch(getDownloadFileLink({ fileName: prefix, repository: repository }));

        await dispatch(downloadFile({ url: payload?.presignedUrl, fileName: prefix }));
      }
    },
    [prefix, repository],
  );
  const renameFileHandler = useCallback(
    async (event) => {
      event.stopPropagation();
      event.preventDefault();

      if (repository) {
        dispatch(renameFile({ prefix: prefix, repository: repository, newName: newFileName })).then(() =>
          dispatch(getFiles({ repository: repository, prefix: currentPath })),
        );
      }
    },
    [prefix, newFileName, repository, currentPath],
  );

  const toggleDrawer = useCallback(
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setAnchor(open);
      deletePopoverHandler();
    },
    [],
  );

  const isSelectedFolder = useMemo<boolean>(() => prefix[prefix.length - 1] === '/', [prefix]);

  const isCheckedFolder = useCallback<(objectName: string) => boolean>(
    (objectName: string) => {
      if (selectedFolders && selectedFolders?.length) return selectedFolders?.some((folder) => folder === objectName);
      return false;
    },
    [selectedFolders],
  );
  const isCheckedFile = useCallback<(objectName: string) => boolean>(
    (objectName: string) => {
      if (selectedFiles && selectedFiles?.length) return selectedFiles?.some((file) => file?.Key === objectName);
      return false;
    },
    [selectedFiles],
  );

  const isCheckedAll = useMemo(
    () =>
      selectedFiles?.length === files?.length &&
      selectedFolders?.length === folders?.length &&
      (files?.length > 0 || folders?.length > 0),
    [files?.length, folders?.length, selectedFolders?.length, selectedFiles?.length],
  );

  const checkAllHandler = useCallback(
    async (event) => {
      event.stopPropagation();
      event.preventDefault();

      if (isCheckedAll) {
        await dispatch(changeSelectedFiles([]));
        await dispatch(changeSelectedFolders([]));
      } else {
        await dispatch(changeSelectedFiles(files));
        await dispatch(changeSelectedFolders(folders));
      }
    },
    [files, folders, isCheckedAll],
  );

  const checkFileHandler = useCallback(
    (file: FileRequestDTO) => async (event) => {
      event.stopPropagation();
      event.preventDefault();

      await dispatch(changeSelectedFiles(file));
    },
    [],
  );

  const checkFolderHandler = useCallback(
    (folder: string) => async (event) => {
      event.stopPropagation();
      event.preventDefault();

      await dispatch(changeSelectedFolders(folder));
    },
    [],
  );

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 500 }}>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                disableRipple
                color="primary"
                disabled={!repository}
                indeterminate={
                  selectedFiles?.length + selectedFolders?.length < files?.length + folders?.length &&
                  (selectedFiles?.length > 0 || selectedFolders?.length > 0)
                }
                checked={isCheckedAll}
                onClick={checkAllHandler}
              />
            </TableCell>
            <TableCell align={'left'}>Name</TableCell>
            <TableCell align={'left'}>Type</TableCell>
            <TableCell align={'left'}>Size</TableCell>
            <TableCell align={'left'}>Last Updated</TableCell>
            <TableCell align={'right'}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!folders?.length && !files?.length && (
            <TableRow hover>
              <TableCell align={'center'} colSpan={6}>
                <Typography>{repository ? 'There are no objects here' : 'Select repository'}</Typography>
              </TableCell>
            </TableRow>
          )}

          {folders?.map((folder) => (
            <TableRow
              key={folder}
              hover
              onClick={() => {
                if (!isLoading) {
                  setProcessingObjectName(folder);
                  if (!!selectedFiles?.length || !!selectedFolders?.length) handleClickOpenMoveInto();
                  else dispatch(goToPath(folder));
                }
              }}
              style={{ cursor: 'pointer' }}
            >
              <TableCell padding="checkbox">
                <Checkbox color="primary" checked={isCheckedFolder(folder)} onClick={checkFolderHandler(folder)} />
              </TableCell>
              <TableCell align={'left'}>
                <Box display={'flex'} alignItems={'center'}>
                  <Folder style={{ marginRight: '10px' }} />
                  {folder}/
                </Box>
              </TableCell>
              <TableCell align={'left'}>Folder</TableCell>
              <TableCell align={'left'}>-</TableCell>
              <TableCell align={'left'}>-</TableCell>
              <TableCell align={'right'}>
                <Box>
                  <IconButton
                    aria-label="delete"
                    size="small"
                    disabled={!!selectedFiles?.length || !!selectedFolders?.length}
                    onClick={moreButtonHandler(`${folder}/`)}
                  >
                    <MoreVert fontSize="small" />
                  </IconButton>
                </Box>
              </TableCell>
            </TableRow>
          ))}
          {files?.map((file) => (
            <TableRow key={file?.Key} hover>
              <TableCell padding="checkbox">
                <Checkbox color="primary" checked={isCheckedFile(file?.Key)} onClick={checkFileHandler(file)} />
              </TableCell>
              <TableCell align={'left'}>
                <Box display={'flex'} alignItems={'center'}>
                  <InsertDriveFile style={{ marginRight: '10px' }} />
                  {file?.Key}
                </Box>
              </TableCell>
              <TableCell align={'left'}>File</TableCell>
              <TableCell align={'left'}>{bytesToSize(file?.Size)}</TableCell>
              <TableCell align={'left'}>{new Date(file?.LastModified * 1000).toUTCString()}</TableCell>
              <TableCell align={'right'}>
                <Box>
                  <IconButton
                    aria-label="delete"
                    size="small"
                    disabled={!!selectedFiles?.length || !!selectedFolders?.length}
                    onClick={moreButtonHandler(file?.Key)}
                  >
                    <MoreVert fontSize="small" />
                  </IconButton>
                </Box>
              </TableCell>
            </TableRow>
          ))}

          <Popover
            id={popoverDeleteId}
            open={!!deleteButtonEl}
            anchorEl={deleteButtonEl}
            onClose={deletePopoverHandler}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            PaperProps={{
              style: {
                backgroundColor: 'transparent',
                boxShadow: 'none',
                borderRadius: 0,
              },
            }}
          >
            <Box
              sx={{
                backgroundColor: 'background.paper',
                borderRadius: 1,
                border: 1,
                borderColor: 'info.main',
                padding: '0 5px',
              }}
              display={'flex'}
              flexDirection={'column'}
              alignItems={'start'}
            >
              <MenuList>
                {isSelectedFolder ? null : (
                  <MenuItem onClick={toggleDrawer(true)}>
                    <ListItemIcon>
                      <DriveFileRenameOutline fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Rename file</ListItemText>
                  </MenuItem>
                )}
                {isSelectedFolder ? (
                  <MenuItem disabled>
                    <ListItemIcon>
                      <Download fontSize="small" color={'primary'} />
                    </ListItemIcon>
                    <ListItemText>Archive folder</ListItemText>
                  </MenuItem>
                ) : (
                  <MenuItem onClick={downloadHandler}>
                    <ListItemIcon>
                      <Download fontSize="small" color={'primary'} />
                    </ListItemIcon>
                    <ListItemText>Download file</ListItemText>
                  </MenuItem>
                )}
                <MenuItem onClick={deleteHandler}>
                  <ListItemIcon>
                    <Delete fontSize="small" color={'error'} />
                  </ListItemIcon>
                  <ListItemText color={'error'}>Delete {isSelectedFolder ? 'folder' : 'file'}</ListItemText>
                </MenuItem>
              </MenuList>
            </Box>
          </Popover>

          <>
            <Modal open={anchor} onClose={toggleDrawer(false)}>
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  minWidth: '400px',
                  backgroundColor: 'background.paper',
                  borderRadius: 1,
                  padding: '15px',
                }}
              >
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} padding={'15px'}>
                  <Typography variant={'h6'}>File rename modal</Typography>
                  <IconButton aria-label="delete" size="large" onClick={() => setAnchor(false)}>
                    <Close />
                  </IconButton>
                </Box>

                <Box padding={'15px'} display={'flex'} justifyContent={'space-between'}>
                  <TextField
                    id="outlined-basic"
                    label="File Name *"
                    variant="outlined"
                    size={'small'}
                    defaultValue={processingObjectName}
                    onChange={(e) => setNewFileName(e.currentTarget.value)}
                  />

                  <Button variant={'outlined'} disabled={isLoading} onClick={renameFileHandler}>
                    Rename
                  </Button>
                </Box>
              </Box>
            </Modal>
          </>

          <Dialog
            open={openMoveInto}
            onClose={handleCloseMoveInto}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Warning</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Uncheck all stuff and move into <strong>{processingObjectName}</strong> folder?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseMoveInto}>Stay here</Button>
              <Button
                onClick={() => {
                  !isLoading && dispatch(goToPath(processingObjectName));
                }}
                autoFocus
              >
                Move into
              </Button>
            </DialogActions>
          </Dialog>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const MemoizedFilesTable = React.memo(FilesTable);

const FilesTableComponent: React.FC<any> = () => {
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useTypedDispatch();

  const { isLoading, files, folders, currentPath, error, repository, downloadLink } = useTypedSelector(
    filesSelector,
    _.isEqual,
  );

  useEffect(() => {
    if (repository) dispatch(getFiles({ repository: repository, prefix: currentPath }));
  }, []);

  useEffect(() => {
    if (repository) dispatch(getFiles({ repository: repository, prefix: currentPath }));
  }, [repository, currentPath]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    }
  }, [error]);

  return (
    <Paper sx={{ width: '100%', mb: 2 }}>
      <MemoizedFilesTableTooblar repository={repository} setRepository={(val) => dispatch(changeRepository(val))} />

      {isLoading ? (
        <Stack spacing={1} style={{ padding: '15px' }}>
          <Skeleton animation={'pulse'} variant={'text'} width={'100%'} height={'10vh'}>
            <FilesTable folders={[]} files={[]} currentPath={''} />
          </Skeleton>
          <Skeleton animation={'pulse'} variant={'rectangular'} width={'100%'} height={'65vh'}>
            <FilesTable folders={[]} files={[]} currentPath={''} />
          </Skeleton>
        </Stack>
      ) : (
        <MemoizedFilesTable folders={folders} files={files} currentPath={currentPath} />
      )}
    </Paper>
  );
};

export const MemoizedFilesTableComponent = React.memo(FilesTableComponent);
