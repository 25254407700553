import React from 'react';
import { Device } from '../../../store/types';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import DeviceListItem from './DeviceListItem';

interface DeviceListProps {
  devices: Device[];
}

export const DeviceList: React.FC<DeviceListProps> = function ({ devices }) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Labels</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {devices?.length ? (
            devices?.map((device) => <DeviceListItem key={device.name} device={device} />)
          ) : (
            <TableRow hover>
              <TableCell align={'center'} colSpan={3}>
                <Typography>There are no devices</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
