import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { Container, Box, Theme, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { VideoPlayerControls } from './VideoPlayerControls';
import { VIDEO_HEIGHT, VIDEO_WIDTH } from '../../flows/session/sessionView';

const useStyles = makeStyles((theme?: Theme) => ({
  playerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },

  videoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

interface IVideoComponentProps {
  url: string;
  title: string;
}

export const VideoPlayer: React.FC<IVideoComponentProps> = function ({ url, title }) {
  const classes = useStyles();
  const playerRef = useRef<ReactPlayer | any>(null);
  const controlsRef = useRef<Element>(null);

  /* Video Ref Time */
  const [duration, setDuration] = useState(playerRef.current && playerRef.current.getDuration());

  /* Controls State */
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isMuted, setIsMuted] = useState<boolean>(false);
  const [seeking, setSeeking] = useState<boolean>(false);

  const [volume, setVolume] = useState<number>(0.05);
  const [played, setPlayed] = useState<number>(0);

  /* Handlers */
  const onPlayPauseHandler = useCallback(() => {
    setIsPlaying((state) => !state);
  }, [setIsPlaying]);

  const onMuteHandler = useCallback(() => {
    setIsMuted((state) => !state);
  }, [setIsMuted]);

  const handlerRewind = useCallback(() => {
    if (playerRef && playerRef.current) {
      playerRef.current.seekTo(playerRef.current.getCurrentTime() - 5);
    }
  }, [playerRef]);

  const handlerFastForward = useCallback(() => {
    if (playerRef && playerRef.current) {
      playerRef?.current.seekTo(playerRef.current.getCurrentTime() + 5);
    }
  }, [playerRef]);

  const onFullscreen = useCallback(() => {
    // TODO: create fullscrean flow
  }, [playerRef, controlsRef]);

  const handlerVolumeChange = useCallback(
    (prevVolume, currentVolume) => {
      setVolume(() => parseFloat(String(currentVolume / 100)));
      setIsMuted(() => currentVolume === 0);
    },
    [setVolume, setIsMuted],
  );

  const handlerVolumeSeekUp = useCallback(
    (prevVolume, currentVolume) => {
      setVolume(() => parseFloat(String(currentVolume / 100)));
      setIsMuted(() => currentVolume === 0);
    },
    [setVolume, setIsMuted],
  );

  const handleOnProgress = useCallback(
    (currentState) => {
      if (!seeking) setPlayed(currentState.playedSeconds);
    },
    [setPlayed, seeking],
  );

  const handleSeekChange = useCallback(
    (e, currentState) => {
      setPlayed(currentState);
    },
    [setPlayed],
  );

  const handleSeekMouseDown = useCallback(() => {
    setSeeking(true);
  }, [setSeeking]);

  const handleSeekMouseUp = useCallback(
    (event, value) => {
      if (playerRef && playerRef.current) playerRef?.current.seekTo(value);

      setSeeking(false);
    },
    [playerRef, setSeeking],
  );

  const handleOnReady = useCallback(
    (player: ReactPlayer) => {
      setDuration(player.getDuration());
    },
    [setDuration],
  );

  return (
    <Box
      component={Paper}
      width={VIDEO_WIDTH}
      style={{
        backgroundColor: 'black',
        height: playerRef?.current?.props?.height || VIDEO_HEIGHT,
        aspectRatio: '16:9',
      }}
    >
      <Box className={classes.playerWrapper}>
        {/* Video */}
        <Box className={classes.videoWrapper}>
          <ReactPlayer
            id={'video-player'}
            ref={playerRef}
            url={url}
            pip
            playing={isPlaying}
            muted={isMuted}
            volume={volume}
            onProgress={handleOnProgress}
            onReady={handleOnReady}
            onEnded={() => setIsPlaying(false)}
          />

          <VideoPlayerControls
            played={played}
            duration={duration}
            volume={volume}
            isPlaying={isPlaying}
            isMuted={isMuted}
            onMute={onMuteHandler}
            onPause={onPlayPauseHandler}
            onRewind={handlerRewind}
            onFastForward={handlerFastForward}
            onVolumeChange={handlerVolumeChange}
            onVolumeSeekUp={handlerVolumeSeekUp}
            onSeek={handleSeekChange}
            onSeekMouseDown={handleSeekMouseDown}
            onSeekMouseUp={handleSeekMouseUp}
            onFullscreen={onFullscreen}
            title={title}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default VideoPlayer;
