import React, { useCallback, useEffect, useRef, useState } from 'react';
import TableauReport from 'tableau-react';
const { tableau } = window as any;

interface TableauComponentProps {
  url: string;
  date: number;
  wid: any;
}

let viz: any;
let worksheet: any;

const TableauComponent: React.FC<TableauComponentProps> = function ({ url, date, wid }) {
  const ref = useRef(null);

  function initViz() {
    viz = new tableau.Viz(ref.current, url, {
      onFirstInteractive: () => {
        viz
          .getWorkbook()
          .activateSheetAsync('Sheet 1')
          .then(function (sheet) {
            worksheet = sheet;
          })
          .then(function () {
            return worksheet.clearFilterAsync('session_date_CHAR');
          })
          .then(function () {
            const fullDate: Date = new Date(date);
            const year: number = fullDate.getUTCFullYear();
            let mounth: any = fullDate.getMonth() + 1;
            const day: any = fullDate.getDate();

            if (mounth < 10) mounth = '0' + mounth;

            return worksheet.applyFilterAsync(
              'session_date_CHAR',
              `${year}-${mounth}-${day}`,
              tableau.FilterUpdateType.REPLACE,
            );
          })
          .then(function () {
            return worksheet.clearFilterAsync('Workstation Id');
          })
          .then(function () {
            return worksheet.applyFilterAsync('Workstation Id', wid, tableau.FilterUpdateType.REPLACE);
          })
          .otherwise(function (err) {
            console.log(err);
          });
      },
    });
  }

  useEffect(() => {
    initViz();
  }, []);

  return (
    <div className="tableauPlaceholder" style={{ margin: '20px' }}>
      <div ref={ref}></div>
    </div>
  );
};

export default TableauComponent;
