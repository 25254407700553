import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Collapse,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { Done, KeyboardArrowDown, KeyboardArrowUp, NavigateNext } from '@mui/icons-material';
import {
  devicesSelector,
  getDevice,
  getUsers,
  getWorkstations,
  usersSelector,
  workstationsSelector,
} from '../../store/reducers';
import { useSnackbar } from 'notistack';
import { Link, useNavigate } from 'react-router-dom';
import { useTypedDispatch, useTypedSelector } from '../../hooks';
import styled from 'styled-components';
import { useParams } from 'react-router';

const StyledLink = styled(Link)`
  color: black;
  text-decoration: none;
  position: relative;
  font-weight: 400;

  &:hover {
    border-bottom: 3px solid #c62828;
    color: black;
  }
`;

const DeviceView: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const dispatch = useTypedDispatch();

  const { name: deviceName } = useParams();

  const {
    assignedUsers,
    assignedLabels,
    assignedWorkstations,
    currentDevice,
    isLoading: isLoadingCurrentDevice,
    error: errorDevice,
  } = useTypedSelector(devicesSelector);

  const {
    workstations,
    isLoading: isLoadingWorkstation,
    error: errorWorkstation,
  } = useTypedSelector(workstationsSelector);

  const { users, isLoading: isLoadingUsers, error: errorUsers } = useTypedSelector(usersSelector);

  const [description, setDescription] = useState<string>('');

  const [isUsersListOpen, setUsersListOpen] = React.useState(false);
  const [isWorkstationsListOpen, setWorkstationsListOpen] = React.useState(false);

  useEffect(() => {
    if (!isLoadingCurrentDevice) dispatch(getDevice(deviceName || ''));
    if (!isLoadingWorkstation) dispatch(getWorkstations());
    if (!isLoadingUsers) dispatch(getUsers());
  }, []);

  useEffect(() => {
    if (errorDevice) {
      enqueueSnackbar(errorDevice, { variant: 'error' });
    }
    if (errorWorkstation) {
      enqueueSnackbar(errorWorkstation, { variant: 'error' });
    }
    if (errorUsers) {
      enqueueSnackbar(errorWorkstation, { variant: 'error' });
    }
  }, [errorDevice, errorWorkstation, errorUsers]);

  useEffect(() => {
    if (currentDevice && currentDevice.description) setDescription(currentDevice?.description);
  }, [currentDevice]);

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '15px',
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: '15px',
        }}
      >
        <Typography variant="h6" align="left">
          {deviceName}
        </Typography>
      </Box>

      <Divider />

      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNext fontSize="small" />}
        sx={{
          padding: '15px',
        }}
      >
        <StyledLink to="/devices">Devices</StyledLink>
        <Typography
          color="black"
          style={{
            fontWeight: '700',
          }}
        >
          Review device
        </Typography>
      </Breadcrumbs>

      <Paper>
        <Box
          style={{
            padding: '15px',
          }}
        >
          <form>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                padding: '0 15px',
                margin: '15px 0',
                width: '100%',
                borderLeft: '5px solid #1976d2',
                backgroundColor: '#ecf8ff',
                borderRadius: '5px',
              }}
            >
              <Grid container>
                <Grid item>
                  <Grid item style={{ margin: '15px 50px 15px 0' }}>
                    <Typography style={{ fontWeight: '700' }}>Name: </Typography>
                  </Grid>
                  <Grid item style={{ margin: '15px 50px 15px 0' }}>
                    <Typography style={{ fontWeight: '700' }}>Description: </Typography>
                  </Grid>
                  <Grid item style={{ margin: '15px 50px 15px 0' }}>
                    <Typography style={{ fontWeight: '700' }}>Labels: </Typography>
                  </Grid>
                </Grid>
                <Grid item style={{ height: '100%' }}>
                  <Grid item style={{ margin: '15px 50px 15px 0' }}>
                    <Typography>{deviceName}</Typography>
                  </Grid>
                  <Grid item style={{ margin: '15px 50px 15px 0' }}>
                    {isLoadingCurrentDevice ? (
                      <CircularProgress size={32} />
                    ) : description ? (
                      <Typography>{description}</Typography>
                    ) : (
                      <Typography variant={'subtitle1'}>None</Typography>
                    )}
                  </Grid>
                  <Grid item style={{ margin: '15px 50px 15px 0' }}>
                    {isLoadingCurrentDevice ? (
                      <CircularProgress size={32} />
                    ) : assignedLabels?.length ? (
                      assignedLabels?.map((label) => (
                        <>
                          <Chip
                            key={label}
                            label={label}
                            color={'primary'}
                            variant={'outlined'}
                            style={{ marginRight: '15px' }}
                          />
                        </>
                      ))
                    ) : (
                      <Typography>Does not have any labels</Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Box>

            <Paper sx={{ width: '100%', marginTop: '15px', padding: '10px' }} elevation={1}>
              <Box display={'flex'} alignItems={'center'}>
                <IconButton aria-label="expand row" size="small" onClick={() => setUsersListOpen((prev) => !prev)}>
                  {isUsersListOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </IconButton>
                <Typography variant={'subtitle1'}>Users</Typography>
              </Box>

              {isUsersListOpen ? <Divider /> : null}

              <Collapse in={isUsersListOpen} timeout="auto" unmountOnExit>
                <TableContainer>
                  <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'}>
                    <TableHead>
                      <TableRow>
                        <TableCell padding="checkbox">
                          <Checkbox
                            disableRipple
                            color="primary"
                            indeterminate={assignedUsers?.length > 0 && assignedUsers?.length < users?.length}
                            checked={assignedUsers?.length > 0 && assignedUsers?.length === users?.length}
                          />
                        </TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Profile</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {users?.map((user, index) => {
                        const isItemSelected = assignedUsers?.some((_user) => _user === user.email);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={user.email}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                disableRipple
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                  'aria-labelledby': labelId,
                                }}
                              />
                            </TableCell>
                            <TableCell component="th" id={labelId} scope="row" padding="none">
                              {user.email}
                            </TableCell>
                            <TableCell component="th" id={labelId} scope="row" padding="none">
                              {user.firstName} {user.lastName}
                            </TableCell>
                            <TableCell component="th" id={labelId} scope="row" padding="none">
                              {user.profile}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Collapse>
            </Paper>

            <Paper sx={{ width: '100%', marginTop: '15px', padding: '10px' }} elevation={1}>
              <Box display={'flex'} alignItems={'center'}>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setWorkstationsListOpen((prev) => !prev)}
                >
                  {isWorkstationsListOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </IconButton>
                <Typography variant={'subtitle1'}>Workstations</Typography>
              </Box>

              {isWorkstationsListOpen ? <Divider /> : null}

              <Collapse in={isWorkstationsListOpen} timeout="auto" unmountOnExit>
                <TableContainer>
                  <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'}>
                    <TableHead>
                      <TableRow>
                        <TableCell padding="checkbox">
                          <Checkbox
                            disableRipple
                            color="primary"
                            indeterminate={
                              assignedWorkstations?.length > 0 && assignedWorkstations?.length < workstations?.length
                            }
                            checked={
                              assignedWorkstations?.length > 0 && assignedWorkstations?.length === workstations?.length
                            }
                          />
                        </TableCell>
                        <TableCell align={'left'}>ID</TableCell>
                        <TableCell>Name</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {workstations?.map((workstation, index) => {
                        const isItemSelected = assignedWorkstations?.some((_wkid) => _wkid === workstation.id);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={workstation.id}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                disableRipple
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                  'aria-labelledby': labelId,
                                }}
                              />
                            </TableCell>
                            <TableCell component="th" id={labelId} scope="row" padding="none">
                              {workstation.id}
                            </TableCell>
                            <TableCell component="th" id={labelId} scope="row" padding="none">
                              {workstation.name}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Collapse>
            </Paper>
          </form>
        </Box>
      </Paper>
    </Box>
  );
};

export default DeviceView;
