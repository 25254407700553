import React, { useMemo } from 'react';
import {
  Container,
  Tooltip,
  Button,
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  CardMedia,
  Avatar,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Dialog,
} from '@mui/material';
import { Logout } from '@mui/icons-material';
import { useLocalStorage } from 'react-use';
import BostonLogo from '../../assets/logo.png';
import { AuthState, useAuth } from '../../context/AuthContext';
import { CLIENT_INFO_KEY, JWT_TOKEN_KEY } from '../../store/types';
import { useTypedDispatch, useTypedSelector } from '../../hooks';
import { setSelectedFiles } from '../../store/reducers/fileStore';
import { changeUpoadedStatus, changeUpoadingStatus, utilsSelector } from '../../store/reducers/utils';

export const HEADER_HEIGHT = '4rem';

const Header: React.FC = function () {
  const dispatch = useTypedDispatch();

  // Auth BL
  const { logout } = useAuth();

  const [jwtToken, setJwtToken] = useLocalStorage<string | null>(JWT_TOKEN_KEY);
  const [authStateLS, setAuthStateLS] = useLocalStorage<AuthState>(CLIENT_INFO_KEY);

  // Files BL
  const { isUploading, hasUploaded } = useTypedSelector(utilsSelector);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (e) => {
    e.preventDefault();

    setOpen(true);
  };

  const handleClose = (e) => {
    e.preventDefault();

    setOpen(false);
  };

  const leaveHandler = async (e) => {
    e.preventDefault();

    setAuthStateLS((prev) => ({ ...prev, isUploading: false } as AuthState));

    dispatch(changeUpoadingStatus(false));
    dispatch(changeUpoadedStatus(true));

    dispatch(setSelectedFiles([]));

    setOpen(false);
    await logout();
  };

  const onLogoutClick = useMemo(
    () => (isUploading || !hasUploaded ? handleClickOpen : async () => await logout()),
    [isUploading, hasUploaded, handleClickOpen, logout],
  );

  return (
    <>
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, height: HEADER_HEIGHT }}>
        <Container maxWidth={false}>
          <Toolbar disableGutters style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6" noWrap component="div" sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}>
              <Avatar variant="square" alt="Boston Scientific Logo" src={BostonLogo} sx={{ width: '100%' }} />
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="small"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
              >
                <CardMedia
                  component="img"
                  image="../../assets/logo.png"
                  style={{
                    width: 'auto',
                    maxHeight: '20px',
                    aspectRatio: '1',
                  }}
                />
              </IconButton>
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              {jwtToken && localStorage.getItem(JWT_TOKEN_KEY) ? (
                <Tooltip title="Logout">
                  <Button
                    variant="text"
                    color="inherit"
                    startIcon={<Logout />}
                    style={{
                      color: 'white',
                    }}
                    onClick={onLogoutClick}
                  >
                    <Typography>Logout</Typography>
                  </Button>
                </Tooltip>
              ) : null}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure you want to leave the current page?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You have not uploaded the specified objects.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={leaveHandler} autoFocus>
            Leave
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Header;
